import React, { type SetStateAction, type Dispatch } from 'react';
import {
  ASSET_BROWNFIELD,
  ASSET_GREENFIELD,
  BROWNFIELD,
  CARBON_CRITICAL,
  CARBON_SIGNIFICANT,
  GREENFIELD,
  MESSAGES,
} from '../../api/constants';
import { type ICarbonData, type Action, PerformanceStandard } from '../../types/types';
import {
  fiftyeight,
  fiftynine,
  fortyone,
  hundred,
  pointtwo,
  pointzerofive,
  thirtynine,
} from '../../constants';

export const getPerformanceStandardMessage = (peakAnnualEquity: number) => {
  if (peakAnnualEquity < pointzerofive) {
    return MESSAGES.peakAnnualEmmision.below50KtaMessage;
  } else if (peakAnnualEquity >= pointzerofive && peakAnnualEquity <= pointtwo) {
    return MESSAGES.peakAnnualEmmision.between50To200KTAmessage;
  } else {
    return MESSAGES.peakAnnualEmmision.above200KTAmessage;
  }
};

export const getContactInfo = (isCritical: boolean) => {
  const performanceMessage = MESSAGES.performanceStandard;
  return isCritical
    ? performanceMessage.carbonCritical.contactInfo
    : performanceMessage.carbonSignificant.contactInfo;
};

export const getEfficiencyMessage = (
  isPerfStandardSignificant: boolean,
  isPerfStandardCritical: boolean,
  isAssetBrownfield: boolean,
  _isAssetGreenfield: boolean,
  efficiency: number,
) => {
  let message = '';
  // assigning a default value as they can not be left as empty string
  let carbonStatus: string;
  let assetType: string;
  let efficiencyMessage = '';

  const efficiencyByUser = Math.round(efficiency * hundred); // as the efficiency is stored after dividing by 100

  const conditions: ICarbonData = {
    carbonSignificant: {
      assetBrownfield: {
        efficiencyAbove39Info: efficiencyByUser > thirtynine,
        efficiencyBelow40Info: efficiencyByUser <= thirtynine,
      },
      assetGreenfield: {
        efficiencyAbove41Info: efficiencyByUser > fortyone,
        efficiencyBelow42Info: efficiencyByUser <= fortyone,
      },
    },
    carbonCritical: {
      assetBrownfield: {
        efficiencyAbove57Info: efficiencyByUser >= fiftyeight,
        efficiencyBelow58Info: efficiencyByUser < fiftyeight,
      },
      assetGreenfield: {
        efficiencyAbove59Info: efficiencyByUser > fiftynine,
        efficiencyBelow60Info: efficiencyByUser <= fiftynine,
      },
    },
  };
  // Determine the values based on conditions
  if (isPerfStandardSignificant || isPerfStandardCritical) {
    carbonStatus = isPerfStandardSignificant ? CARBON_SIGNIFICANT : CARBON_CRITICAL;
    assetType = isAssetBrownfield ? ASSET_BROWNFIELD : ASSET_GREENFIELD;

    const carbonStatusObj = conditions[carbonStatus];
    const assetTypeObj = carbonStatusObj[assetType as keyof typeof carbonStatusObj];
    for (const msg in assetTypeObj) {
      if (assetTypeObj[msg as keyof typeof assetTypeObj]) {
        efficiencyMessage = msg;
        break;
      }
    }
    const performanceStandard: PerformanceStandard = MESSAGES.performanceStandard;
    const carbonCritical = performanceStandard[carbonStatus];
    const assetBrownfield = carbonCritical[assetType as keyof typeof carbonCritical];
    const efficiencyInfo = assetBrownfield[efficiencyMessage as keyof typeof assetBrownfield];
    message = String(efficiencyInfo);
  }

  return message;
};

export const getOperatingModelMessage = (operatingModel: number) => {
  const messages = {
    1: MESSAGES.operatingModel.sovOrNov,
    2: MESSAGES.operatingModel.sovOrNov,
    4: MESSAGES.operatingModel.lease,
  };
  return messages[operatingModel as keyof typeof messages] || '';
};

export const setUpKpiMessage = (
  peakAnnualEquity: number,
  efficiency: number,
  operatingModel: number,
  setKpiMessage: React.Dispatch<SetStateAction<string[]>>,
  assetType: string,
  dispatch: Dispatch<Action>,
) => {
  const isPerfStandardSignificant =
    peakAnnualEquity >= pointzerofive && peakAnnualEquity <= pointtwo;
  const isPerfStandardCritical = peakAnnualEquity > pointtwo;
  const isAssetBrownfield = assetType.toUpperCase() === BROWNFIELD;
  const isAssetGreenfield = assetType.toUpperCase() === GREENFIELD;
  // ORDER of messages
  /*
    - Equity Scope 1&2 Carbon (Peak Annual Emissions) < 0.050 mtCO2e
    - 0.050 mtCO2e > Equity Scope 1&2 Carbon (Peak Annual Emissions) < 0.200 mtCO2e
    - Equity Scope 1&2 Carbon (Peak Annual Emissions) > 0.200 mtCO2e

    - Operating Model in Step-1 = Investment SOV or Investment NOV
    - Operating Model in Step-1 = Lease

    - Performance Standard = Carbon Significant and Asset Type = Brownfield and Efficiency input in Step-1 >= 40%
    - Performance Standard = Carbon Significant and Asset Type = Greenfield and Efficiency input in Step-1 >= 42%
    - Performance Standard = Carbon Critical and Asset Type = Brownfield and Efficiency input in Step-1 >= 58%
    - Performance Standard = Carbon Critical and Asset Type = Greenfield and Efficiency input in Step-1 >= 60%

    - Performance Standard = Carbon Significant
    - Performance Standard = Carbon Critical
  */
  const message1 = getPerformanceStandardMessage(peakAnnualEquity);
  const message2 = getOperatingModelMessage(operatingModel);
  const message3 = getEfficiencyMessage(
    isPerfStandardSignificant,
    isPerfStandardCritical,
    isAssetBrownfield,
    isAssetGreenfield,
    efficiency,
  );
  const message4 =
    isPerfStandardCritical || isPerfStandardSignificant
      ? getContactInfo(isPerfStandardCritical)
      : '';

  const finalMessage = [message1, message2, message3, message4].filter((msg) => msg !== '');
  setKpiMessage(finalMessage.flat(Infinity) as unknown as string[]);
  dispatch({
    type: 'setPowerPerformanceStandardKpiMessage',
    payload: finalMessage.join('. ') + '.',
  });
};
