import { Button } from '@sede-x/shell-ds-react-framework';
import { POWER, GRID, GAS_FIRED_GENERATION } from '../../api/constants';
import React, { useContext } from 'react';
import { AppContext } from '../../context/calculatorFormContext';
import { IManualFormData, type Idispatch } from '../../types/types';

export const ManualFormButtons = (props: {
  country: string;
  myState: IManualFormData;
  myDispatch: Idispatch;
}) => {
  const { state, dispatch } = useContext(AppContext);
  const validateForm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { country, myState, myDispatch } = props;
    const {
      opportunity,
      business,
      commodity,
      fuelType,
      assetType,
      operatingModel,
      equityShare,
      efficiency,
      gridLocation,
      isGoToStepTwoClicked,
    } = state;

    const { techSource, startDate, endDate, grossMarginVal, uomVal } = myState;

    const isValidOpportunity = opportunity !== '';
    const isValidBusiness = business !== '';
    const isValidCountry = country !== '';
    const isValidCommodity = commodity !== '';
    const isValidStartDate = startDate !== '';
    const isValidEndDate = endDate !== '';
    const isValidGrossMargin = grossMarginVal !== '';
    myDispatch({ type: 'setisOpportunityValid', payload: isValidOpportunity });
    myDispatch({ type: 'setisBusinessValid', payload: isValidBusiness });
    myDispatch({ type: 'setisCountryValid', payload: isValidCountry });
    myDispatch({ type: 'setisCommodityValid', payload: isValidCommodity });
    myDispatch({ type: 'setisStartDateValid', payload: isValidStartDate });
    myDispatch({ type: 'setisEndDateValid', payload: isValidEndDate });
    myDispatch({ type: 'setisGrossMarginValid', payload: isValidGrossMargin });

    const isCommodityPowerAndEmptyTechSources = commodity === POWER && techSource.length === 0;
    const isValidTechSource = !isCommodityPowerAndEmptyTechSources;
    myDispatch({ type: 'setisTechSourceValid', payload: isValidTechSource });

    const isValidGridLocation = techSource.includes(GRID) ? (gridLocation || '') !== '' : true;
    myDispatch({ type: 'setIsGridLocationValid', payload: isValidGridLocation });

    let isValidFuelType = true;
    let isValidAssetType = true;
    let isValidOperatingModel = true;
    let isValidEquityShare = true;
    let isValidEfficiency = true;

    // if Gas Fired Generation is opted as Tech Source
    const isGasFiredGeneration = techSource.includes(GAS_FIRED_GENERATION);

    if (isGasFiredGeneration) {
      isValidFuelType = fuelType !== '';
      isValidAssetType = assetType !== '';
      isValidOperatingModel = operatingModel !== '';
      isValidEquityShare = equityShare !== '';
      isValidEfficiency = efficiency !== '';
      myDispatch({ type: 'setIsFuelTypeValid', payload: isValidFuelType });
      myDispatch({ type: 'setIsAssetTypeValid', payload: isValidAssetType });
      myDispatch({ type: 'setIsOperatingModelValid', payload: isValidOperatingModel });
      myDispatch({ type: 'setIsEquityPercentageValid', payload: isValidEquityShare });
      myDispatch({ type: 'setIsEfficiencyValid', payload: isValidEfficiency });
    }

    const isFormValid = [
      isValidTechSource,
      isValidCommodity,
      isValidBusiness,
      isValidCountry,
      isValidGrossMargin,
      isValidOpportunity,
      isValidStartDate,
      isValidEndDate,
      isValidFuelType,
      isValidOperatingModel,
      isValidEquityShare,
      isValidEfficiency,
      isValidGridLocation,
      isValidAssetType,
    ].every((isValid) => isValid);

    myDispatch({ type: 'setIsWarningMsgAvailable', payload: !isFormValid });
    dispatch({ type: 'setIsFormValidated', payload: isFormValid });

    if (isFormValid) {
      const techSourceSelected: string[] = [...techSource];
      if (techSourceSelected.includes(GAS_FIRED_GENERATION as string)) {
        techSourceSelected.splice(techSourceSelected.indexOf(GAS_FIRED_GENERATION), 1);
        techSourceSelected.push(fuelType);
      }
      dispatch({ type: 'setTechnologySource', payload: techSourceSelected });
      dispatch({ type: 'setStepTwoTableFirstCol', payload: 'Tech Source, ' + uomVal });
      dispatch({ type: 'setIsGoToStepTwoClicked', payload: !isGoToStepTwoClicked });
      dispatch({ type: 'setActiveKey', payload: [0, 1] });
    }
  };
  const reload = () => document.location.reload();
  return (
    <div>
      <Button className='btn-size margin-right' variant='outlined' onClick={reload}>
        Reset Form
      </Button>
      <Button
        variant='filled'
        className='btn-size'
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          validateForm(e);
        }}
      >
        Go to step 2
      </Button>
    </div>
  );
};
