import { BaseTable } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';
import { IKpiTableData } from '../../types/types';

const Container = styled.div`
  overflow: scroll;
  margin-top: 10px;
`;

export const KpiTable = (props: {
  cols: Array<string | number>;
  stepThreeTableData: IKpiTableData[];
}) => {
  const cols = props.cols.map((val) => {
    return {
      accessorKey: val.toString(),
      header: val.toString(),
    };
  });
  return (
    <Container>
      <BaseTable
        style={{ width: '100%' }}
        size='small'
        noFooter
        maxHeight={1000}
        columns={cols}
        data={props.stepThreeTableData}
      />
    </Container>
  );
};
