import { type IManualFormData } from '../../types/types';

export const manualFormInitialState: IManualFormData = {
  businessunitsOptns: [],
  commodityOptions: [],
  techSourceOptions: [],
  techSourceApiResponse: [],
  fuelTypeOptions: [],
  assetTypeOptions: [],
  powergridOptns: [],
  uomVal: '',
  efficiencyVal: '',
  grossMarginVal: '',
  equityShareVal: '',
  country: '',
  startDate: '',
  endDate: '',
  techSource: [],
  isWarningMsgAvailable: false,
  disableGrid: true,
  enableTechSourceField: false,
  showGasFields: false,
  showEquityShare: true,
  isOpportunityValid: true,
  isBusinessValid: true,
  isCountryValid: true,
  isCommodityValid: true,
  isTechSourceValid: true,
  isGrossMarginValid: true,
  isStartDateValid: true,
  isEndDateValid: true,
  isFuelTypeValid: true,
  isAssetTypeValid: true,
  isOperatingModelValid: true,
  isEquityPercentageValid: true,
  isEfficiencyValid: true,
  isLoading: false,
  isGridLocationValid: true,
};
