import React from 'react';
import { ALL_CARBON_DEALS_HEADING, ALL_CARBON_DEALS_SUMMARY } from '../../../constants';
import {
  AllCarbonDealsContentContainer,
  AllCarbonDealsHeading,
  AllCarbonDealsSummary,
} from '../AllDeals.style';
import { IAuthToken } from '../../../types/types';
import DownloadDeals from '../DownloadDeals/DownloadDeals';
import { Flexbox, Grid } from '@sede-x/shell-ds-react-framework';

interface AllCarbonDealsContentProps {
  user: IAuthToken;
}

const AllCarbonDealsContent: React.FC<AllCarbonDealsContentProps> = ({ user }) => {
  return (
    <AllCarbonDealsContentContainer>
      <Grid height='80px' columns={'auto auto'}>
        <Grid.Cell>
          <AllCarbonDealsHeading>{ALL_CARBON_DEALS_HEADING}</AllCarbonDealsHeading>
          <AllCarbonDealsSummary>{ALL_CARBON_DEALS_SUMMARY}</AllCarbonDealsSummary>
        </Grid.Cell>
        <Grid.Cell centerContent={true}>
          <Flexbox gap='10px' justifyContent='flex-end'>
            <DownloadDeals user={user} />
          </Flexbox>
        </Grid.Cell>
      </Grid>
    </AllCarbonDealsContentContainer>
  );
};

export default AllCarbonDealsContent;
