import {
  FormField,
  FormLabelIconPosition,
  Select,
  SelectMode,
} from '@sede-x/shell-ds-react-framework';

import { DefaultOptionType, eventType, IOptions } from '../../../types/types';

interface ReusableSelectProps {
  id: string;
  label: string;
  value?: string | string[];
  // eslint-disable-next-line no-unused-vars
  onChange?: (value: eventType, option: DefaultOptionType) => void; // Update the type of onChange prop
  options: IOptions[];
  disabled?: boolean;
  optionLabelProp?: string;
  invalid?: boolean;
  loading?: boolean;
  mandatory: boolean;
  mode?: string;
  iconPosition?: FormLabelIconPosition;
  allowClear?: boolean;
}
export const ReusableSelect = (props: ReusableSelectProps) => {
  const {
    id,
    label,
    value,
    onChange,
    options,
    disabled,
    optionLabelProp,
    invalid,
    loading,
    mandatory,
    mode,
    iconPosition,
    allowClear,
  } = props;
  return (
    <FormField id={id} label={label} mandatory={mandatory} iconPosition={iconPosition}>
      <Select
        value={value}
        onChange={(e, option) => onChange && onChange(e, option as DefaultOptionType)}
        disabled={disabled}
        optionLabelProp={optionLabelProp}
        invalid={invalid}
        loading={loading}
        mode={(mode as SelectMode) ?? 'single'}
        allowClear={allowClear}
        data-testid={id}
      >
        {options.map((item) => (
          <option key={item.value} value={item.label}>
            {item.label}
          </option>
        ))}
      </Select>
    </FormField>
  );
};
