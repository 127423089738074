import { Copy } from '@sede-x/shell-ds-react-framework/build/esm/components/Icon/components';
import {
  AVG_CARBON_INT,
  ASSET_SCOPE1_CI,
  EQUITY_SCOPE_CARBON_PEAK_ANNUAL_EMISSION,
  NET_ABS_EMISSION_SCOPE_PEAK_ANNUAL_EMISSION,
  MARGIN_TO_CARBON_RATIO_AVG,
  OPERATED_SCOPE,
  EQUITY_SCOPE,
  NET_ABSOLUTE_EMISSIONS,
  MCR_AVG_UOM,
  MTCO2E,
  NATURAL_GAS,
} from '../../api/constants';
import {
  type ICalculatorFormState,
  type YearWiseData,
  type IKpiTableData,
  type IyearWiseKPIrecord,
  type IRecord,
  Idispatch,
  Iparameters,
  IKpiContentData,
  IStepTwoTableData,
  IKpiCardData,
} from '../../types/types';
import { convertInputToCommaSeparatedWithThreeDecimal, convertBigIntToDecimal } from '../../utils';
import { setUpKpiMessage } from './setUpMessage';
import { type SetStateAction, type ReactNode, Dispatch } from 'react';
import {
  PointTwoNineThree,
  ThreePointSix,
  four,
  pointnine,
  tenlakh,
  thousand,
  thousandfivehundred,
  three,
  two,
} from '../../constants';
import { calculateCIforGFPfuelTypeLNG, calculateCIforGFPfuelTypeNG } from './utils';
const cardCols: string[] = ['KPIs', 'Value', 'Unit'];
// Exlude array for total calculation
const excludeTotalColumn: string[] = [AVG_CARBON_INT, ASSET_SCOPE1_CI];

const cardFirstColData: string[] = [
  EQUITY_SCOPE_CARBON_PEAK_ANNUAL_EMISSION,
  NET_ABS_EMISSION_SCOPE_PEAK_ANNUAL_EMISSION,
  MARGIN_TO_CARBON_RATIO_AVG,
];

export const decorateCardTableData = (
  myState: IKpiContentData,
  myDispatch: Idispatch,
  dispatch: Idispatch,
  parameters: Iparameters,
  state: ICalculatorFormState,
) => {
  const cardRowData: IKpiCardData[] = [];
  const [cardFirstCol, , cardLastCol] = cardCols;
  const cardLastColData: ReactNode[] = [
    <>
      {MTCO2E}{' '}
      <Copy
        onClick={() => {
          copyEquityScope12(myState, myDispatch);
        }}
      />
    </>,
    <>
      {MTCO2E}{' '}
      <Copy
        onClick={() => {
          copyNaeScope12(myState, myDispatch);
        }}
      />
    </>,
    <>
      {MCR_AVG_UOM}{' '}
      <Copy
        onClick={() => {
          copyMCR(myState, myDispatch);
        }}
      />
    </>,
  ];
  if (myState.stepThreeTableData.length > 0) {
    // setting first and last key-value pair for each row object
    for (let i = 0; i < cardFirstColData.length; i++) {
      cardRowData.push({
        [cardFirstCol]: cardFirstColData[i],
        Value: '0.000',
        [cardLastCol]: cardLastColData[i],
      } as unknown as IKpiCardData);
      // set up the values for each row in the Card
      updateNetAbsEmsScopePeakAnnualEmission(cardRowData, i, myState, myDispatch);
      updateMarginToCarbonRatioAvg(cardRowData, i, dispatch, myState, myDispatch, state);
      updateEquityScopeCarbonPeakAnnualEmission(cardRowData, i, myState, myDispatch, parameters);
    }

    myDispatch({ type: 'setCardTableData', payload: cardRowData });
  }
};

export const decorateTableData = (
  state: ICalculatorFormState,
  dispatch: Idispatch,
  myState: IKpiContentData,
  myDispatch: Idispatch,
  setKpiMessage: Dispatch<SetStateAction<string[]>>,
  data: { parameters: Iparameters; cols: Array<string | number>; firstColData: string[] },
) => {
  const { parameters, cols, firstColData } = data;
  const stepTwoData: IStepTwoTableData[] = [...state.stepTwoTableData];
  const rowData: IKpiTableData[] = [];
  const operatedScopeYearWiseObj: YearWiseData = { techSource: '', [state.startYear]: '' };
  const equityScopeYearWiseObj: YearWiseData = { techSource: '', [state.startYear]: '' };
  const scope1CmbCrbnIntYearWiseObj: YearWiseData = { techSource: '', [state.startYear]: '' };
  const yearWiseKPIrecord: IyearWiseKPIrecord = {
    netAbsEmissionYearWise: [],
    operatedScopeYearWiseObj,
    equityScopeYearWiseObj,
    timeStampDataYearWise: [],
    carbonIntensityYearWise: [],
    carbonIntensityUomYearWise: [],
    carbonEmissionYearWise: [],
    scope1CmbCrbnIntYearWiseObj,
  };
  const equityScopeData: IRecord = {};
  const netAbsEmissionData: IRecord = {};
  const isGasFiredPower =
    state.technologySource.includes(NATURAL_GAS) || state.technologySource.includes('LNG');
  prepareRowData(rowData, cols, firstColData);

  for (let a = 0; a < rowData.length; a++) {
    const rowDataInfo: IKpiTableData = rowData[a];
    const fieldIndex: number = a;
    const firstCol = state.stepTwoTableFirstCol;
    const currentUpdateFunctions = createUpdateFunctions(
      {
        fieldIndex,
        rowDataInfo,
        equityScopeData,
        netAbsEmissionData,
        yearWiseKPIrecord,
        parameters,
        stepTwoData,
        firstCol,
      },
      state,
      dispatch,
    );
    for (let year = state.startYear; year < state.endYear + 1; year++) {
      currentUpdateFunctions.forEach(({ condition, update }) => {
        if (condition) {
          update(year);
        }
      });
    }
    updateTotalColumn(rowData[a], state);
  }
  if (isGasFiredPower && Number(state.operatingModel) !== three) {
    setUpKpiMessage(
      Number(myState.equityScope12PeakEmission.replaceAll(',', '')),
      Number(state.efficiency),
      Number(state.operatingModel),
      setKpiMessage,
      state.assetType,
      dispatch,
    );
  }
  myDispatch({ type: 'setStepThreeTableData', payload: rowData });
  myDispatch({ type: 'setEquityScopeDataYearWise', payload: equityScopeData });
  myDispatch({ type: 'setNetAbsEmsDataYearWise', payload: netAbsEmissionData });
  dispatch({
    type: 'setOperatedScopeYearWiseObj',
    payload: yearWiseKPIrecord.operatedScopeYearWiseObj,
  });
  dispatch({
    type: 'setEquityScopeYearWiseObj',
    payload: yearWiseKPIrecord.equityScopeYearWiseObj,
  });
  dispatch({
    type: 'setCarbonIntensityYearWise',
    payload: yearWiseKPIrecord.carbonIntensityYearWise,
  });
  dispatch({
    type: 'setCarbonIntensityUomYearWise',
    payload: yearWiseKPIrecord.carbonIntensityUomYearWise,
  });
  dispatch({
    type: 'setCarbonEmissionYearWise',
    payload: yearWiseKPIrecord.carbonEmissionYearWise,
  });
  dispatch({ type: 'setTimeStampDataYearWise', payload: yearWiseKPIrecord.timeStampDataYearWise });
  dispatch({
    type: 'setScope1CmbCrbnIntYearWiseObj',
    payload: yearWiseKPIrecord.scope1CmbCrbnIntYearWiseObj,
  });
};

const saveCiDataForSaveKpiData = (
  data: {
    year: number;
    firstCol: string;
    carbonIntForCurrTechSource: number;
    timeStampDataForCurrTechSource: string;
    carbonIntUomForCurrTechSource: string;
  },
  carbonIntensityYearWise: YearWiseData[],
  carbonIntensityUomYearWise: YearWiseData[],
  timeStampDataYearWise: YearWiseData[],
) => {
  const {
    year,
    firstCol,
    carbonIntForCurrTechSource,
    timeStampDataForCurrTechSource,
    carbonIntUomForCurrTechSource,
  } = data;
  // store carbon intensity for final kpi data saving
  let found = false;
  carbonIntensityYearWise.forEach((obj: YearWiseData) => {
    if (obj.techSource === firstCol) {
      obj[year] = carbonIntForCurrTechSource.toString();
      found = true;
    }
  });
  if (!found) {
    const ciData: YearWiseData = { techSource: firstCol };
    ciData[year] = carbonIntForCurrTechSource.toString();
    carbonIntensityYearWise.push(ciData);
  }

  // set UOM for all CI data
  found = false;
  carbonIntensityUomYearWise.forEach((obj: YearWiseData) => {
    if (obj.techSource === firstCol) {
      obj[year] = carbonIntUomForCurrTechSource;
      found = true;
    }
  });
  if (!found) {
    const ciData: YearWiseData = { techSource: firstCol };
    ciData[year] = carbonIntUomForCurrTechSource;
    carbonIntensityUomYearWise.push(ciData);
  }

  // set UOM for all CI data
  found = false;
  timeStampDataYearWise.forEach((obj: YearWiseData) => {
    if (obj.techSource === firstCol) {
      obj[year] = timeStampDataForCurrTechSource;
      found = true;
    }
  });
  if (!found) {
    const ciData: YearWiseData = { techSource: firstCol };
    ciData[year] = timeStampDataForCurrTechSource;
    timeStampDataYearWise.push(ciData);
  }

  return [carbonIntensityYearWise, carbonIntensityUomYearWise, timeStampDataYearWise];
};

const getUOMForPipelineGasAsCommodity = (parameters: Iparameters) => {
  // UOM Conversion Factor is (3.6*0.293/1000000000) in MTPA for Americas
  // UOM Conversion Factor is (3.6/1000000000)  in MTPA for Rest of the world
  // UOM Conversion Factor is (10^9 / 10^12 = 0.001)  in MTPA for Australia
  const uomForAmericasAndAsiaWithCommodityPg =
    (ThreePointSix * PointTwoNineThree) / (thousand * thousand * thousand); // in MTPA
  const uomForRestOfTheWorldWithCommodityPg = ThreePointSix / (thousand * thousand * thousand); // in MTPA
  const uomForAustraliaWithCommodityPg = 0.001;
  if (parameters.isAmericasWithCommodityPg || parameters.isAsiaWithCommodityPg) {
    return uomForAmericasAndAsiaWithCommodityPg;
  }
  if (parameters.isAustraliaWithCommodityPg) {
    return uomForAustraliaWithCommodityPg;
  }
  return uomForRestOfTheWorldWithCommodityPg;
};

const getCIforTechSrcNonGridAndNonGFP = (
  year: number,
  firstCol: string,
  carbonIntensityYearWise: YearWiseData[],
  carbonIntensityUomYearWise: YearWiseData[],
  timeStampDataYearWise: YearWiseData[],
  state: ICalculatorFormState,
) => {
  let carbonIntForCurrTechSource = 0;
  let carbonIntUomForCurrTechSource = '';
  let timeStampDataForCurrTechSource = '';
  for (const carbonIntensityForNonGrid of state.carbonIntensityForNonGrid) {
    if (carbonIntensityForNonGrid.TECHNOLOGY_SOURCE_NAME === firstCol) {
      carbonIntForCurrTechSource = carbonIntensityForNonGrid.CARBON_INTENSITY;
      carbonIntUomForCurrTechSource = carbonIntensityForNonGrid.UNIT_CODE;
      timeStampDataForCurrTechSource = carbonIntensityForNonGrid.LAST_UPDATE_TIMESTAMP;
      // store carbon intensity for final kpi data saving
      [carbonIntensityYearWise, carbonIntensityUomYearWise, timeStampDataYearWise] =
        saveCiDataForSaveKpiData(
          {
            year,
            firstCol,
            carbonIntForCurrTechSource,
            timeStampDataForCurrTechSource,
            carbonIntUomForCurrTechSource,
          },
          carbonIntensityYearWise,
          carbonIntensityUomYearWise,
          timeStampDataYearWise,
        );
    }
  }
  return carbonIntForCurrTechSource;
};

const getCIforTechSrcGrid = (
  year: number,
  firstCol: string,
  carbonIntensityYearWise: YearWiseData[],
  carbonIntensityUomYearWise: YearWiseData[],
  timeStampDataYearWise: YearWiseData[],
  state: ICalculatorFormState,
) => {
  let carbonIntForCurrTechSource = 0;
  let carbonIntUomForCurrTechSource = '';
  let timeStampDataForCurrTechSource = '';
  for (const carbonIntensityForGrid of state.carbonIntensityForGrid) {
    if (carbonIntensityForGrid.YEAR === year) {
      carbonIntForCurrTechSource = carbonIntensityForGrid.CARBON_INTENSITY;
      carbonIntUomForCurrTechSource = carbonIntensityForGrid.UNIT_CODE;
      timeStampDataForCurrTechSource = carbonIntensityForGrid.LAST_UPDATE_TIMESTAMP;
      // store carbon intensity for final kpi data saving
      [carbonIntensityYearWise, carbonIntensityUomYearWise, timeStampDataYearWise] =
        saveCiDataForSaveKpiData(
          {
            year,
            firstCol,
            carbonIntForCurrTechSource,
            timeStampDataForCurrTechSource,
            carbonIntUomForCurrTechSource,
          },
          carbonIntensityYearWise,
          carbonIntensityUomYearWise,
          timeStampDataYearWise,
        );
    }
  }
  return carbonIntForCurrTechSource;
};

const getCIforCommodityPG = (
  year: number,
  firstCol: string,
  carbonIntensityYearWise: YearWiseData[],
  carbonIntensityUomYearWise: YearWiseData[],
  timeStampDataYearWise: YearWiseData[],
  state: ICalculatorFormState,
) => {
  let carbonIntForCurrTechSource = 0;
  let carbonIntUomForCurrTechSource = '';
  let timeStampDataForCurrTechSource = '';
  for (const carbonIntensityForGas of state.carbonIntensityForGas) {
    if (carbonIntensityForGas.YEAR === year) {
      carbonIntForCurrTechSource = carbonIntensityForGas.CARBON_INTENSITY;
      carbonIntUomForCurrTechSource = carbonIntensityForGas.UNIT_CODE;
      timeStampDataForCurrTechSource = carbonIntensityForGas.LAST_UPDATE_TIMESTAMP;
      // store carbon intensity for final kpi data saving
      [carbonIntensityYearWise, carbonIntensityUomYearWise, timeStampDataYearWise] =
        saveCiDataForSaveKpiData(
          {
            year,
            firstCol,
            carbonIntForCurrTechSource,
            timeStampDataForCurrTechSource,
            carbonIntUomForCurrTechSource,
          },
          carbonIntensityYearWise,
          carbonIntensityUomYearWise,
          timeStampDataYearWise,
        );
    }
  }
  return carbonIntForCurrTechSource;
};

const saveCarbonEmissionDataForSaveKpiData = (
  firstCol: string,
  year: number,
  carbonEmissionYearWise: YearWiseData[],
  currentCarbonEmission: number,
) => {
  let found = false;
  carbonEmissionYearWise.forEach((obj: YearWiseData) => {
    if (obj.techSource === firstCol) {
      obj[year] = currentCarbonEmission.toString();
      found = true;
    }
  });
  if (!found) {
    const carbonEmissionData: YearWiseData = { techSource: firstCol };
    carbonEmissionData[year] = currentCarbonEmission.toString();
    carbonEmissionYearWise.push(carbonEmissionData);
  }
  return carbonEmissionYearWise;
};

const calculateNAE = (
  data: {
    carbonIntForCurrTechSource: number;
    index: number;
    year: number;
    firstColInfo: string;
    netAbsEmission: number;
    parameters: Iparameters;
  },
  carbonEmissionYearWise: YearWiseData[],
  stepTwoData: IStepTwoTableData[],
  state: ICalculatorFormState,
  dispatch: Idispatch,
) => {
  const { carbonIntForCurrTechSource, index, year, firstColInfo, parameters } = data;
  const gasFiredPowerParams = calculateCIforGasFiredPower(state, dispatch, stepTwoData);
  // calculate NAE for power but not gas fired power
  if (parameters.isCommodityPW && !['LNG', NATURAL_GAS].includes(firstColInfo)) {
    let tableCellValue: number | string = stepTwoData[index][year];
    if (typeof tableCellValue !== 'number') {
      tableCellValue = Number(stepTwoData[index][year].toString().replaceAll(',', ''));
    }
    data.netAbsEmission +=
      (tableCellValue * carbonIntForCurrTechSource) / (thousand * thousand * thousand);
    // save carbon emission data separately to use for save kpi data
    const currentCarbonEmission =
      (tableCellValue * carbonIntForCurrTechSource) / (thousand * thousand * thousand);
    saveCarbonEmissionDataForSaveKpiData(
      firstColInfo,
      year,
      carbonEmissionYearWise,
      currentCarbonEmission,
    );
  } else if (parameters.isCommodityPg) {
    // calculate nae for gas
    // 'Volume * 0.9 * Carbon Intensity * UOM Conversion Factor where 0.9 is for HHV to LHV conversion factor
    // UOM Conversion Factor is (3.6*0.293/1000000000) in MTPA for Americas
    // UOM Conversion Factor is (3.6/1000000000)  in MTPA for Rest of the world
    // UOM Conversion Factor is (10^9 / 10^12 = 0.001)  in MTPA for Australia
    const uomForPipelineGas: number = getUOMForPipelineGasAsCommodity(parameters);
    let tableCellValue = stepTwoData[index][year];
    // convert tableCellValue to number
    if (typeof tableCellValue !== 'number') {
      tableCellValue = Number(stepTwoData[index][year].toString().replaceAll(',', ''));
    }
    // All Asia with natural gas (MMBTU) - same as isAmericasWithCommodityPg, CI is always gCO2e/MJ
    // All Australia with Natural gas where UOM is PJ -
    // Net Absolute Emissions = Volume HHV in PJ *  0.9 (HHV to LHV) * Carbon Intensity for Rest of the World LHV in gCO2e/MJ * {10^9 (MJ/PJ) / 10^12 (gCO2e/MTPA)}
    // created a commonCalc that is the common calculation for all the cases.
    const commonCalc: number = tableCellValue * pointnine * carbonIntForCurrTechSource;
    const emissionForThisYear = commonCalc * uomForPipelineGas;
    data.netAbsEmission += emissionForThisYear; //  in MTPA
    const currentCarbonEmission = emissionForThisYear;
    saveCarbonEmissionDataForSaveKpiData(
      firstColInfo,
      year,
      carbonEmissionYearWise,
      currentCarbonEmission,
    );
  } else if (parameters.isGasFiredPower) {
    // finally if Gas Fired power is there, add nae of gas fired power to latest nae calculated
    data.netAbsEmission += gasFiredPowerParams.nae[year];
    saveCarbonEmissionDataForSaveKpiData(
      firstColInfo,
      year,
      carbonEmissionYearWise,
      gasFiredPowerParams.nae[year],
    );
  }
  return data.netAbsEmission;
};

const updateNetAbsEmission = (
  data: {
    yearWiseKPIrecord: IyearWiseKPIrecord;
    year: number;
    rowDataInfo: IKpiTableData;
    netAbsEmissionData: Record<number, string | number>;
    parameters: Iparameters;
    firstCol: string;
  },
  stepTwoData: IStepTwoTableData[],
  state: ICalculatorFormState,
  dispatch: Idispatch,
) => {
  const { yearWiseKPIrecord, year, rowDataInfo, netAbsEmissionData, parameters, firstCol } = data;
  // calculate total CO2 emissions
  let netAbsEmission = 0;
  let carbonIntForCurrTechSource = 0;
  for (let index = 0; index < stepTwoData.length - 1; index++) {
    const firstColInfo: string = stepTwoData[index][
      firstCol as keyof (typeof stepTwoData)[typeof index]
    ] as string;
    // Get CI for Commodity NG, Commodity PW with Tech Src as Grid and Non-Grid. CI for Tech Src GFP is calculated separately.
    // carbon intensity for commodity as Power and tech source that is not Grid nor Gas Fired Power
    if (parameters.isCommodityPW && !['Grid', 'LNG', NATURAL_GAS].includes(firstColInfo)) {
      carbonIntForCurrTechSource = getCIforTechSrcNonGridAndNonGFP(
        year,
        firstColInfo,
        yearWiseKPIrecord.carbonIntensityYearWise,
        yearWiseKPIrecord.carbonIntensityUomYearWise,
        yearWiseKPIrecord.timeStampDataYearWise,
        state,
      );
    } else if (parameters.isCommodityPW && firstColInfo === 'Grid') {
      // carbon intensity for commodity as Power and Grid tech source year wise
      carbonIntForCurrTechSource = getCIforTechSrcGrid(
        year,
        firstColInfo,
        yearWiseKPIrecord.carbonIntensityYearWise,
        yearWiseKPIrecord.carbonIntensityUomYearWise,
        yearWiseKPIrecord.timeStampDataYearWise,
        state,
      );
    } else if (parameters.isCommodityPg) {
      // carbon intensity for commodity as Natural Gas
      carbonIntForCurrTechSource = getCIforCommodityPG(
        year,
        firstColInfo,
        yearWiseKPIrecord.carbonIntensityYearWise,
        yearWiseKPIrecord.carbonIntensityUomYearWise,
        yearWiseKPIrecord.timeStampDataYearWise,
        state,
      );
    }

    netAbsEmission = calculateNAE(
      { carbonIntForCurrTechSource, index, year, firstColInfo, netAbsEmission, parameters },
      yearWiseKPIrecord.carbonEmissionYearWise,
      stepTwoData,
      state,
      dispatch,
    );
  }
  if (isNaN(netAbsEmission)) {
    rowDataInfo[year] = '-';
  } else {
    netAbsEmissionData[year] = netAbsEmission;
    // convert upto 3 decimals while showing in UI
    rowDataInfo[year] = convertInputToCommaSeparatedWithThreeDecimal(netAbsEmission.toFixed(three));
  }
  yearWiseKPIrecord.netAbsEmissionYearWise[year] = netAbsEmission;
};

const updateScope1CmbCrbnInt = (
  year: number,
  rowData: IKpiTableData,
  scope1CmbCrbnIntYearWiseObj: YearWiseData,
  state: ICalculatorFormState,
  dispatch: Idispatch,
  stepTwoData: IStepTwoTableData[],
) => {
  const gasFiredPowerParams = calculateCIforGasFiredPower(state, dispatch, stepTwoData);
  // convert upto 3 decimals while showing in UI
  rowData[year] = convertInputToCommaSeparatedWithThreeDecimal(
    gasFiredPowerParams.assetScope1[year].toFixed(three),
  );
  scope1CmbCrbnIntYearWiseObj[year] = rowData[year];
  scope1CmbCrbnIntYearWiseObj.techSource = state.fuelType;
  dispatch({ type: 'setScope1CmbCrbnInt', payload: rowData[year] });
};

const updateEquityScope = (
  year: number,
  rowData: IKpiTableData,
  equityScopeYearWiseObj: YearWiseData,
  equityScopeData: IRecord,
  state: ICalculatorFormState,
  dispatch: Idispatch,
  stepTwoData: IStepTwoTableData[],
) => {
  const gasFiredPowerParams = calculateCIforGasFiredPower(state, dispatch, stepTwoData);
  // convert upto 3 decimals while showing in UI
  rowData[year] = convertInputToCommaSeparatedWithThreeDecimal(
    gasFiredPowerParams.equityScope12[year].toFixed(three),
  );
  equityScopeYearWiseObj[year] = rowData[year];
  equityScopeData[year] = gasFiredPowerParams.equityScope12[year];
  equityScopeYearWiseObj.techSource = state.fuelType;
  dispatch({ type: 'setEquityScope', payload: rowData[year] });
};

const updateOperatedScope = (
  year: number,
  rowData: IKpiTableData,
  operatedScopeYearWiseObj: YearWiseData,
  state: ICalculatorFormState,
  dispatch: Idispatch,
  stepTwoData: IStepTwoTableData[],
) => {
  const gasFiredPowerParams = calculateCIforGasFiredPower(state, dispatch, stepTwoData);
  // convert upto 3 decimals while showing in UI
  rowData[year] = convertInputToCommaSeparatedWithThreeDecimal(
    gasFiredPowerParams.operatedScope12[year].toFixed(three),
  );
  operatedScopeYearWiseObj[year] = rowData[year];
  operatedScopeYearWiseObj.techSource = state.fuelType;
  dispatch({ type: 'setOperatedScope', payload: rowData[year] });
};

const updateAvgCarbonIntensity = (
  netAbsEmissionYearWise: Record<number, string | number>,
  year: number,
  rowData: IKpiTableData,
  parameters: Iparameters,
  state: ICalculatorFormState,
  stepTwoData: IStepTwoTableData[],
) => {
  let avgCarbonInt = 0;
  // UOM conversion factor = 1/1000 = 0.0001
  //
  // calculation for avg carbon intensity for power =>
  // ((vol of techsrc 1 for 2023 * CI of techsrc 1 for 2023 + vol of techsrc 2 for 2023 * CI of techsrc 2 for 2023....+ Vol of LNG/NG * PVCE if available) * 1000) /
  // (vol of techsrc 1 for 2023+ vol of techsrc 2 for 2023 + vol of LNG/NG if available)
  // OR
  // (NAE in MTPA / total volume in MWh) * 10^6 tCO2e/MWh
  if (parameters.isCommodityPW && stepTwoData.length > 0) {
    const netAbsEmissionForCurrYear = netAbsEmissionYearWise[year];
    let totalVolumeFinal = stepTwoData[stepTwoData.length - 1][year];
    if (typeof totalVolumeFinal !== 'number') {
      totalVolumeFinal = Number(totalVolumeFinal.toString().replaceAll(',', ''));
    }
    // final formula
    avgCarbonInt = (Number(netAbsEmissionForCurrYear) / totalVolumeFinal) * tenlakh;
  } else if (parameters.isCommodityPg && stepTwoData.length > 0) {
    const totalCarbonInt = state.carbonIntensityForGas.reduce(
      (total: number, item: { CARBON_INTENSITY: number }) => total + item.CARBON_INTENSITY,
      0,
    );
    // a simple averaging of all the carbon intensity values for Natural gas
    avgCarbonInt = totalCarbonInt / state.carbonIntensityForGas.length;
  }
  if (isNaN(avgCarbonInt)) {
    rowData[year] = '-';
  } else {
    // convert upto 3 decimals while showing in UI
    rowData[year] = avgCarbonInt.toFixed(three);
  }
};

const updateTotalColumn = (
  rowData: IKpiTableData,
  state: { startYear: number; endYear: number },
) => {
  let rowTotal = 0;
  for (let year = state.startYear; year < state.endYear + 1; year++) {
    if (rowData[year] !== undefined) {
      rowTotal += Number(rowData[year].toString().replaceAll(',', ''));
    }
  }
  if (isNaN(rowTotal)) {
    rowData.Total = '-';
  } else {
    rowData.Total = excludeTotalColumn.includes(rowData.KPI as string)
      ? '' // Avg Carbon Intensity is not summed up to show in UI, hence it goes empty.
      : convertInputToCommaSeparatedWithThreeDecimal(rowTotal.toFixed(three)); // convert upto 3 decimals while showing in UI
  }
};

const prepareRowData = (
  rowData: IKpiTableData[],
  cols: Array<string | number>,
  firstColData: string[],
) => {
  const firstCol = cols[0];
  // setting first key-value pair for each row object
  for (const col of firstColData) {
    rowData.push({ [firstCol]: col });
  }

  // default to 0
  for (const row of rowData) {
    for (let j = 1; j < cols.length; j++) {
      row[cols[j] as keyof typeof row] = '0.000';
    }
  }
};

const createUpdateFunctions = (
  data: {
    fieldIndex: number;
    rowDataInfo: IKpiTableData;
    equityScopeData: IRecord;
    netAbsEmissionData: IRecord;
    yearWiseKPIrecord: IyearWiseKPIrecord;
    parameters: Iparameters;
    stepTwoData: IStepTwoTableData[];
    firstCol: string;
  },
  state: ICalculatorFormState,
  dispatch: Idispatch,
) => {
  const {
    rowDataInfo,
    equityScopeData,
    netAbsEmissionData,
    yearWiseKPIrecord,
    parameters,
    stepTwoData,
    firstCol,
  } = data;
  // Increment 'fieldIndex' by 1, if technology source is not selected as Gas Fired Generation to match with the conditions.
  if (!parameters.isGasFiredPower) {
    data.fieldIndex += 1;
  }
  return [
    {
      condition: data.fieldIndex === 0 && rowDataInfo.KPI === ASSET_SCOPE1_CI,
      update: (year: number) => {
        updateScope1CmbCrbnInt(
          year,
          rowDataInfo,
          yearWiseKPIrecord.scope1CmbCrbnIntYearWiseObj,
          state,
          dispatch,
          stepTwoData,
        );
      },
    },
    {
      condition:
        parameters.isGasFiredPower && data.fieldIndex === 1 && rowDataInfo.KPI === OPERATED_SCOPE,
      update: (year: number) => {
        updateOperatedScope(
          year,
          rowDataInfo,
          yearWiseKPIrecord.operatedScopeYearWiseObj,
          state,
          dispatch,
          stepTwoData,
        );
      },
    },
    {
      condition:
        parameters.isGasFiredPower && data.fieldIndex === two && rowDataInfo.KPI === EQUITY_SCOPE,
      update: (year: number) => {
        updateEquityScope(
          year,
          rowDataInfo,
          yearWiseKPIrecord.equityScopeYearWiseObj,
          equityScopeData,
          state,
          dispatch,
          stepTwoData,
        );
      },
    },
    {
      condition: data.fieldIndex === three && rowDataInfo.KPI === NET_ABSOLUTE_EMISSIONS,
      update: (year: number) => {
        updateNetAbsEmission(
          { yearWiseKPIrecord, year, rowDataInfo, netAbsEmissionData, parameters, firstCol },
          stepTwoData,
          state,
          dispatch,
        );
      },
    },
    {
      condition: data.fieldIndex === four && rowDataInfo.KPI === AVG_CARBON_INT,
      update: (year: number) => {
        updateAvgCarbonIntensity(
          yearWiseKPIrecord.netAbsEmissionYearWise,
          year,
          rowDataInfo,
          parameters,
          state,
          stepTwoData,
        );
      },
    },
  ];
};

// calculate ci for gas fired power
const calculateCIforGasFiredPower = (
  state: ICalculatorFormState,
  dispatch: Idispatch,
  stepTwoData: IStepTwoTableData[],
) => {
  const isFuelTypeNG = state.technologySource.includes(NATURAL_GAS);
  if (isFuelTypeNG) {
    return calculateCIforGFPfuelTypeNG(state, dispatch, stepTwoData);
  }
  return calculateCIforGFPfuelTypeLNG(state, dispatch, stepTwoData);
};

const getMaxEquityScope = (myState: IKpiContentData) => {
  let maxOfEquityScope = Number.NEGATIVE_INFINITY;
  for (const year in myState.equityScopeDataYearWise) {
    if (year in myState.equityScopeDataYearWise) {
      const value = myState.equityScopeDataYearWise[year];
      if (value > maxOfEquityScope) {
        maxOfEquityScope = value;
      }
    }
  }
  return maxOfEquityScope;
};

const getMaxOfTotalCO2eAndTotalEmission = (myState: IKpiContentData) => {
  let totalEmission = 0;
  let maxOfTotalCO2e = Number.NEGATIVE_INFINITY;
  for (const year in myState.netAbsEmsDataYearWise) {
    if (year in myState.netAbsEmsDataYearWise) {
      const value = myState.netAbsEmsDataYearWise[year];
      if (value > maxOfTotalCO2e) {
        maxOfTotalCO2e = value;
      }
      totalEmission += Number(value);
    }
  }
  return { maxOfTotalCO2e, totalEmission };
};

const setCardRowDataValue = (value: number, rowName: string) => {
  if (isNaN(value) || !isFinite(value)) {
    // isFinite() is predefined function that covers both Infinity and -Infinity
    return '-';
  }
  if (
    [
      NET_ABS_EMISSION_SCOPE_PEAK_ANNUAL_EMISSION,
      EQUITY_SCOPE_CARBON_PEAK_ANNUAL_EMISSION,
    ].includes(rowName)
  ) {
    return convertInputToCommaSeparatedWithThreeDecimal(value.toFixed(three));
  }
  if (rowName === MARGIN_TO_CARBON_RATIO_AVG) {
    return convertBigIntToDecimal(value);
  }
  return '-';
};

const updateNetAbsEmsScopePeakAnnualEmission = (
  cardRowData: IKpiCardData[],
  i: number,
  myState: IKpiContentData,
  myDispatch: Idispatch,
) => {
  if (cardFirstColData[i] === NET_ABS_EMISSION_SCOPE_PEAK_ANNUAL_EMISSION) {
    const { maxOfTotalCO2e } = getMaxOfTotalCO2eAndTotalEmission(myState);
    cardRowData[i].Value = setCardRowDataValue(
      maxOfTotalCO2e,
      NET_ABS_EMISSION_SCOPE_PEAK_ANNUAL_EMISSION,
    );
    myDispatch({ type: 'setNetAbsEmsPeak', payload: cardRowData[i].Value });
  }
};

const updateMarginToCarbonRatioAvg = (
  cardRowData: IKpiCardData[],
  i: number,
  dispatch: Idispatch,
  myState: IKpiContentData,
  myDispatch: Idispatch,
  state: { grossMargin: { toNumber: () => number } },
) => {
  if (cardFirstColData[i] === MARGIN_TO_CARBON_RATIO_AVG) {
    let marginToCarbonRatioAvg = 0;
    const { totalEmission } = getMaxOfTotalCO2eAndTotalEmission(myState);
    // Gross Margin in Million USD/ sum of Net Absolute Emissions
    marginToCarbonRatioAvg = state.grossMargin.toNumber() / totalEmission;
    cardRowData[i].Value = setCardRowDataValue(marginToCarbonRatioAvg, MARGIN_TO_CARBON_RATIO_AVG);
    myDispatch({ type: 'setMcrAvg', payload: cardRowData[i].Value });
    dispatch({ type: 'setMcrAverage', payload: marginToCarbonRatioAvg });
  }
};

const updateEquityScopeCarbonPeakAnnualEmission = (
  cardRowData: IKpiCardData[],
  i: number,
  myState: IKpiContentData,
  myDispatch: Idispatch,
  parameters: Iparameters,
) => {
  if (cardFirstColData[i] === EQUITY_SCOPE_CARBON_PEAK_ANNUAL_EMISSION) {
    // for gas fired power
    let maxOfEquityScope = 0.0;
    if (parameters.isGasFiredPower) {
      maxOfEquityScope = getMaxEquityScope(myState);
    }
    cardRowData[i].Value = setCardRowDataValue(
      maxOfEquityScope,
      EQUITY_SCOPE_CARBON_PEAK_ANNUAL_EMISSION,
    );
    myDispatch({ type: 'setEquityScope12PeakEmission', payload: cardRowData[i].Value });
  }
};

const copyEquityScope12 = (myState: IKpiContentData, myDispatch: Idispatch) => {
  void navigator.clipboard.writeText(myState.equityScope12PeakEmission.toString());
  myDispatch({
    type: 'setCopiedContent',
    payload: EQUITY_SCOPE_CARBON_PEAK_ANNUAL_EMISSION,
  });
  myDispatch({
    type: 'setIsCopiedToClipboard',
    payload: true,
  });
  const timeoutId = setTimeout(() => {
    myDispatch({
      type: 'setIsCopiedToClipboard',
      payload: false,
    });
    // Clear the timeout immediately
    clearTimeout(timeoutId);
  }, thousandfivehundred);
};

const copyNaeScope12 = (myState: IKpiContentData, myDispatch: Idispatch) => {
  void navigator.clipboard.writeText(myState.netAbsEmsPeak.toString());
  myDispatch({
    type: 'setCopiedContent',
    payload: NET_ABS_EMISSION_SCOPE_PEAK_ANNUAL_EMISSION,
  });
  myDispatch({
    type: 'setIsCopiedToClipboard',
    payload: true,
  });
  const timeoutId = setTimeout(() => {
    myDispatch({
      type: 'setIsCopiedToClipboard',
      payload: false,
    });
    // Clear the timeout immediately
    clearTimeout(timeoutId);
  }, thousandfivehundred);
};

const copyMCR = (myState: IKpiContentData, myDispatch: Idispatch) => {
  void navigator.clipboard.writeText(myState.mcrAvg.toString());
  myDispatch({
    type: 'setCopiedContent',
    payload: MARGIN_TO_CARBON_RATIO_AVG,
  });
  myDispatch({
    type: 'setIsCopiedToClipboard',
    payload: true,
  });
  const timeoutId = setTimeout(() => {
    myDispatch({
      type: 'setIsCopiedToClipboard',
      payload: false,
    });
    // Clear the timeout immediately
    clearTimeout(timeoutId);
  }, thousandfivehundred);
};
