import React, { useMemo, useReducer } from 'react';
import CalculatorForm from '../CalculatorForm/CalculatorForm';
import { PageContainerComponent, PageContent } from './PageContainer.styles';
import '../CalculatorForm/CalculatorForm.css';
import { AppContext } from '../../context/calculatorFormContext';
import { calculatorFormReducer } from '../../reducers/ActionHandlers/CalculatorActionHandler';
import { calculateFormInitialState } from '../../reducers/InitialStates/CalculatorInitialState';
import Footer from '../Footer/Footer';
import { IAuthToken } from '../../types/types';

interface PageContainerProps {
  user: IAuthToken;
}

const PageContainer: React.FC<PageContainerProps> = ({ user }) => {
  const [state, dispatch] = useReducer(calculatorFormReducer, calculateFormInitialState);
  const value = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);
  return (
    <>
      <PageContainerComponent>
        <PageContent>
          <AppContext.Provider value={value}>
            <CalculatorForm userName={user.email} authUser={user} />
          </AppContext.Provider>
        </PageContent>
      </PageContainerComponent>
      <Footer />
    </>
  );
};

export default PageContainer;
