import React from 'react';
import './App.css';
import { ShellThemeProvider } from '@sede-x/shell-ds-react-framework';
import AppContent from './authPages/AppContent';
function App () {
  return (
    <div className='App'>
      <ShellThemeProvider theme='light'>
        <AppContent />
      </ShellThemeProvider>
    </div>
  );
}

export default App;
