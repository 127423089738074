import { type Action, ActionHandlers, StateGenericType } from '../types/types';

export const createReducer = (initialState: StateGenericType, handlers: ActionHandlers) => {
  return (state = initialState, action: Action) => {
    if (Object.hasOwn(handlers, action.type)) {
      const handler = handlers[action.type];
      return handler(state, action.payload);
    }
    return state;
  };
};
