import React, { createContext } from 'react';
import { type ICalculatorFormState, type Action } from '../types/types';
import { calculateFormInitialState } from '../reducers/InitialStates/CalculatorInitialState';

export const AppContext = createContext<{
  state: ICalculatorFormState;
  dispatch: React.Dispatch<Action>;
}>({
  state: calculateFormInitialState,
  dispatch: () => null,
});
