import {
  ActionHandlers,
  IKpiCardData,
  IKpiContentData,
  IRecord,
  IYearlyData,
} from '../../types/types';
import { kpiContentInitialState } from '../InitialStates/KpiContentInitialState';
import { createReducer } from '../ReducerFunction';

const actionHandlers: ActionHandlers = {
  setStepThreeTableData: (state: IKpiContentData, payload: IYearlyData) => ({
    ...state,
    stepThreeTableData: payload,
  }),
  setCardTableData: (state: IKpiContentData, payload: IKpiCardData) => ({
    ...state,
    cardTableData: payload,
  }),
  setEquityScope12PeakEmission: (state: IKpiContentData, payload: string) => ({
    ...state,
    equityScope12PeakEmission: payload,
  }),
  setNetAbsEmsPeak: (state: IKpiContentData, payload: string) => ({
    ...state,
    netAbsEmsPeak: payload,
  }),
  setMcrAvg: (state: IKpiContentData, payload: string) => ({
    ...state,
    mcrAvg: payload,
  }),
  setIsCopiedToClipboard: (state: IKpiContentData, payload: boolean) => ({
    ...state,
    isCopiedToClipboard: payload,
  }),
  setCopiedContent: (state: IKpiContentData, payload: string) => ({
    ...state,
    copiedContent: payload,
  }),
  setNetAbsEmsDataYearWise: (state: IKpiContentData, payload: IRecord) => ({
    ...state,
    netAbsEmsDataYearWise: payload,
  }),
  setEquityScopeDataYearWise: (state: IKpiContentData, payload: IRecord) => ({
    ...state,
    equityScopeDataYearWise: payload,
  }),
};
export const kpiContentReducer = createReducer(kpiContentInitialState, actionHandlers);
