import dayjs, { Dayjs } from 'dayjs';
import { EventValue, IDeals, IFilters, IRow, Idispatch } from '../../types/types';
import { notify } from '../Toast/notify';
import { twohundred } from '../../constants';
import moment from 'moment';
import { Flexbox, Link, SelectionCheckbox, Toggle } from '@sede-x/shell-ds-react-framework';

const tableGap = '20px';
export const EmptyHeader = () => <></>;
export const EmptyHeaderCell = (
  { row, getValue }: { row: IRow; getValue: () => string },
  selectedData: IDeals[],
  // eslint-disable-next-line no-unused-vars
  handleSingleCheckbox: (selectedItem: IDeals) => void,
) => {
  return (
    <Flexbox gap={tableGap} alignItems='center'>
      <SelectionCheckbox
        data-testid='checkbox'
        checked={selectedData.some(
          (item: IDeals) => item.OPPORTUNITY_ID === row.original.OPPORTUNITY_ID,
        )}
        indeterminate={row.getIsSomeSelected()}
        onChange={(event) => {
          row.getToggleSelectedHandler()(event);
          handleSingleCheckbox(row.original);
        }}
      />
      {getValue()}
    </Flexbox>
  );
};

export const dealMovedToDashboard = (
  { row, getValue }: { row: IRow; getValue: () => string },
  // eslint-disable-next-line no-unused-vars
  handleToggle: (selectedItem: IDeals) => Promise<void>,
) => {
  const toggleLabel = Number(getValue()) === 0 ? 'No' : 'Yes';
  return row.original.MOVE_CARBON_DASHBOARD !== null ? (
    <Flexbox gap={tableGap} alignItems='center'>
      {toggleLabel as string}
      <Toggle
        size='medium'
        data-testid='toggle'
        checked={row.original.MOVE_CARBON_DASHBOARD === 1}
        onChange={() => handleToggle(row.original)}
      />
    </Flexbox>
  ) : (
    '-'
  );
};
export const opportunityNameModal = (
  { row, getValue }: { row: IRow; getValue: () => string },
  // eslint-disable-next-line no-unused-vars
  handleModal: (selectedOpportunityId: number) => void,
) => {
  return (
    <Flexbox gap={tableGap} alignItems='center'>
      <Link
        data-testid='modal'
        onClick={() => handleModal(row.original.OPPORTUNITY_ID)}
        className='modal-link'
      >
        {getValue()}
      </Link>
    </Flexbox>
  );
};
export const showToast = (status: number, message: string) => {
  if (status === twohundred) {
    notify('success', message);
  } else {
    notify('error', message);
  }
};

export const handleDateChange = (
  e: EventValue<Dayjs> | { $d: Date },
  dispatch: Idispatch,
  type: string,
) => {
  if (e && '$d' in e && e.$d !== undefined) {
    dispatch({
      type,
      payload: dayjs(e.$d).format('YYYY-MM-DD'),
    });
  } else {
    dispatch({
      type,
      payload: null,
    });
  }
};

export const applyFilter = (allDealsData: IDeals[], filters: IFilters) => {
  const { opportunity, commodity, country, startDate, endDate, isEquityScope } = filters;
  const filterFunctions = [
    (deal: IDeals) =>
      opportunity.length !== 0 ? opportunity.includes(deal.OPPORTUNITY_NAME) : true,
    (deal: IDeals) => (commodity ? deal.TRADE_COMMODITY_NAME === commodity : true),
    (deal: IDeals) => (country ? deal.COUNTRY_NAME === country : true),
    (deal: IDeals) =>
      startDate ? moment(deal.START_DATE).isSameOrAfter(moment(String(startDate))) : true,
    (deal: IDeals) =>
      endDate ? moment(deal.END_DATE).isSameOrBefore(moment(String(endDate))) : true,
    (deal: IDeals) => (isEquityScope ? deal.EQUITY_SCOPE !== null : true),
  ];

  return allDealsData.filter((deal) => filterFunctions.every((fn) => fn(deal)));
};

export const disabledPrevDatesForEndDate = (current: Dayjs, filters: IFilters) =>
  current != null ? current.isBefore(filters.startDate, 'date') : false;

export const disabledNextDatesForStartDate = (current: Dayjs, filters: IFilters) =>
  current != null ? current.isAfter(filters.endDate, 'date') : false;

export const isAnyFieldNotEmpty = (filters: IFilters) => {
  const { opportunity, commodity, country, startDate, endDate, isEquityScope } = filters;
  return [
    opportunity.length === 0,
    commodity === null,
    country === null,
    !startDate,
    !endDate,
    !isEquityScope,
  ].every(Boolean);
};
