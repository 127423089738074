import { Modal, Accordion, AccordionPanel } from '@sede-x/shell-ds-react-framework';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getDealDetails } from '../../../api/services';
import { getYears } from './DealDetailFunctions';
import InputForm from './InputForm/InputForm';
import { IDealDetails } from '../../../types/types';
import ModalKpiTable from './ModalKpiTable/ModalKpiTable';
import ModalFieldsTable from './ModalFieldsTable/ModalFieldsTable';
import KpiSummary from './KpiSummary/KpiSummary';
import { AxiosError } from 'axios';
import { showToast } from '../utils';
import { KpiFormMessage } from '../../ManualEntryForm/KpiFormMessage';
import { fivehundres, one, two } from '../../../constants';

const Container = styled.div`
  overflow: scroll;
`;
interface DealDetailsProps {
  isOpen: boolean;
  opportunityId: number;
  onClose: () => void;
}
const DealDetails: React.FC<DealDetailsProps> = ({ isOpen, opportunityId, onClose }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dealDetailsData, setDealDetailsData] = useState<IDealDetails[]>([]);
  const [years, setYears] = useState<string[]>([]);

  const getDealDetailsData = async () => {
    setIsLoading(true);
    try {
      const res = await getDealDetails(opportunityId);
      setDealDetailsData(res?.data);
      setYears(getYears(res?.data));
    } catch (err) {
      const error = err as AxiosError<Error>;
      showToast(error?.response?.status ?? fivehundres, 'Error while fetching deal details.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDealDetailsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunityId]);

  return (
    <Modal
      loading={isLoading}
      loadingText={'Deal details loading'}
      open={isOpen}
      width={'97%'}
      contentScrollable={true}
      title={'Deal Summary'}
      onClose={onClose}
    >
      <Container>
        {dealDetailsData.length > 0 && (
          <Accordion activeKey={[0, one, two]}>
            <AccordionPanel
              className='accordion-box-shadow'
              header={
                <div>
                  <span className='acc-label'>
                    <b>STEP 01 &nbsp;&nbsp;</b>
                  </span>
                  <span className='sub-heading'>Deal Entry Form &nbsp;</span>
                </div>
              }
            >
              <InputForm selectConfig={dealDetailsData} />
              <KpiFormMessage />
            </AccordionPanel>
            <AccordionPanel
              className='accordion-box-shadow'
              header={
                <div>
                  <span className='acc-label'>
                    <b>STEP 02 &nbsp;&nbsp;</b>
                  </span>
                  <span className='sub-heading'>Input Fields Table &nbsp;</span>
                </div>
              }
            >
              <ModalFieldsTable
                dealDetailsData={dealDetailsData}
                years={years}
                commodityId={dealDetailsData[0]?.TRADE_COMMODITY_ID}
              />
            </AccordionPanel>
            <AccordionPanel
              className='accordion-box-shadow'
              header={
                <div>
                  <span className='acc-label'>
                    <b>STEP 03 &nbsp;&nbsp;</b>
                  </span>
                  <span className='sub-heading'>Carbon KPIs &nbsp;</span>
                </div>
              }
            >
              <KpiSummary dealDetailsData={dealDetailsData} />
              <ModalKpiTable dealDetailsData={dealDetailsData} years={years} />
            </AccordionPanel>
          </Accordion>
        )}
      </Container>
    </Modal>
  );
};

export default DealDetails;
