import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <div className='dashboard-footer'>
      {' '}
      © Shell Energy Integration & Fundamentals (SEIF) Carbon Portfolio Management.{' '}
      <em>Confidential data and graphs. Not for onward distribution.</em>
    </div>
  );
};

export default Footer;
