import { getSortedRowModel, getPaginationRowModel } from '@tanstack/react-table';
import { BaseTable, Card, Flexbox } from '@sede-x/shell-ds-react-framework';
import React, { useContext, useEffect, useState } from 'react';
import { IDeals, IRow, PaginationState, SortingState } from '../../../types/types';
import DeleteDeals from '../DeleteDeals/DeleteDeals';
import DealDetails from '../DealDetails/DealDetails';
import { deleteDeals, updateDeals } from '../../../api/services';
import {
  EmptyHeader,
  EmptyHeaderCell,
  applyFilter,
  dealMovedToDashboard,
  opportunityNameModal,
  showToast,
} from '../utils';
import { DEAL_DELETED, DEAL_MOVED, DEAL_REMOVED } from '../../../api/constants';
import Loader from '../../Loader';
import { FiltersContext } from '../../../context/FiltersContext';
import { fiveHundreds, ten, twohundred } from '../../../constants';
import { AxiosError } from 'axios';

interface AllCarbonDealsProps {
  dealsData: IDeals[];
  handleDeleteDeals: () => Promise<void>;
}
const AllCarbonDeals: React.FC<AllCarbonDealsProps> = ({ dealsData, handleDeleteDeals }) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectedData, setSelectedData] = useState<IDeals[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allDealsData, setAllDealsData] = useState<IDeals[]>([]);
  const [message, setMessage] = useState<string>('');
  const { filters, dispatch } = useContext(FiltersContext);
  const [opportunityId, setOpportunityId] = useState<number>();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isToggle, setIsToggle] = useState<boolean>(false);
  const [paginationState, setPaginationState] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: ten,
  });

  useEffect(() => {
    setIsOpenModal(true);
  }, [opportunityId]);

  useEffect(() => {
    setAllDealsData(dealsData);
  }, [dealsData]);

  useEffect(() => {
    if (filters.isApplyFilterClicked) {
      const filterData = applyFilter(dealsData, filters);
      setAllDealsData(filterData);
      dispatch({
        type: 'setIsApplyFilterClicked',
        payload: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.isApplyFilterClicked]);

  useEffect(() => {
    const { isClearAll } = filters;
    // when no filter selected or clear all, reset the table data.
    if (isClearAll) {
      setAllDealsData(dealsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.isClearAll]);

  const handleSingleCheckbox = (selectedItem: IDeals) => {
    const hasItem = selectedData?.some(
      (item: IDeals) => item.OPPORTUNITY_ID === selectedItem.OPPORTUNITY_ID,
    );

    if (hasItem) {
      setSelectedData(
        selectedData.filter((item: IDeals) => item.OPPORTUNITY_ID !== selectedItem.OPPORTUNITY_ID),
      );
    } else {
      setSelectedData([...selectedData, selectedItem]);
    }
  };

  const updateDataOnToggle = (toggleOpportunityId: number, moveCarbonDashboard: number) => {
    setIsToggle(true);
    const updatedData = allDealsData.map((item: IDeals) =>
      item.OPPORTUNITY_ID === toggleOpportunityId
        ? { ...item, MOVE_CARBON_DASHBOARD: Number(moveCarbonDashboard) === 0 ? 1 : 0 }
        : item,
    );
    setAllDealsData(updatedData);
  };

  const handleToggle = async (selectedItem: IDeals) => {
    setIsLoading(true);
    // Update the allDealsData
    const { OPPORTUNITY_ID, MOVE_CARBON_DASHBOARD } = selectedItem;
    setMessage(MOVE_CARBON_DASHBOARD === 0 ? DEAL_MOVED : DEAL_REMOVED);
    // changing the toggle state.
    updateDataOnToggle(OPPORTUNITY_ID, MOVE_CARBON_DASHBOARD);
    try {
      const result = await updateDeals({ OPPORTUNITY_ID, MOVE_CARBON_DASHBOARD });
      showToast(result?.status, MOVE_CARBON_DASHBOARD === 0 ? DEAL_MOVED : DEAL_REMOVED);
      if (result?.status !== twohundred) {
        updateDataOnToggle(OPPORTUNITY_ID, Math.abs(MOVE_CARBON_DASHBOARD - 1));
      }
    } catch (err) {
      const error = err as AxiosError<Error>;
      showToast(error?.response?.status ?? fiveHundreds, 'Error while update the deal.');
    } finally {
      setIsLoading(false);
    }
  };

  const onDeleteDeals = async (selectedDeals: IDeals[]) => {
    setIsLoading(true);
    setMessage('Please wait, we are deleting the selected deals.');
    try {
      const result = await deleteDeals(selectedDeals);
      showToast(result?.status, DEAL_DELETED);
      if (result?.status === twohundred) {
        handleDeleteDeals();
        dispatch({
          type: 'setClearAll',
          payload: true,
        });
      }
      setSelectedData([]);
    } catch (err) {
      const error = err as AxiosError<Error>;
      showToast(error?.response?.status ?? fiveHundreds, 'Error while deleting the deals.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleModal = (selectedOpportunityId: number) => {
    setOpportunityId(selectedOpportunityId);
  };
  const closeModal = () => {
    setIsOpenModal(false);
    setOpportunityId(undefined);
  };
  const columns = [
    {
      cell: ({ row, getValue }: { row: IRow; getValue: () => string }) =>
        EmptyHeaderCell({ row, getValue }, selectedData, handleSingleCheckbox),
      header: EmptyHeader,
      enableSorting: false,
      accessorKey: 'checkbox',
    },
    {
      cell: ({ row, getValue }: { row: IRow; getValue: () => string }) =>
        opportunityNameModal({ row, getValue }, handleModal),
      enableSorting: true,
      accessorKey: 'OPPORTUNITY_NAME',
      header: 'Opportunity Name',
    },
    {
      accessorKey: 'BUSINESS_NAME',
      header: 'Business',
      enableSorting: true,
    },
    {
      accessorKey: 'TRADE_COMMODITY_NAME',
      header: 'Commodity',
      enableSorting: true,
    },
    {
      accessorKey: 'COUNTRY_NAME',
      header: 'Country',
      enableSorting: true,
    },
    {
      accessorKey: 'EQUITY_SCOPE',
      header: 'Scope 1 & 2 (Operational Control)',
      enableSorting: true,
    },
    {
      accessorKey: 'CARBON_EMISSIONS',
      header: 'Peak Net Absolute Emissions',
      enableSorting: true,
    },
    {
      accessorKey: 'MCR',
      header: 'Margin to Carbon Ratio',
      enableSorting: true,
    },
    {
      accessorKey: 'START_DATE',
      header: 'Start Date',
      enableSorting: true,
    },
    {
      accessorKey: 'END_DATE',
      header: 'End Date',
      enableSorting: true,
    },
    {
      header: 'Deal moved to Carbon Dashboard',
      cell: ({ row, getValue }: { row: IRow; getValue: () => string }) =>
        dealMovedToDashboard({ row, getValue }, handleToggle),
      accessorKey: 'MOVE_CARBON_DASHBOARD',
      enableSorting: false,
    },
  ];

  const handlePaginationChange = (newPaginationState: React.SetStateAction<PaginationState>) => {
    isToggle ? setIsToggle(false) : setPaginationState(newPaginationState);
  };
  const { pageSize, pageIndex } = paginationState;
  const tableOptions = {
    state: {
      sorting,
      pagination: { pageSize, pageIndex },
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: handlePaginationChange,
  };
  return (
    <>
      <Loader isLoading={isLoading} message={message} />
      {opportunityId && (
        <DealDetails isOpen={isOpenModal} onClose={closeModal} opportunityId={opportunityId} />
      )}
      <Card
        bodyPadding={false}
        padding='tight'
        bodyBgColor='base'
        borders={false}
        className='all-deals-card'
      >
        <div style={{ background: 'white', padding: '5px 10px 10px 10px' }}>
          <Flexbox justifyContent='space-between' gap='10px'>
            <div className='deals-count'>No. of deals: {allDealsData.length}</div>
            <DeleteDeals selectedDeals={selectedData} handleDeleteDeals={onDeleteDeals} />
          </Flexbox>
        </div>
        <BaseTable
          noFooter
          className='all-deals-table'
          maxHeight={1000}
          columns={columns}
          data={allDealsData}
          tableOptions={tableOptions}
        />
      </Card>
    </>
  );
};

export default AllCarbonDeals;
