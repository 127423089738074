import styled from 'styled-components';

export const AllDealsContainer = styled.div`
  background: #f5f5f5;
  padding-top: 70px;
`;
export const AllCarbonDealsHeading = styled.div`
  //styleName: Label/Extra Large/Demi;
  font-family: Shell Font;
  font-size: 20px;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
  color: #343434;
`;

export const AllCarbonDealsSummary = styled.div`
  //styleName: Paragraph/Small/Italic;
  font-family: Shell Font;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #757575;
`;

export const AllCarbonDealsContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

export const FooterContent = styled.div`
  color: #000000;
  margin-top: 15px;
`;

export const ModalBodyContent = styled.div`
  padding: 20px;
  text-align: center;
`;

export const ModalTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0em;
  font-style: normal;
  padding: 4px;
`;
export const ModalSubTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 4px;
`;
export const ModalWarning = styled.div`
  position: absolute;
  padding-top: 100px;
  font-style: italic;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  left: 60px;
`;
