import React, { useMemo, useReducer } from 'react';

import { IAuthToken } from '../../types/types';
import { FiltersContext } from '../../context/FiltersContext';
import { filtersReducer } from '../../reducers/ActionHandlers/FiltersActionHandler';
import { intitialFiltersState } from '../../reducers/InitialStates/FiltersInitialState';
import AllDeals from '../AllDeals/AllDeals';

interface PageContainerProps {
  user: IAuthToken;
}

const AllDealsContainerPage: React.FC<PageContainerProps> = ({ user }) => {
  const [filters, dispatch] = useReducer(filtersReducer, intitialFiltersState);
  const value = useMemo(() => {
    return { filters, dispatch };
  }, [filters, dispatch]);
  return (
    <FiltersContext.Provider value={value}>
      <AllDeals user={user} />
    </FiltersContext.Provider>
  );
};

export default AllDealsContainerPage;
