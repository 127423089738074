import { ActionHandlers, InputTableFormState } from '../../types/types';
import { inputTableInitialState } from '../InitialStates/InputTableInitialState';
import { createReducer } from '../ReducerFunction';

const actionHandlers: ActionHandlers = {
  setIsLoading: (state: InputTableFormState, payload: boolean) => ({
    ...state,
    isLoading: payload,
  }),
  setCols: (state: InputTableFormState, payload: string[]) => ({ ...state, cols: payload }),
  setIsPasteDataFailure: (state: InputTableFormState, payload: boolean) => ({
    ...state,
    isPasteDataFailure: payload,
  }),
};
export const inputTableReducer = createReducer(inputTableInitialState, actionHandlers);
