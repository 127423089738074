/* eslint-disable no-undef */
import axios from 'axios';
import { AuthService } from '../authPages/AuthService';

const instance = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL, timeout: 10000 });
const sessionId = `oidc.user:${process.env.REACT_APP_SSO_AUTHORITY_URL}:${process.env.REACT_APP_SSO_CLIENT_ID}`;
const loggedInUser = sessionStorage.getItem(sessionId);
let loggedInUserDetails = loggedInUser && JSON.parse(loggedInUser);
const authService = new AuthService();

const refreshToken = async (isTokenExpired: boolean) => {
  try {
    return await authService.login(isTokenExpired);
  } catch (error: unknown) {
    // If there is any error while refreshing the token, logging out the user.
    if (error instanceof Error && error.message === 'invalid_grant') {
      // Force a full login if the error is 'invalid_grant'
      return authService.login(false);
    }
    return authService.logout();
  }
};

instance.interceptors.request.use(
  async (config) => {
    const expireTimestamp = loggedInUserDetails?.expires_at;
    const milliseconds = 1000;
    const currentTimestamp = new Date().getTime() / milliseconds;
    const isTokenExpired = expireTimestamp && expireTimestamp <= currentTimestamp;
    // checking token expire and getting new token using silent login.
    if (isTokenExpired) {
      loggedInUserDetails = await refreshToken(isTokenExpired);
    }
    const accessToken = loggedInUserDetails?.access_token;
    if (accessToken && config.headers) {
      (config.headers as { [key: string]: string })['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  async () => {
    // If there is any error while refresh token, logging out the user.
    await authService.logout();
  },
);

export default instance;
