import Decimal from 'decimal.js';
import {
  type SaveKpiDataI,
  type ICalculatorFormState,
  type ICarbonEmissionYearWise,
  type YearWiseData,
} from './types/types';
import {
  FETCH_ERROR,
  GAS_FIRED_GENERATION,
  GRID,
  MCR_AVG_UOM,
  PIPELINE_GAS,
  contactPerson,
} from './api/constants';
import { saveKpiData, downloadKpiData } from './api/services';
import { four, hundred, six, ten, three, twelve, two } from './constants';
import { notify } from './components/Toast/notify';

export const regexForOneToHundredUptoTwoDecimals = /^(100(\.0{1,2})?|[1-9]\d?(\.\d{1,2})?)$/;

// the below function is used to convert step 2 table input to comma separated number with max 12 whole digits and 6 decimal
export const convertInputToCommaSeparatedWithTwoDecimal = (input: string) => {
  let sanitizedInput = input.replace(/[^0-9.]/g, ''); // Remove any invalid characters except . and -

  // Remove any extra decimals and negative signs
  sanitizedInput = sanitizedInput.replace(/([.-])(?=.+[.-])/g, '');

  // Remove leading zeros
  sanitizedInput = sanitizedInput.replace(/^0+(?=\d)/, '');

  // Remove leading negative sign if it's not the first character
  sanitizedInput = sanitizedInput.replace(/^-/, '');

  // remove any second decimal dot if there are two consecutive dots
  sanitizedInput = sanitizedInput.replace(/\.{2,}/g, '.');

  if (sanitizedInput === '' || isNaN(Number(sanitizedInput))) {
    return '0'; // Return 0 if input is not a number or is empty
  }

  let [whole, decimal = ''] = sanitizedInput.split('.'); // Split input into whole and decimal parts

  // Remove the 13th digit
  if (whole.replace('.', '').replace('-', '').length > twelve) {
    whole = whole.slice(0, -1);
  }

  // Truncate whole part to 12 digits if necessary
  if (whole.length > twelve) {
    whole = whole.slice(0, twelve);
  }

  // Truncate decimal part to 2 digits if necessary
  decimal = decimal.slice(0, six);

  let formattedInput = Number.parseFloat(whole).toLocaleString(); // Convert input to number and format as comma-separated string

  // if just a decimal dot is there, but no decimal part, then append a 0
  if (sanitizedInput.includes('.')) {
    if (decimal === '') {
      formattedInput = formattedInput + '.';
    } else {
      formattedInput = `${formattedInput} . ${decimal}`;
    }
  }

  return formattedInput.replace(/^-/, ''); // Remove any leading negative sign from the formatted input
};
// the below function is used to convert step 2 table INPUT AS PASTED DATA to comma separated number with max 16 whole digits and 2 decimal
export const convertCopiedInputToCommaSeparatedWithTwoDecimal = (value: string) => {
  // Remove any non-digit characters and decimals with more than two decimal places
  const cleanedInput = value.replace(/[^0-9.]/g, '').replace(/(\.\d{2})\d+/g, '$1');

  // Parse the cleaned input to a number, or return an empty string if it's not a valid number
  const number = parseFloat(cleanedInput);
  if (isNaN(number)) {
    return '';
  }

  // Format the number with commas and up to two decimal places, with only one dot
  const parts = cleanedInput.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (parts.length > 1) {
    parts[1] = parts[1].substring(0, two).padEnd(two, '0');
    return `${parts[0]}.${parts[1]}`;
  } else {
    return parts[0];
  }
};
// the below function is used to convert step 3 table data to comma separated number upto 3 decimal
export const convertInputToCommaSeparatedWithThreeDecimal = (value: string) => {
  // Remove any non-digit characters and decimals with more than three decimal places
  const cleanedInput = value.replace(/[^0-9.]/g, '').replace(/(\.\d{3})\d+/g, '$1');

  // Parse the cleaned input to a number, or return an empty string if it's not a valid number
  const number = parseFloat(cleanedInput);
  if (isNaN(number)) {
    return '';
  }

  // Format the number with commas and up to three decimal places, with only one dot
  const parts = cleanedInput.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (parts.length > 1) {
    parts[1] = parts[1].substring(0, three).padEnd(three, '0');
    return `${parts[0]}.${parts[1]}`;
  } else {
    return parts[0];
  }
};
// the below function is to convert bigInt numbers to decimal numbers upto 3 places
export const convertBigIntToDecimal = (value: number) => {
  const PRECISION = 3;

  const splittedNumber: string[] = value.toString().split('.');
  let actualNumber: string = splittedNumber[1]
    ? `${splittedNumber[0]} . ${splittedNumber[1].substring(0, four)}`
    : splittedNumber[0];
  actualNumber = Number(actualNumber.replace(/\s/g, '')).toFixed(PRECISION).toString();
  return actualNumber;
};

// Function for download KPI deal for specific user
export const handleDownloadKpiDeal = () => {
  (async () => {
    try {
      // Make a GET request to download the CSV file from the API
      const response = await downloadKpiData();
      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: 'text/csv' });
      const filename = 'CPM_CarbonKPICalc_DealDump';
      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);
      // Programmatically create a link
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      // Simulate a click event to initiate the download
      document.body.appendChild(link);
      link.click();
      // Cleanup: Remove the temporary URL and the link element
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch {
      notify('error', FETCH_ERROR);
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  })().catch(() => {});
};
const formatDateToISOString = (dateString: string) => {
  return new Date(dateString).toISOString().slice(0, ten);
};

const getVolumeByUser = (
  state: ICalculatorFormState,
  year: number,
  currentCommodity: string,
  currentTechSrc?: string,
) => {
  let userInput = new Decimal(0);
  for (const rowData of state.stepTwoTableData) {
    const commodity = currentCommodity === 'NG' ? state.commodity : currentTechSrc;
    if (rowData[state.stepTwoTableFirstCol as unknown as keyof typeof rowData] === commodity) {
      userInput = rowData[year as unknown as keyof typeof rowData] as unknown as Decimal;
      break;
    }
  }
  return userInput;
};

export const handleSaveDeal = async (state: ICalculatorFormState, userName: string) => {
  // write api to save data to DB
  const kpiDataDefaultObject: SaveKpiDataI = getDefaultKpiObject(state, userName);
  const saveKpiDataObj: SaveKpiDataI[] = [kpiDataDefaultObject];
  if (!isNaN(state.mcrAverage) && isFinite(state.mcrAverage)) {
    kpiDataDefaultObject.MCR = state.mcrAverage;
  }
  setKpiObjectForPipelineGas(kpiDataDefaultObject, state, saveKpiDataObj);
  setKpiObjectForPower(kpiDataDefaultObject, state, saveKpiDataObj);
  saveKpiDataObj.shift(); // remove first object which is a dummy
  // make api to save data to db
  try {
    await saveKpiData(saveKpiDataObj);
  } catch {
    notify('error', FETCH_ERROR);
  }
};

const getDefaultKpiObject = (state: ICalculatorFormState, userName: string) => {
  return {
    OPPORTUNITY_NAME: state.opportunity,
    TRADE_COMMODITY_ID: Number(state.commodityId),
    TECHNOLOGY_SOURCE_ID: null, // each object for a technology source, NULL if it is gas fired generation.
    COUNTRY_ID: Number(state.countryId),
    GRID_ID: null, // grid location id. NULL if technology source is not GRID
    BUSINESS_ID: Number(state.businessId),
    GROSS_MARGIN: state.grossMargin,
    CURRENCY: 'USD', // unit of measure of Gross margin
    START_DATE: formatDateToISOString(state.startDate),
    END_DATE: formatDateToISOString(state.endDate),
    YEAR: state.startYear, // will iterate for all years
    QUANTITY: '0', // user entered volume for the technology source and Year, to be iterated
    QUANTITY_UNIT: state.uomVal, // UOM to be picked from form input
    FUEL_TYPE: '', // NULL if technology source is not Gas Fired Generation
    ASSET_TYPE: '', // NULL if commodity is not power and technology source is not Gas fired generation.
    OPERATING_MODEL: '', // for Gas Fired Generation
    EQUITY_SHARE: Number(state.equityShare) * hundred, // from form
    EFFICIENCY: Number(state.efficiency) * hundred, // from form
    OPERATED_SCOPE: null,
    OPERATED_SCOPE_UNIT: 'MTPA',
    EQUITY_SCOPE: null,
    EQUITY_SCOPE_UNIT: 'MTPA',
    CARBON_EMISSIONS: 1.0, // carbon emission for that technology source and Year, PVCE*volume*UOM for Gas Fired Generation
    CARBON_EMISSIONS_UNIT: 'MTPA',
    CARBON_INTENSITY: 1.0, // carbon intensity for that technology source and Year, PVCE for Gas Fired Generation
    CARBON_INTENSITY_UNIT: '', // check with backend to receive in CI api
    DEALMAKER_USERID: userName.toLowerCase(), // singed in user,
    CI_EFL_last_update_timestamp: '', // the time stamp is received for each Carbon Intensity Api calls in InputTable component
    Power_Performance_Standards: '', // the power performance standard messages
    UOM: MCR_AVG_UOM, // the UOM for MCR average calculated
  };
};

const setKpiObjectForPipelineGas = (
  kpiDataDefaultObject: SaveKpiDataI,
  state: ICalculatorFormState,
  saveKpiDataObj: SaveKpiDataI[],
) => {
  // for commodity natural gas
  if (state.commodity.replaceAll(' ', '').toLocaleLowerCase() === 'pipelinegas') {
    for (let year = state.startYear; year < state.endYear + 1; year++) {
      // set irrelevant parameters to null or empty
      kpiDataDefaultObject.TECHNOLOGY_SOURCE_ID = null;
      kpiDataDefaultObject.GRID_ID = null;
      kpiDataDefaultObject.FUEL_TYPE = '';
      kpiDataDefaultObject.OPERATING_MODEL = '';
      kpiDataDefaultObject.EQUITY_SHARE = null;
      kpiDataDefaultObject.EFFICIENCY = null;
      kpiDataDefaultObject.OPERATED_SCOPE = null;
      kpiDataDefaultObject.OPERATED_SCOPE_UNIT = '';
      kpiDataDefaultObject.EQUITY_SCOPE = null;
      kpiDataDefaultObject.EQUITY_SCOPE_UNIT = '';
      kpiDataDefaultObject.ASSET_TYPE = '';
      kpiDataDefaultObject.Power_Performance_Standards = '';
      // set year
      kpiDataDefaultObject.YEAR = year;
      const volumeByUserForThisTechSrcAndThisYear = getVolumeByUser(state, year, 'NG');
      // set quantity
      kpiDataDefaultObject.QUANTITY = volumeByUserForThisTechSrcAndThisYear
        .toString()
        .replaceAll(',', '');
      // set carbon emission
      kpiDataDefaultObject.CARBON_EMISSIONS = state.carbonEmissionYearWise[0][year];
      // set carbon intensity
      kpiDataDefaultObject.CARBON_INTENSITY = state.carbonIntensityYearWise[0][year];
      // set carbon intensity UOM
      kpiDataDefaultObject.CARBON_INTENSITY_UNIT = state.carbonIntensityUomYearWise[0][
        year
      ] as string;
      // set Time Stamp Data
      kpiDataDefaultObject.CI_EFL_last_update_timestamp = state.timeStampDataYearWise[0][
        year
      ] as string;
      // save object for this year
      const finalKpiData: SaveKpiDataI = { ...kpiDataDefaultObject };
      saveKpiDataObj.push(finalKpiData);
    }
  }
};

const setKpiObjectForPower = (
  kpiDataDefaultObject: SaveKpiDataI,
  state: ICalculatorFormState,
  saveKpiDataObj: SaveKpiDataI[],
) => {
  // for commodity as power
  if (state.commodity.toLowerCase() === 'power') {
    for (let techSrc = 0; techSrc < state.technologySource.length; techSrc++) {
      const currentTechSrc = state.selectedTechnologySources[techSrc].value;
      kpiDataDefaultObject.TECHNOLOGY_SOURCE_ID = state.selectedTechnologySources[techSrc].id;
      setGridId(currentTechSrc, kpiDataDefaultObject, state);
      for (let year = state.startYear; year < state.endYear + 1; year++) {
        kpiDataDefaultObject.YEAR = year;
        const volumeByUserForThisTechSrcAndThisYear = getVolumeByUser(
          state,
          year,
          'PW',
          currentTechSrc,
        );
        // set quantity
        kpiDataDefaultObject.QUANTITY = volumeByUserForThisTechSrcAndThisYear
          .toString()
          .replaceAll(',', '');
        getCarbonEmissionYearWise(
          state.carbonEmissionYearWise,
          currentTechSrc,
          year,
          kpiDataDefaultObject,
        );
        getCarbonIntYearWiseForNonGfpTechSrc(
          state.carbonIntensityYearWise,
          state.carbonIntensityUomYearWise,
          state.timeStampDataYearWise,
          currentTechSrc,
          year,
          kpiDataDefaultObject,
        );
        // attributes for Gas Fired Generation
        kpiDataDefaultObject.Power_Performance_Standards = '';
        setKpiDefaultObjForGFP(currentTechSrc, state, kpiDataDefaultObject, year);
        // save object for this year
        const finalKpiData: SaveKpiDataI = { ...kpiDataDefaultObject };
        saveKpiDataObj.push(finalKpiData);
      }
    }
  }
};

const setGridId = (
  currentTechSrc: string,
  kpiDataDefaultObject: SaveKpiDataI,
  state: ICalculatorFormState,
) => {
  if (currentTechSrc === GRID) {
    kpiDataDefaultObject.GRID_ID = state.gridLocationId;
  } else {
    kpiDataDefaultObject.GRID_ID = null;
  }
};

const getCarbonEmissionYearWise = (
  carbonEmissionYearWise: YearWiseData[],
  currentTechSrc: string,
  year: number,
  kpiDataDefaultObject: SaveKpiDataI,
) => {
  // get carbon emission year wise
  let carbonEmissionForThisYearAndThisTechSource = 0;
  for (const element of carbonEmissionYearWise) {
    const emissionObj: ICarbonEmissionYearWise = element as ICarbonEmissionYearWise;
    if (emissionObj.techSource === currentTechSrc) {
      carbonEmissionForThisYearAndThisTechSource = parseFloat(emissionObj[year]);
      break;
    }
  }
  kpiDataDefaultObject.CARBON_EMISSIONS = carbonEmissionForThisYearAndThisTechSource;
};

const getCarbonIntYearWiseForNonGfpTechSrc = (
  carbonIntensityYearWise: string | YearWiseData[],
  carbonIntensityUomYearWise: string | YearWiseData[],
  timeStampDataYearWise: string | YearWiseData[],
  currentTechSrc: string,
  year: number,
  kpiDataDefaultObject: SaveKpiDataI,
) => {
  // get carbon intensity year wise for non-GFP tech sources
  let carbonIntensityForThisYearAndThisTechSource = 0;
  let carbonIntensityUomForThisYearAndThisTechSource = '';
  let timeStampDataForThisYearAndThisTechSource = '';
  for (let ciIntensityObj = 0; ciIntensityObj < carbonIntensityYearWise.length; ciIntensityObj++) {
    const ciObj: ICarbonEmissionYearWise = carbonIntensityYearWise[
      ciIntensityObj
    ] as ICarbonEmissionYearWise;
    const ciUomObj: ICarbonEmissionYearWise = carbonIntensityUomYearWise[
      ciIntensityObj
    ] as ICarbonEmissionYearWise;
    const timeStampObj: ICarbonEmissionYearWise = timeStampDataYearWise[
      ciIntensityObj
    ] as ICarbonEmissionYearWise;
    if (ciObj.techSource === currentTechSrc) {
      carbonIntensityForThisYearAndThisTechSource = parseFloat(ciObj[year]);
      carbonIntensityUomForThisYearAndThisTechSource = ciUomObj[year];
      timeStampDataForThisYearAndThisTechSource = timeStampObj[year];
      break;
    }
  }
  kpiDataDefaultObject.CARBON_INTENSITY = carbonIntensityForThisYearAndThisTechSource;
  kpiDataDefaultObject.CARBON_INTENSITY_UNIT = carbonIntensityUomForThisYearAndThisTechSource;
  kpiDataDefaultObject.CI_EFL_last_update_timestamp = timeStampDataForThisYearAndThisTechSource;
};

const setKpiDefaultObjForGFP = (
  currentTechSrc: string,
  state: ICalculatorFormState,
  kpiDataDefaultObject: SaveKpiDataI,
  year: number,
) => {
  const isGasFiredPower = [PIPELINE_GAS, 'LNG', GAS_FIRED_GENERATION].includes(currentTechSrc);
  if (isGasFiredPower) {
    kpiDataDefaultObject.FUEL_TYPE = state.fuelType;
    kpiDataDefaultObject.OPERATING_MODEL = state.operatingModel;
    kpiDataDefaultObject.EQUITY_SCOPE = Number(
      state.equityScopeYearWiseObj[year].toString().replaceAll(',', ''),
    );
    kpiDataDefaultObject.OPERATED_SCOPE = Number(
      state.operatedScopeYearWiseObj[year].toString().replaceAll(',', ''),
    );
    kpiDataDefaultObject.CARBON_INTENSITY = state.carbonIntensityForGasFiredPowerYearWise[year];
    kpiDataDefaultObject.CARBON_INTENSITY_UNIT = 'gCO2e/KWH';
    kpiDataDefaultObject.ASSET_TYPE = state.assetType;
    kpiDataDefaultObject.CI_EFL_last_update_timestamp =
      state.timeStampDataForGasFiredPowerYearWise[year];
    kpiDataDefaultObject.Power_Performance_Standards =
      state.powerPerformanceStandardKpiMessage.replace(/<\/?strong>/g, '');
  }
};

// Function to replace email addresses with clickable email links
export const attachEmailLinkToText = (message: string) => {
  const regex = /([\w.-]+@[\w.-]+)/g;
  if (message) {
    return message.split(regex).map((part: string, index: number) => {
      if (index % two === 0) {
        return part; // Non-email text
      } else {
        const email: string = part.trim();
        const name: string = contactPerson.filter((person) => person.email.includes(email))[0].name;
        return (
          <a key={email} href={`mailto:${email}`}>
            {name}
          </a>
        );
      }
    });
  } else {
    return '';
  }
};

export const containsHTMLTags = (inputString: string) => {
  const htmlTagPattern = /<[^>]*>/;
  return htmlTagPattern.test(inputString);
};
