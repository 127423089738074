import { Text } from '@sede-x/shell-ds-react-framework';

export const KpiFormMessage = () => {
  return (
    <Text size='small' style={{ color: '#000000', marginTop: '5px' }}>
      <em>
        Base case gross margin i.e. Revenue less purchases less direct variable trading costs (e.g.
        storage, transportation, transmission) to be presented in USD, MOD. <br />
        Deals denominated in other currencies will require translation using group FX rates.
      </em>
    </Text>
  );
};
