import { Alert, Sentiments } from '@sede-x/shell-ds-react-framework';
import { IDispatch } from '../../types/types';

export const WarningMessage = (props: { showMessage: boolean; myDispatch: IDispatch }) => {
  const { showMessage, myDispatch } = props;
  const hideAlert = () => {
    myDispatch({
      type: 'setIsWarningMsgAvailable',
      payload: false,
    });
  };
  return showMessage ? (
    <p>
      <Alert dismissible sentiment={Sentiments.Warning} onClick={hideAlert}>
        Please fill all the required fields!!
      </Alert>
    </p>
  ) : null;
};
