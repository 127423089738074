export const intitialFiltersState = {
  opportunity: [],
  commodity: null,
  country: null,
  startDate: null,
  endDate: null,
  isEquityScope: false,
  isApplyFilterClicked: false,
  isClearAll: false,
};
