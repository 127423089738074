import {
  dataKeys,
  fieldKeys,
  one,
  pointtwo,
  pointzerofive,
  six,
  ten,
  three,
  two,
} from '../../../constants';
import {
  IColumn,
  IDealDetails,
  IDealSummary,
  IKpiModal,
  TransformedDataEntry,
} from '../../../types/types';
import filterOptions from '../../../constants/filterOptions';
import { BROWNFIELD, GAS_FIRED_GENERATION, GREENFIELD, NATURAL_GAS } from '../../../api/constants';
import {
  getContactInfo,
  getEfficiencyMessage,
  getOperatingModelMessage,
  getPerformanceStandardMessage,
} from '../../KPIContent/setUpMessage';

export const getUniqueTechSource = (dealDetailsData: IDealDetails[]) => {
  return Array.from(
    new Set(
      dealDetailsData
        .map((deal) =>
          deal.TECHNOLOGY_SOURCE_NAME === NATURAL_GAS || deal.TECHNOLOGY_SOURCE_NAME === 'LNG'
            ? GAS_FIRED_GENERATION
            : deal.TECHNOLOGY_SOURCE_NAME,
        )
        .filter((technologyName) => technologyName !== null),
    ),
  );
};

const getSelectedOperatingModel = (OPERATING_MODEL: number) => {
  const { operatingModelOptions } = filterOptions;
  return (
    operatingModelOptions.filter(
      (operatingModel) => operatingModel?.value === Number(OPERATING_MODEL),
    )[0]?.label ?? ''
  );
};
export const getInputFields = (dealDetailsData: IDealDetails[]) => {
  const deal = dealDetailsData[0];
  const {
    OPPORTUNITY_NAME,
    BUSINESS_NAME,
    REGION_NAME,
    COUNTRY_NAME,
    TRADE_COMMODITY_NAME,
    Grid,
    ASSET_TYPE,
    FUEL_TYPE,
    EQUITY_SHARE,
    EFFICIENCY,
    UOM,
    GROSS_MARGIN,
    START_DATE,
    END_DATE,
    TRADE_COMMODITY_ID,
    OPERATING_MODEL,
  } = deal;
  const selectedOperatingModel = getSelectedOperatingModel(Number(OPERATING_MODEL));
  const uniqueTechSource = TRADE_COMMODITY_ID === two ? getUniqueTechSource(dealDetailsData) : [];

  return {
    opportunityName: OPPORTUNITY_NAME ?? '',
    businessName: BUSINESS_NAME ?? '',
    region: REGION_NAME ?? '',
    country: COUNTRY_NAME ?? '',
    commodity: TRADE_COMMODITY_NAME ?? '',
    techSource: uniqueTechSource,
    gridLocation: Grid ?? '',
    assetType: ASSET_TYPE ?? '',
    fuelType: FUEL_TYPE ?? '',
    operatingModel: selectedOperatingModel,
    equityShare: EQUITY_SHARE?.toString() ?? '',
    efficiency: EFFICIENCY?.toString() ?? '',
    unitOfMeasure: UOM ?? 'MWh',
    grossMargin: GROSS_MARGIN ?? 0,
    startDate: START_DATE ?? '',
    endDate: END_DATE ?? '',
    showGasFields: uniqueTechSource.includes(GAS_FIRED_GENERATION),
  };
};
const getTechSourceOrCommodity = (deal: IDealDetails) => {
  const { TRADE_COMMODITY_ID, TRADE_COMMODITY_NAME, TECHNOLOGY_SOURCE_NAME } = deal;
  const techName = TRADE_COMMODITY_ID === one ? TRADE_COMMODITY_NAME : TECHNOLOGY_SOURCE_NAME;
  return techName?.replace('LNG', 'Gas Fired Generation (LNG)');
};
export const getTableData = (
  dealDetailsData: IDealDetails[],
  cols: { accessorKey: string; header: string }[],
) => {
  const techSource = cols[0].accessorKey;
  const transformedData = dealDetailsData.reduce((acc: TransformedDataEntry[], item) => {
    // Check if there's an existing entry for this commodity
    const techSourceName = getTechSourceOrCommodity(item);
    const existingEntryIndex = acc.findIndex((entry) => entry[techSource] === techSourceName);

    if (existingEntryIndex === -1) {
      // If no existing entry found, create a new one
      const newEntry: TransformedDataEntry = { [techSource]: techSourceName as string };
      cols.slice(1).forEach((key) => (newEntry[key['header']] = item['QUANTITY']));
      acc.push(newEntry);
    } else {
      // Find the correct year key and update the quantity
      const existingEntry = acc[existingEntryIndex];
      if (existingEntry) {
        cols.slice(1).forEach((key) => {
          if (String(item['YEAR']) === key['accessorKey']) {
            existingEntry[key['header']] = item['QUANTITY'];
          }
        });
      }
    }
    return acc;
  }, []);
  // Add total volume object
  const totalVolume: TransformedDataEntry = { [techSource]: 'Total Volume' };
  cols.slice(1).forEach((col) => {
    if (col.header !== techSource) {
      totalVolume[col.header] = transformedData.reduce((total, entry) => {
        return total + Number(entry[col.header] ?? 0);
      }, 0);
    }
  });

  // Push total volume object to transformedData
  return [...transformedData, totalVolume];
};

export const getYears = (dealDetailsData: IDealDetails[]) => {
  const uniqueYears = new Set();

  // Iterate over the data and add each year to the Set
  dealDetailsData.forEach((deal: IDealDetails) => {
    uniqueYears.add(deal.YEAR);
  });
  return Array.from(uniqueYears).map((year) => String(year));
};

export const getMaxValue = (dealDetailsData: IDealDetails[]) => {
  if (!dealDetailsData.length) {
    return { maxQuantity: null, maxAge: null };
  }

  let maxEquityScope = {
    value: dealDetailsData[0].EQUITY_SCOPE,
    unit: dealDetailsData[0].EQUITY_SCOPE_UNIT,
  };
  let maxNetAbsoluteEmission = {
    value: dealDetailsData[0].CARBON_EMISSIONS,
    unit: dealDetailsData[0].CARBON_EMISSIONS_UNIT,
  };
  let maxMCR = { value: dealDetailsData[0].MCR, unit: '$/tCO2e' };

  dealDetailsData.slice(1).map((deal) => {
    maxEquityScope = {
      ...maxEquityScope,
      value: Math.max(maxEquityScope.value ?? 0, deal.EQUITY_SCOPE ?? 0),
    };
    maxNetAbsoluteEmission = {
      ...maxNetAbsoluteEmission,
      value: Math.max(maxNetAbsoluteEmission.value ?? 0, deal.CARBON_EMISSIONS ?? 0),
    };
    maxMCR = { ...maxMCR, value: Math.max(maxMCR.value ?? 0, deal.MCR ?? 0) };
  });

  return { maxEquityScope, maxNetAbsoluteEmission, maxMCR };
};

const getSummedUpData = (dealDetailsData: IDealDetails[]) => {
  return dealDetailsData.reduce((accumulator: IDealSummary[], current) => {
    const existingYearIndex = accumulator.findIndex(
      (item: IDealSummary) => item.YEAR === current.YEAR,
    );
    if (existingYearIndex !== -1) {
      accumulator[existingYearIndex].OPERATED_SCOPE += current.OPERATED_SCOPE ?? 0;
      accumulator[existingYearIndex].EQUITY_SCOPE += current.EQUITY_SCOPE ?? 0;
      accumulator[existingYearIndex].CARBON_EMISSIONS += current.CARBON_EMISSIONS ?? 0;
      accumulator[existingYearIndex].CARBON_INTENSITY += current.CARBON_INTENSITY ?? 0;
      accumulator[existingYearIndex].QUANTITY += current.QUANTITY ?? 0;
    } else {
      accumulator.push({
        YEAR: current.YEAR,
        OPERATED_SCOPE: current.OPERATED_SCOPE ?? 0,
        EQUITY_SCOPE: current.EQUITY_SCOPE ?? 0,
        CARBON_EMISSIONS: current.CARBON_EMISSIONS ?? 0,
        CARBON_INTENSITY: current.CARBON_INTENSITY ?? 0,
        QUANTITY: current.QUANTITY ?? 0,
      });
    }
    return accumulator;
  }, []);
};
export const getKpiTableData = (dealDetailsData: IDealDetails[], cols: IColumn[]) => {
  const summedUpData = getSummedUpData(dealDetailsData);

  // Create an array to store the response objects
  const responseData: IKpiModal[] = [];

  // Iterate through keys and dealDetailsData to build the response
  fieldKeys.forEach((key, keyIndex) => {
    const responseObj: IKpiModal = {
      KPI: key,
      Total: '0',
    };

    // Iterate through columns to extract data for each year
    cols.forEach((col) => {
      const year = Number(col.accessorKey);
      const dataForYear: IDealSummary | undefined = summedUpData.find(
        (item: IDealSummary) => item.YEAR === year,
      );
      if (dataForYear) {
        if (key === 'Avg Carbon Intensity**') {
          responseObj[year] = (
            (dataForYear[dataKeys[keyIndex] as keyof IDealSummary] / (dataForYear.QUANTITY ?? 0)) *
            Math.pow(ten, six)
          ).toFixed(three);
          responseObj['Total'] = null;
        } else {
          responseObj[year] = (dataForYear[dataKeys[keyIndex] as keyof IDealSummary] ?? 0).toFixed(
            three,
          );
          responseObj['Total'] = (
            Number(responseObj['Total']) +
            (dataForYear[dataKeys[keyIndex] as keyof IDealSummary] ?? 0)
          ).toFixed(three);
        }
      }
    });

    responseData.push(responseObj);
  });
  return responseData;
};

export const setUpKpiMessage = (
  peakAnnualEquity: number,
  efficiency: number,
  operatingModel: number,
  assetType: string,
) => {
  const isPerfStandardSignificant =
    peakAnnualEquity >= pointzerofive && peakAnnualEquity <= pointtwo;
  const isPerfStandardCritical = peakAnnualEquity > pointtwo;
  const isAssetBrownfield = assetType.toUpperCase() === BROWNFIELD;
  const isAssetGreenfield = assetType.toUpperCase() === GREENFIELD;
  // ORDER of messages
  /*
    - Equity Scope 1&2 Carbon (Peak Annual Emissions) < 0.050 mtCO2e
    - 0.050 mtCO2e > Equity Scope 1&2 Carbon (Peak Annual Emissions) < 0.200 mtCO2e
    - Equity Scope 1&2 Carbon (Peak Annual Emissions) > 0.200 mtCO2e

    - Operating Model in Step-1 = Investment SOV or Investment NOV
    - Operating Model in Step-1 = Lease

    - Performance Standard = Carbon Significant and Asset Type = Brownfield and Efficiency input in Step-1 >= 40%
    - Performance Standard = Carbon Significant and Asset Type = Greenfield and Efficiency input in Step-1 >= 42%
    - Performance Standard = Carbon Critical and Asset Type = Brownfield and Efficiency input in Step-1 >= 58%
    - Performance Standard = Carbon Critical and Asset Type = Greenfield and Efficiency input in Step-1 >= 60%

    - Performance Standard = Carbon Significant
    - Performance Standard = Carbon Critical
  */
  const message1 = getPerformanceStandardMessage(peakAnnualEquity);
  const message2 = getOperatingModelMessage(operatingModel);
  const message3 = getEfficiencyMessage(
    isPerfStandardSignificant,
    isPerfStandardCritical,
    isAssetBrownfield,
    isAssetGreenfield,
    efficiency,
  );
  const message4 =
    isPerfStandardCritical || isPerfStandardSignificant
      ? getContactInfo(isPerfStandardCritical)
      : '';

  return [message1, message2, message3, message4].filter((msg) => msg !== '');
  // setKpiMessage(finalMessage.flat(Infinity) as unknown as string[]);
};
