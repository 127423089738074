import React from 'react';
import { DOWNLOAD_DEALS } from '../../../api/constants';
import { IAuthToken } from '../../../types/types';
import { handleDownloadKpiDeal } from '../../../utils';
import CustomButton from '../../CustomButton/CustomButton';
interface DownloadDealsProps {
  user: IAuthToken;
}
const DownloadDeals: React.FC<DownloadDealsProps> = () => {
  return <CustomButton label={DOWNLOAD_DEALS} apiCall={handleDownloadKpiDeal} />;
};
export default DownloadDeals;
