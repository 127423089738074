import { Grid } from '@sede-x/shell-ds-react-framework'
import styled from 'styled-components'

export const LoginContainer = styled.div`
  position: relative;
  height: 100vh;
`

export const BackgroundVideo = styled.video`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`

export const LoginGrid = styled(Grid)`
  height: 100%;
`

export const Login = styled.div`
  border: 2px solid black;
  border-radius: 20px;
  width: 100%;
  padding: 0px;
  background-color: white;
`

export const GridContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
`
