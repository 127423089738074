import { Button, DeprecatedTable, Modal, Text } from '@sede-x/shell-ds-react-framework';
import { useEffect, useReducer, useContext } from 'react';
import styled from 'styled-components';
import { AMERICAS, NATURAL_GAS, PIPELINE_GAS } from '../../api/constants';
import Step2Image from '../../images/Step2_empty.png';
import Loader from '../Loader';
import '../CalculatorForm/CalculatorForm.css';
import { AppContext } from '../../context/calculatorFormContext';
import { inputTableReducer } from '../../reducers/ActionHandlers/InputTableActionHandler';
import { inputTableInitialState } from '../../reducers/InitialStates/InputTableInitialState';
import {
  decorateTableData,
  tableCols,
  resetTable,
  getOutputKpi,
  bootstrapCol,
} from './InputTableFunctions';

const Container = styled.div`
  overflow: scroll;
`;

const InputTableContent = () => {
  const { state, dispatch } = useContext(AppContext);
  const [myState, myDispatch] = useReducer(inputTableReducer, inputTableInitialState);
  const firstColData: string[] = ['Total Volume'];
  const isAmericasPipelineGas = state.commodity === PIPELINE_GAS && state.region === AMERICAS;
  const isRestOfTheWorldPipelineGas = state.commodity === PIPELINE_GAS && state.region !== AMERICAS;
  const isCommodityPipelineGas = isAmericasPipelineGas || isRestOfTheWorldPipelineGas;
  const isPower = state.commodity.toLowerCase() === 'power';

  const tableHeadingOne = state.stepTwoTableFirstCol;
  const doesTechSrcHasGrid = state.technologySource.includes('Grid');
  const doesTechSrcHasGasFiredPower =
    state.technologySource.includes(NATURAL_GAS) || state.technologySource.includes('LNG');

  useEffect(() => {
    // set all the column headers
    let col: Array<string | number> = [tableHeadingOne];
    col = bootstrapCol(col, state, firstColData, myDispatch);
    decorateTableData(firstColData, dispatch, col);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isGoToStepTwoClicked]);

  return state.isFormValidated ? (
    <>
      <Loader isLoading={myState.isLoading} message={'Fetching Carbon Intensities'} />
      <Modal
        open={state.isGetOutputKpiServiceFailure}
        onClose={() => {
          dispatch({
            type: 'setIsGetOutputKpiServiceFailure',
            payload: false,
          });
        }}
      >
        We ran into issues. Please re-try.
      </Modal>
      <Modal
        open={myState.isPasteDataFailure}
        onClose={() => {
          myDispatch({
            type: 'setIsPasteDataFailure',
            payload: false,
          });
        }}
      >
        Only positive numbers, positive decimals and 0 are valid inputs.
      </Modal>
      <Container>
        <DeprecatedTable
          size='small'
          data={state.stepTwoTableData}
          columns={tableCols(state, dispatch, myState, tableHeadingOne, myDispatch)}
          tableLayout='fixed'
          rowKey={state.stepTwoTableFirstCol}
          wrapperStyle={{
            textAlign: 'left',
          }}
        />
      </Container>
      <div className='align-btns-right-input-table'>
        <Button
          className='btn-size margin-right'
          variant='outlined'
          onClick={() => {
            resetTable(state, dispatch);
          }}
        >
          Reset Table
        </Button>
        <Button
          variant='filled'
          className='btn-size'
          onClick={() => {
            void getOutputKpi(
              doesTechSrcHasGrid,
              doesTechSrcHasGasFiredPower,
              isCommodityPipelineGas,
              isPower,
              state,
              dispatch,
              myDispatch,
            );
          }}
        >
          Get Output KPIs
        </Button>
      </div>
    </>
  ) : (
    <>
      <Text type='span' className='flex-display-start'></Text>
      <div className='margin-top empty-state-image'>
        <img src={Step2Image} width='90' height='90' alt='KPI Table' />
        <p className='empty-state-sub-label'>
          Please enter the fields in Step 01 to generate the input table
        </p>
      </div>
      {/* <DataGrid></DataGrid> */}
    </>
  );
};

export default InputTableContent;
