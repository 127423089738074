import React from 'react';
import { UNIT_FOR_MEASURE_CONVERTER, UNIT_FOR_MEASURE_CONVERTER_LINK } from '../../api/constants';
const CustomLabel = () => {
  return (
    <a href={UNIT_FOR_MEASURE_CONVERTER_LINK} target='_blank' rel='noreferrer'>
      {UNIT_FOR_MEASURE_CONVERTER}
      <svg
        className='uom-icon'
        width='15'
        height='15'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M15.0834 0.500886C15.3135 0.500924 15.5 0.687461 15.5 0.917553V5.91667C15.5 6.14679 15.3135 6.33333 15.0833 6.33333H14.25C14.0199 6.33333 13.8333 6.14679 13.8333 5.91667V3.34518L8.29463 8.88388C8.13191 9.0466 7.86809 9.0466 7.70537 8.88388L7.11612 8.29463C6.9534 8.13191 6.9534 7.86809 7.11612 7.70537L12.6548 2.16667H10.0833C9.85321 2.16667 9.66667 1.98012 9.66667 1.75V0.916735C9.66667 0.68659 9.85326 0.500031 10.0834 0.500069L15.0834 0.500886Z'
          fill='#404040'
        />
        <path
          d='M2.16667 4.66667C2.16667 4.20643 2.53976 3.83333 3 3.83333H8.41667C8.64679 3.83333 8.83333 3.64679 8.83333 3.41667V2.58333C8.83333 2.35322 8.64679 2.16667 8.41667 2.16667H3C1.61929 2.16667 0.5 3.28596 0.5 4.66667V13C0.5 14.3807 1.61929 15.5 3 15.5H11.3333C12.714 15.5 13.8333 14.3807 13.8333 13V7.58333C13.8333 7.35321 13.6468 7.16667 13.4167 7.16667H12.5833C12.3532 7.16667 12.1667 7.35321 12.1667 7.58333V13C12.1667 13.4602 11.7936 13.8333 11.3333 13.8333H3C2.53976 13.8333 2.16667 13.4602 2.16667 13V4.66667Z'
          fill='#404040'
        />
      </svg>
    </a>
  );
};
export default CustomLabel;
