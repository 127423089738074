import styled from 'styled-components';

export const PageContainerComponent = styled.div`
  display: flex;
  justify-content: space-around;
  background-color: ${({ theme }) => theme.background.base};
  min-height: 100vh;
  min-width: 1280px;
  padding: 100px 40px;
  overflow: auto;
`;

export const PageContent = styled.div`
  flex-grow: 1;
  min-width: 880px;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
`;
