import React, { createContext } from 'react';
import { type Action, IFilters } from '../types/types';
import { intitialFiltersState } from '../reducers/InitialStates/FiltersInitialState';

export const FiltersContext = createContext<{
  filters: IFilters;
  dispatch: React.Dispatch<Action>;
}>({
  filters: intitialFiltersState,
  dispatch: () => null,
});
