import { BaseTable, Text } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';
import { IDealDetails, IKpiTableData } from '../../../../types/types';
import { getKpiTableData } from '../DealDetailFunctions';
import { FOOT_NOTE } from '../../../../api/constants';
import { FooterContent } from '../../AllDeals.style';

const ModalKpiTable = (props: { dealDetailsData: IDealDetails[]; years: string[] }) => {
  const { dealDetailsData, years } = props;
  const Container = styled.div`
    overflow: scroll;
    margin-top: 10px;
  `;

  const Cols = ['KPI', ...years, 'Total'].map((val) => {
    return {
      accessorKey: val,
      header: val,
    };
  });
  const data = getKpiTableData(dealDetailsData, Cols) as IKpiTableData[];
  return (
    <Container>
      <BaseTable
        data-testid='kpi_table'
        style={{ width: '100%' }}
        size='small'
        noFooter
        maxHeight={1000}
        columns={Cols}
        data={data}
      />
      <Text size='small'>
        <FooterContent>
          <em>{FOOT_NOTE}</em>
        </FooterContent>
      </Text>
    </Container>
  );
};

export default ModalKpiTable;
