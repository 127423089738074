import React, { useState } from 'react';
import { Button, Modal, Positions, Variants } from '@sede-x/shell-ds-react-framework';
import { DELETE_DEALS, SELECT_DEALS } from '../../../api/constants';
import { TrashAlt } from '@sede-x/shell-ds-react-framework/build/esm/components/Icon/components';
import { IDeals } from '../../../types/types';
import { toast } from 'react-toastify';
import { ModalBodyContent, ModalSubTitle, ModalTitle, ModalWarning } from '../AllDeals.style';
import { DEAL_MODAL_SUBTITLE, DEAL_MODAL_TITLE, DEAL_MODAL_WARNING } from '../../../constants';

interface DeleteDealsProps {
  selectedDeals: IDeals[];
  // eslint-disable-next-line no-unused-vars
  handleDeleteDeals: (selectedDeals: IDeals[]) => Promise<void>;
}
const DeleteDeals: React.FC<DeleteDealsProps> = ({ selectedDeals, handleDeleteDeals }) => {
  const [open, setOpen] = useState(false);
  const handleOnClose = () => setOpen(false);
  const handleDelete = () => {
    if (!selectedDeals.length) {
      toast.info(SELECT_DEALS, {
        position: 'top-right',
        closeOnClick: true,
      });
    } else {
      setOpen(true);
    }
  };
  return (
    <>
      <Button
        icon={<TrashAlt />}
        iconPosition={Positions?.Left}
        size='small'
        variant='outlined'
        onClick={() => handleDelete()}
      >
        {DELETE_DEALS}
      </Button>
      <Modal
        bodyPadding={false}
        open={open}
        onClose={() => {
          handleOnClose();
        }}
        actions={[
          {
            label: 'Cancel',
            action: () => {
              handleOnClose();
            },
            props: {
              variant: Variants.Outlined,
            },
          },
          {
            label: 'Delete',
            action: () => {
              handleDeleteDeals(selectedDeals);
              handleOnClose();
            },
            props: {
              sentiment: 'negative',
            },
          },
        ]}
      >
        <ModalBodyContent>
          <ModalTitle>{DEAL_MODAL_TITLE}</ModalTitle>
          <ModalSubTitle>{DEAL_MODAL_SUBTITLE}</ModalSubTitle>
          <ModalWarning>{DEAL_MODAL_WARNING}</ModalWarning>
        </ModalBodyContent>
      </Modal>
    </>
  );
};
export default DeleteDeals;
