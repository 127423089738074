import React from 'react';
import { Button, Positions } from '@sede-x/shell-ds-react-framework';
import styles from './CustomButton.module.css';
import { DOWNLOAD_DEALS } from '../../api/constants';
import { Download } from '@sede-x/shell-ds-react-framework/build/esm/components/Icon/components';
interface LinkButtonProps {
  label: string;
  link?: string;
  apiCall?: () => void;
}
const CustomButton: React.FC<LinkButtonProps> = ({ label, link, apiCall }) => {
  // checking user for download deal access.

  let variant;
  const buttonIcon = () => {
    if (label === DOWNLOAD_DEALS) {
      variant = 'outlined';
      return <Download />;
    }
    return null;
  };
  const handleClick = () => {
    if (link) {
      window.open(link, '_blank');
    } else if (apiCall) {
      apiCall();
    }
  };
  return (
    <div className={styles['link-btn-div']}>
      {link ? (
        <Button
          id='link-btn'
          variant='outlined'
          className={styles['btn-style']}
          onClick={handleClick}
        >
          {label}
        </Button>
      ) : (
        <Button
          icon={buttonIcon()}
          iconPosition={Positions?.Left}
          size='small'
          variant={variant}
          onClick={handleClick}
        >
          {label}
        </Button>
      )}
    </div>
  );
};
export default CustomButton;
