import { Flexbox } from '@sede-x/shell-ds-react-framework';
import { GAS_FIRED_GENERATION } from '../../../../api/constants';
import { IDealDetails } from '../../../../types/types';
import { getMaxValue, getUniqueTechSource, setUpKpiMessage } from '../DealDetailFunctions';
import SummaryMessage from './SummaryMessage/SummaryMessage';
import '../../AllDeals.css';
import SummaryTable from './SummaryTable/SummaryTable';
import { two } from '../../../../constants';

const KpiSummary = (props: { dealDetailsData: IDealDetails[] }) => {
  const { dealDetailsData } = props;
  const { maxEquityScope, maxNetAbsoluteEmission, maxMCR } = getMaxValue(dealDetailsData);
  const { EFFICIENCY, OPERATING_MODEL, ASSET_TYPE, TRADE_COMMODITY_ID } = dealDetailsData[0];
  const uniqueTechSource = TRADE_COMMODITY_ID === two ? getUniqueTechSource(dealDetailsData) : [];
  const isGasFiredPower = uniqueTechSource.includes(GAS_FIRED_GENERATION);

  const kpiMessage = setUpKpiMessage(
    maxNetAbsoluteEmission?.value ?? 0,
    EFFICIENCY ?? 0,
    Number(OPERATING_MODEL) || 0,
    ASSET_TYPE ?? '',
  );

  return (
    <Flexbox gap='20px'>
      <SummaryTable
        maxEquityScope={maxEquityScope}
        maxNetAbsoluteEmission={maxNetAbsoluteEmission}
        maxMCR={maxMCR}
      />
      {kpiMessage && (
        <SummaryMessage
          kpiMessage={kpiMessage}
          isGasFiredPower={isGasFiredPower}
          operatingModel={Number(OPERATING_MODEL)}
        />
      )}
    </Flexbox>
  );
};

export default KpiSummary;
