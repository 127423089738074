import { BaseTable } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';
import { IDealDetails, TransformedDataEntry } from '../../../../types/types';
import { getTableData } from '../DealDetailFunctions';

const ModalFieldsTable = (props: {
  dealDetailsData: IDealDetails[];
  years: string[];
  commodityId: number;
}) => {
  const { dealDetailsData, years, commodityId } = props;
  const Container = styled.div`
    overflow: scroll;
  `;
  let techSource = 'Tech Source, ';
  if (commodityId === 1) {
    techSource = techSource + 'PJ';
  } else {
    techSource = techSource + 'Mwh';
  }
  const cols = [techSource, ...years].map((val) => {
    return {
      accessorKey: val,
      header: val,
    };
  });

  const data: TransformedDataEntry[] = getTableData(dealDetailsData, cols);
  return (
    <Container>
      <BaseTable
        style={{ width: '100%' }}
        size='small'
        noFooter
        maxHeight={1000}
        columns={cols}
        data={data}
      />
    </Container>
  );
};

export default ModalFieldsTable;
