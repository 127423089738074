/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { Button } from '@sede-x/shell-ds-react-framework';
import { toPng } from 'html-to-image';
import jsPDF from 'jspdf';
import { twenty, onefifty, hundred, thirty, twotwenty } from '../../../constants';
import { ICalculatorFormState } from '../../../types/types';
import moment from 'moment';

const DownloadPdf = (props: {
  state: ICalculatorFormState;
  setIsHideAllButtons: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { state, setIsHideAllButtons } = props;
  const handleDownloadPdf = (target: string) => {
    let domElement: HTMLElement = document.getElementById('my-node')!;
    if (target === 'step3') {
      const elements = document.getElementsByClassName('step3');
      if (elements.length > 0) {
        domElement = elements[0] as HTMLElement;
      }
    }

    let downloadAs = moment().format('DDMMMYYYY_HH-mm-ss');

    // hide the DOWNLOAD buttons
    setIsHideAllButtons(true);

    if (target === 'step3') {
      downloadAs = `${state.opportunity}_KPI_Data_${downloadAs}.pdf`;
      toPng(domElement)
        .then(function (dataUrl: string) {
          const pdf = new jsPDF();
          pdf.addImage(dataUrl, 'PNG', twenty, twenty, onefifty, hundred);
          pdf.save(downloadAs);
          setIsHideAllButtons(false);
        })
        .catch(function () {});
    } else {
      downloadAs = `${state.opportunity}_${downloadAs}.pdf`;
      toPng(domElement)
        .then(function (dataUrl: string) {
          const pdf = new jsPDF();
          pdf.addImage(dataUrl, 'PNG', thirty, twenty, onefifty, twotwenty);
          pdf.save(downloadAs);
          setIsHideAllButtons(false);
        })
        .catch(function () {});
    }
  };
  return (
    <>
      <Button
        variant='outlined'
        hidden={state.isHideAllButtons}
        className='align-btns-right btn-size download-step3'
        onClick={() => {
          handleDownloadPdf('step3');
        }}
      >
        Download Step 03
      </Button>
      <Button
        variant='filled'
        hidden={state.isHideAllButtons}
        className='align-btns-right btn-size'
        onClick={() => {
          handleDownloadPdf('fullPage');
        }}
      >
        Download Deal
      </Button>
    </>
  );
};

export default DownloadPdf;
