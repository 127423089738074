/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable @typescript-eslint/space-before-function-paren */
import { type User, UserManager, WebStorageStateStore } from 'oidc-client';

const postLogoutRedirectUri = process.env.REACT_APP_WEB_URL as string;
const clientId = process.env.REACT_APP_SSO_CLIENT_ID as string;
const authorityUrl = process.env.REACT_APP_SSO_AUTHORITY_URL as string;
export class AuthService {
  public userManager: UserManager;

  constructor() {
    const settings = {
      authority: authorityUrl,
      client_id: clientId,
      redirect_uri: `${postLogoutRedirectUri}signin-callback.html`,
      post_logout_redirect_uri: postLogoutRedirectUri,
      response_type: 'code',
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    };
    this.userManager = new UserManager(settings);
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public login(isTokenExpired = false): Promise<User | void> {
    const extraQueryParams = { kc_idp_hint: 'oidc' };
    if (isTokenExpired) {
      return this.userManager.signinSilent(extraQueryParams);
    }
    return this.userManager.signinRedirect(extraQueryParams);
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }
}

export default AuthService;
