import { Flexbox, Modal, Text } from '@sede-x/shell-ds-react-framework';
import { useEffect, useContext, useReducer, useState } from 'react';
import {
  AVG_CARBON_INT,
  NET_ABSOLUTE_EMISSIONS,
  OPERATED_SCOPE,
  EQUITY_SCOPE,
  PIPELINE_GAS,
  POWER,
  AMERICAS,
  ASIA,
  AUSTRALIA,
  FOOT_NOTE,
  ASSET_SCOPE1_CI,
  NATURAL_GAS,
} from '../../api/constants';
import Step3Image from '../../images/Step3_empty.png';
import '../CalculatorForm/CalculatorForm.css';
import { AppContext } from '../../context/calculatorFormContext';
import { kpiContentReducer } from '../../reducers/ActionHandlers/KpiContentActionHandler';
import { kpiContentInitialState } from '../../reducers/InitialStates/KpiContentInitialState';
import { KpiCardView } from './KpiCardView';
import { KpiMessageView } from './KpiMessageView';
import { KpiTable } from './KpiTable';
import { decorateTableData, decorateCardTableData } from './KpiContentFunctions';
import { IStepTwoTableData } from '../../types/types';

const KPIContent = () => {
  const { state, dispatch } = useContext(AppContext);
  const [kpiMessage, setKpiMessage] = useState<string[]>([]);
  const [myState, myDispatch] = useReducer(kpiContentReducer, kpiContentInitialState);
  // combination of Business and Commodity
  const isAmericasWithCommodityPg = state.commodity === PIPELINE_GAS && state.region === AMERICAS;
  const isAsiaWithCommodityPg = state.commodity === PIPELINE_GAS && state.region === ASIA;
  const isAustraliaWithCommodityPg = state.commodity === PIPELINE_GAS && state.region === AUSTRALIA;
  const isCommodityPW = state.commodity === POWER;
  const isCommodityPg = state.commodity === PIPELINE_GAS;

  const isGasFiredPower =
    state.technologySource.includes(NATURAL_GAS) || state.technologySource.includes('LNG');

  const firstColData: string[] = [
    OPERATED_SCOPE,
    EQUITY_SCOPE,
    NET_ABSOLUTE_EMISSIONS,
    AVG_CARBON_INT,
  ];
  // Adding new field at beginning of the firstColData array, if the selected technology source is 'Gas Fired Generation'
  isGasFiredPower && firstColData.unshift(ASSET_SCOPE1_CI);
  const parameters = {
    isAmericasWithCommodityPg,
    isAsiaWithCommodityPg,
    isAustraliaWithCommodityPg,
    isCommodityPW,
    isCommodityPg,
    isGasFiredPower,
  };
  // set all the column headers
  const cols: Array<string | number> = ['KPI'];
  const stepTwoData: IStepTwoTableData[] = [...state.stepTwoTableData];
  if (stepTwoData.length > 1) {
    // get start and end year from step two table, not from manual entry form
    const stepTwoTableCols = Object.keys(stepTwoData[0]);
    stepTwoTableCols.pop(); // To remove tech source column
    if (state.startYear !== 0 && state.endYear !== 0) {
      for (let i = state.startYear; i <= state.endYear; i++) {
        cols.push(i);
      }
    }
    cols.push('Total');
  }

  useEffect(() => {
    decorateTableData(state, dispatch, myState, myDispatch, setKpiMessage, {
      parameters,
      cols,
      firstColData,
    });
  }, [state.isGetOutputKpi]);

  useEffect(() => {
    decorateCardTableData(myState, myDispatch, dispatch, parameters, state);
  }, [myState.stepThreeTableData]);

  useEffect(() => {
    decorateCardTableData(myState, myDispatch, dispatch, parameters, state);
  }, [state.isGetOutputKpi]);

  const onClose = () => {
    myDispatch({
      type: 'setIsCopiedToClipboard',
      payload: false,
    });
  }

  return !state.isGetOutputKpiServiceFailure && state.isShowKpiData && state.isFormValidated ? (
    <>
      <Modal
        open={myState.isCopiedToClipboard}
        onClose={onClose}
        showFooter={false}
        showHeader={false}
      >
        {(myState.copiedContent as string) + ' is copied to clipboard.'}
      </Modal>
      <Flexbox gap='20px'>
        <KpiCardView cardTableData={myState.cardTableData} />
        <KpiMessageView kpiMessage={kpiMessage} isGasFiredPower={isGasFiredPower} />
      </Flexbox>
      <KpiTable cols={cols} stepThreeTableData={myState.stepThreeTableData} />
      <Text size='small' style={{ color: '#00000', marginTop: '15px' }}>
        <em>{FOOT_NOTE}</em>
      </Text>
    </>
  ) : (
    <>
      <Text type='span' className='kpi-label'></Text>
      <div className='empty-state-image'>
        <img src={Step3Image} width='90' height='90' alt='KPI Table' />
      </div>
      <p className='empty-state-sub-label'>Please complete Step 02 to generate Carbon KPIs</p>
    </>
  );
};
export default KPIContent;
