import { ActionHandlers, IManualFormData, IOptions } from '../../types/types';
import { manualFormInitialState } from '../InitialStates/ManualFormInitialState';
import { createReducer } from '../ReducerFunction';

const actionHandlers: ActionHandlers = {
  setBusinessunitsOptns: (state: IManualFormData, payload: IOptions[]) => ({
    ...state,
    businessunitsOptns: payload,
  }),
  setCommodityOptions: (state: IManualFormData, payload: IOptions[]) => ({
    ...state,
    commodityOptions: payload,
  }),
  setTechSourceOptions: (state: IManualFormData, payload: IOptions[]) => ({
    ...state,
    techSourceOptions: payload,
  }),
  setTechSourceApiResponse: (state: IManualFormData, payload: string[]) => ({
    ...state,
    techSourceApiResponse: payload,
  }),
  setFuelTypeOptions: (state: IManualFormData, payload: IOptions[]) => ({
    ...state,
    fuelTypeOptions: payload,
  }),
  setAssetTypeOptions: (state: IManualFormData, payload: IOptions[]) => ({
    ...state,
    assetTypeOptions: payload,
  }),
  setPowergridOptns: (state: IManualFormData, payload: string[]) => ({
    ...state,
    powergridOptns: payload,
  }),
  setUomVal: (state: IManualFormData, payload: string) => ({
    ...state,
    uomVal: payload,
  }),
  setEfficiencyVal: (state: IManualFormData, payload: string) => ({
    ...state,
    efficiencyVal: payload,
  }),
  setGrossMarginVal: (state: IManualFormData, payload: string) => ({
    ...state,
    grossMarginVal: payload,
  }),
  setEquityShareVal: (state: IManualFormData, payload: string) => ({
    ...state,
    equityShareVal: payload,
  }),
  setCountry: (state: IManualFormData, payload: string) => ({
    ...state,
    country: payload,
  }),
  setStartDate: (state: IManualFormData, payload: string) => ({
    ...state,
    startDate: payload,
  }),
  setEndDate: (state: IManualFormData, payload: string) => ({
    ...state,
    endDate: payload,
  }),
  setTechSource: (state: IManualFormData, payload: string[]) => ({
    ...state,
    techSource: payload,
  }),
  setIsWarningMsgAvailable: (state: IManualFormData, payload: boolean) => ({
    ...state,
    isWarningMsgAvailable: payload,
  }),
  setDisableGrid: (state: IManualFormData, payload: boolean) => ({
    ...state,
    disableGrid: payload,
  }),
  setEnableTechSourceField: (state: IManualFormData, payload: boolean) => ({
    ...state,
    enableTechSourceField: payload,
  }),
  setShowGasFields: (state: IManualFormData, payload: boolean) => ({
    ...state,
    showGasFields: payload,
  }),
  setShowEquityShare: (state: IManualFormData, payload: boolean) => ({
    ...state,
    showEquityShare: payload,
  }),
  setisOpportunityValid: (state: IManualFormData, payload: boolean) => ({
    ...state,
    isOpportunityValid: payload,
  }),
  setisBusinessValid: (state: IManualFormData, payload: boolean) => ({
    ...state,
    isBusinessValid: payload,
  }),
  setisCountryValid: (state: IManualFormData, payload: boolean) => ({
    ...state,
    isCountryValid: payload,
  }),
  setisCommodityValid: (state: IManualFormData, payload: boolean) => ({
    ...state,
    isCommodityValid: payload,
  }),
  setisTechSourceValid: (state: IManualFormData, payload: boolean) => ({
    ...state,
    isTechSourceValid: payload,
  }),
  setisGrossMarginValid: (state: IManualFormData, payload: boolean) => ({
    ...state,
    isGrossMarginValid: payload,
  }),
  setisStartDateValid: (state: IManualFormData, payload: boolean) => ({
    ...state,
    isStartDateValid: payload,
  }),
  setisEndDateValid: (state: IManualFormData, payload: boolean) => ({
    ...state,
    isEndDateValid: payload,
  }),
  setIsFuelTypeValid: (state: IManualFormData, payload: boolean) => ({
    ...state,
    isFuelTypeValid: payload,
  }),
  setIsAssetTypeValid: (state: IManualFormData, payload: boolean) => ({
    ...state,
    isAssetTypeValid: payload,
  }),
  setIsOperatingModelValid: (state: IManualFormData, payload: boolean) => ({
    ...state,
    isOperatingModelValid: payload,
  }),
  setIsEquityPercentageValid: (state: IManualFormData, payload: boolean) => ({
    ...state,
    isEquityPercentageValid: payload,
  }),
  setIsEfficiencyValid: (state: IManualFormData, payload: boolean) => ({
    ...state,
    isEfficiencyValid: payload,
  }),
  setIsLoading: (state: IManualFormData, payload: boolean) => ({
    ...state,
    isLoading: payload,
  }),
  setIsGridLocationValid: (state: IManualFormData, payload: boolean) => ({
    ...state,
    isGridLocationValid: payload,
  }),
};
export const manualFormReducer = createReducer(manualFormInitialState, actionHandlers);
