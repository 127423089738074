import { TextInput } from '@sede-x/shell-ds-react-framework';
import Decimal from 'decimal.js';
import { type ChangeEvent, type Dispatch, type ReactElement } from 'react';
import {
  fetchCarbonIntensityForPipelineGasAsCommodity,
  fetchCarbonIntensityNonGrid,
  fetchCarbonIntensityGrid,
  fetchGasFiredPowerData,
} from '../../api/services';
import { TECHSOURCE_TWH, PIPELINE_GAS, FETCH_ERROR } from '../../api/constants';
import {
  type CiDataInterface,
  type ICIforCommodityPipelineGas,
  type Action,
  type ICalculatorFormState,
  type Idispatch,
  type IArrayOfObject,
  type ICIforGrid,
  InputTableFormState,
  IStepTwoTableData,
  IStepTwoTableColumnObject,
  ITableData,
} from '../../types/types';
import {
  convertCopiedInputToCommaSeparatedWithTwoDecimal,
  convertInputToCommaSeparatedWithTwoDecimal,
} from '../../utils';
import { two } from '../../constants';
import { notify } from '../Toast/notify';

let isDataCopied = false;
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
let ciData: object[];
const carbonIntensities: object[] = [];

export const bootstrapCol = (
  col: Array<string | number>,
  state: ICalculatorFormState,
  firstColData: string[],
  myDispatch: Idispatch,
) => {
  if (state.commodity.toLowerCase() === 'power' && state.technologySource.length > 0) {
    firstColData.unshift(...state.technologySource);
  }
  if (state.commodity.toLowerCase().includes('gas')) {
    firstColData.unshift(PIPELINE_GAS);
  }
  if (state.startYear !== 0 && state.endYear !== 0) {
    for (let i = state.startYear; i <= state.endYear; i++) {
      col.push(i);
    }
  }
  myDispatch({
    type: 'setCols',
    payload: col,
  });
  return col;
};

export const decorateTableData = (
  firstColData: string[],
  dispatch: Idispatch,
  col: Array<string | number>,
) => {
  const data: Array<Record<string, string | number>> = [];
  const firstCol = col[0];
  // setting first key-value pair for each row object
  for (const element of firstColData) {
    data.push({ [firstCol]: element });
  }
  for (const row of data) {
    for (let j = 1; j < col.length; j++) {
      row[col[j]] = 0;
    }
  }
  dispatch({
    type: 'setStepTwoTableData',
    payload: data,
  });
};

export const removeExtraRowFromCopiedContent = (rows: string[][]) => {
  if (rows[rows.length - 1].length === 1 && rows[rows.length - 1][0] === '') {
    rows.pop();
  }
};

const hanldePastingData = (
  val: number,
  index: number,
  rows: string[][],
  state: ICalculatorFormState,
  dispatch: Idispatch,
) => {
  const currTableData: IStepTwoTableData[] = [...state.stepTwoTableData] as IStepTwoTableData[];
  const currColYearBeingChanged: number = val;
  const currRowBeingChanged: number = index;
  // start pasting dynamically from the row-col index user initiates the pasting
  for (let i = 0; i < currTableData.length - 1 - currRowBeingChanged && i < rows.length; i++) {
    for (let j = 0; j < state.endYear - currColYearBeingChanged + 1 && j < rows[i].length; j++) {
      const year = currColYearBeingChanged + j;
      const row = currRowBeingChanged + i;
      (currTableData[row] as unknown as Record<number, string>)[year] =
        convertCopiedInputToCommaSeparatedWithTwoDecimal(rows[i][j]);
    }
  }
  // summing up for Total Volume
  let sum = 0;
  for (let year = state.startYear; year < state.endYear + 1; year++) {
    for (let i = 0; i < currTableData.length - 1; i++) {
      sum =
        sum +
        Number(
          (currTableData[i] as unknown as Record<number, string>)[year]
            .toString()
            .replaceAll(',', ''),
        );
    }
    (currTableData[currTableData.length - 1] as unknown as Record<number, string>)[year] =
      convertCopiedInputToCommaSeparatedWithTwoDecimal(sum.toString());
    sum = 0;
  }
  dispatch({
    type: 'setStepTwoTableData',
    payload: currTableData,
  });
};

const pasteCopiedDataToTable = async (
  val: number,
  index: number,
  state: ICalculatorFormState,
  dispatch: Idispatch,
  myDispatch: Idispatch,
) => {
  // pasteDataFromClipboard(currTableData, val,row);
  // Get the pasted text from the clipboard data
  let pastedText = '';

  pastedText = await navigator.clipboard.readText();

  // Split the pasted text into rows and columns
  const rows = pastedText.split('\r\n').map((row) => row.split('\t'));
  // sometimes pasted text contains an empty row at the end. remove that.
  removeExtraRowFromCopiedContent(rows);
  let hasPastedTextPositiveNumbers = true;

  // check if the values of pastedText has only positive numbers or positive decimal numbers or 0
  rows.some((row) => {
    return row.some((cellData) => {
      if (typeof cellData === 'string' && cellData.includes(',')) {
        cellData = cellData.replaceAll(',', '');
      }
      hasPastedTextPositiveNumbers = /^(\d+(\.\d+)?|\.\d+|0)$/.test(cellData);
      return !hasPastedTextPositiveNumbers;
    });
  });

  if (hasPastedTextPositiveNumbers) {
    hanldePastingData(val, index, rows, state, dispatch);
  } else {
    // throw error that user is trying to paste unacceptable values
    myDispatch({
      type: 'setIsPasteDataFailure',
      payload: true,
    });
  }
  // make the variable false especially for scenarios when user tries to copy and then start typing
  isDataCopied = false;
};

const handleManualValeChangeInTable = (
  e: MouseEvent | ChangeEvent<HTMLInputElement>,
  row: IStepTwoTableData,
  val: number,
  tableHeadingOne: string,
  state: ICalculatorFormState,
  dispatch: Idispatch,
) => {
  const currTableData: ICalculatorFormState[] = [
    ...state.stepTwoTableData,
  ] as unknown as ICalculatorFormState[];
  const inputField = e.target as HTMLInputElement;
  // make the variable false especially for scenarios when user tries to copy and then start typing
  isDataCopied = false;
  // remove 0 when user starts inputting
  const value = inputField.value.startsWith('0')
    ? inputField.value.slice(1, inputField.value.length)
    : inputField.value;
  const tableData: ITableData = {};
  const valueToShowInUI = convertInputToCommaSeparatedWithTwoDecimal(
    value.toString().replaceAll(',', ''),
  );

  for (const [i, rowData] of currTableData.entries()) {
    if (
      rowData[tableHeadingOne as keyof typeof rowData] === row[tableHeadingOne as keyof typeof row]
    ) {
      // Use Record<number, string> to index into the array
      (currTableData[i] as unknown as Record<number, string>)[val] = valueToShowInUI;
      break;
    }
  }

  // summing up
  let sum = new Decimal(0);
  for (let i = 0; i < currTableData.length - 1; i++) {
    sum = sum.plus(
      new Decimal(
        (currTableData[i] as unknown as Record<number, string>)[val].toString().replace(/,/g, ''),
      ),
    );
    for (let j = 0; j < currTableData.length - 1; j++) {
      for (let k = state.startYear; k < state.endYear + 1; k++) {
        tableData[[j].toString() + [k].toString()] = (
          currTableData[j] as unknown as Record<number, string>
        )[k];
      }
    }
  }
  if (currTableData.length > 0) {
    (currTableData[currTableData.length - 1] as unknown as Record<number, string>)[val] =
      convertInputToCommaSeparatedWithTwoDecimal(sum.toString());
  }
  dispatch({
    type: 'setUserTableInputData',
    payload: tableData,
  });
  dispatch({
    type: 'setStepTwoTableData',
    payload: currTableData,
  });
};

const handleStepTwoTableValueChanges = async (
  e: MouseEvent | ChangeEvent<HTMLInputElement>,
  data: {
    val: number;
    index: number;
    row: IStepTwoTableData;
    tableHeadingOne: string;
  },
  state: ICalculatorFormState,
  dispatch: Idispatch,
  myDispatch: Idispatch,
) => {
  const { val, index, row, tableHeadingOne } = data;
  if (isDataCopied) {
    await pasteCopiedDataToTable(val, index, state, dispatch, myDispatch);
  } else {
    handleManualValeChangeInTable(e, row, val, tableHeadingOne, state, dispatch);
  }
};

const handleDataPasteFromExternalFile = () => {
  isDataCopied = true;
};

const updateCIdataForGrid = (response: ICIforGrid[]) => {
  const ciDataObj: CiDataInterface = { [TECHSOURCE_TWH]: '' };
  response.forEach((resp) => {
    const { TECHNOLOGY_SOURCE_NAME, YEAR, CARBON_INTENSITY } = resp;
    if (ciDataObj[TECHSOURCE_TWH] === '') {
      ciDataObj[TECHSOURCE_TWH] = TECHNOLOGY_SOURCE_NAME;
    }
    ciDataObj[YEAR] = CARBON_INTENSITY;
  });
  carbonIntensities.push(ciDataObj);
};

const updateCIdataForNonGrid = (response: IArrayOfObject, state: ICalculatorFormState) => {
  response.forEach((resp) => {
    const { TECHNOLOGY_SOURCE_NAME, CARBON_INTENSITY } = resp;
    const ciDataObj = {
      [TECHSOURCE_TWH]: TECHNOLOGY_SOURCE_NAME,
    };
    for (let year = state.startYear; year < state.endYear + 1; year++) {
      ciDataObj[year as unknown as keyof typeof ciDataObj] = CARBON_INTENSITY;
    }
    carbonIntensities.push(ciDataObj);
  });
};

const updateCIdataForGas = (response: ICIforCommodityPipelineGas[]) => {
  const ciDataObj: CiDataInterface = { [TECHSOURCE_TWH]: PIPELINE_GAS };
  response.forEach((resp) => {
    const { YEAR, CARBON_INTENSITY } = resp;
    ciDataObj[YEAR] = CARBON_INTENSITY;
  });
  carbonIntensities.push(ciDataObj);
};

const updateCIdata = (
  response: ICIforCommodityPipelineGas[] | ICIforGrid[] | IArrayOfObject,
  ciFor: string,
  state: ICalculatorFormState,
) => {
  // Add grid carbon intensities to the carbonIntensities object
  if (ciFor === 'grid') {
    updateCIdataForGrid(response as ICIforGrid[]);
  }
  // Add non-grid carbon intensities to the carbonIntensities object
  if (ciFor === 'nongrid') {
    updateCIdataForNonGrid(response as IArrayOfObject, state);
  }
  if (ciFor === 'isGas') {
    updateCIdataForGas(response as ICIforCommodityPipelineGas[]);
  }
  ciData = [...carbonIntensities];
};

const showLoading = (isLoading: boolean, myDispatch: Dispatch<Action>) => {
  myDispatch({
    type: 'setIsLoading',
    payload: isLoading,
  });
};

const getCIforCommodityPipelineGas = async (
  myDispatch: Dispatch<Action>,
  state: ICalculatorFormState,
  dispatch: Idispatch,
) => {
  let nonGridResp;
  // Api to get CI for natural gas with Americas
  const options = {
    region: state.region,
    startYear: state.startYear,
    endYear: state.endYear,
  };
  showLoading(true, myDispatch);
  try {
    nonGridResp = await fetchCarbonIntensityForPipelineGasAsCommodity(options);
    const responsedata: ICIforCommodityPipelineGas[] = nonGridResp?.data;
    // Carbon intensity value with UOM gCO2e/MJ only should be used for calculating Average carbon intensity.
    const carbonIntForPipelineGasAsCommodity = responsedata?.filter(
      (PipelineGas) => PipelineGas?.UNIT_CODE === 'gCO2e/MJ',
    );
    updateCIdata(carbonIntForPipelineGasAsCommodity, 'isGas', state);
    dispatch({
      type: 'setCarbonIntensityForGas',
      payload: carbonIntForPipelineGasAsCommodity,
    });
    dispatch({
      type: 'setIsShowKpiData',
      payload: true,
    });
  } catch {
    dispatch({
      type: 'setIsGetOutputKpiServiceFailure',
      payload: true,
    });
    dispatch({
      type: 'setIsShowKpiData',
      payload: false,
    });
    notify('error', FETCH_ERROR);
    showLoading(false, myDispatch);
  }
  showLoading(false, myDispatch);
};

const getCIforCommodityPower = async (
  myDispatch: Dispatch<Action>,
  state: ICalculatorFormState,
  doesTechSrcHasGrid: boolean,
  doesTechSrcHasGasFiredPower: boolean,
  dispatch: Idispatch,
) => {
  let nonGridResp;
  if (!doesTechSrcHasGrid && !doesTechSrcHasGasFiredPower) {
    // when technology source list has no grid and no gas fired power
    showLoading(true, myDispatch);
    try {
      nonGridResp = await fetchCarbonIntensityNonGrid(state.technologySource);
      const carbonIntForNonGrid = nonGridResp?.data;
      updateCIdata(carbonIntForNonGrid, 'nongrid', state);
      dispatch({
        type: 'setCarbonIntensityForNonGrid',
        payload: carbonIntForNonGrid,
      });
      dispatch({
        type: 'setIsShowKpiData',
        payload: true,
      });
    } catch {
      dispatch({
        type: 'setIsGetOutputKpiServiceFailure',
        payload: true,
      });
      dispatch({
        type: 'setIsShowKpiData',
        payload: false,
      });
      notify('error', FETCH_ERROR);
      showLoading(false, myDispatch);
    }
    showLoading(false, myDispatch);
  } else {
    // when tech src has combined grid/non-grid/gas fired power
    const techSrc = [...state.technologySource];
    // get the index of GRID out ot tech source list
    const grid = doesTechSrcHasGrid ? techSrc.splice(techSrc.indexOf('Grid'), 1) : [];
    let gridResp;
    let gasFiredPowerResp;
    const optionsForGrid = {
      techSource: grid,
      startYear: state.startYear,
      endYear: state.endYear,
      gridLocation: state.gridLocation,
    };
    showLoading(true, myDispatch);
    try {
      // fetch carbon intensity for Grid
      if (doesTechSrcHasGrid) {
        gridResp = await fetchCarbonIntensityGrid(optionsForGrid);
        const carbonIntForGrid = gridResp?.data;
        updateCIdata(carbonIntForGrid, 'grid', state);
        dispatch({
          type: 'setCarbonIntensityForGrid',
          payload: carbonIntForGrid,
        });
      }

      // fetch CI for gas fired power
      if (doesTechSrcHasGasFiredPower) {
        // remove LNG or Natural Gas from tech source
        updateTechSrcToRemoveGFP(techSrc);
        const options = {
          startYear: state.startYear,
          endYear: state.endYear,
        };
        gasFiredPowerResp = await fetchGasFiredPowerData(options);
        const gasFiredPowerData = gasFiredPowerResp?.data;
        dispatch({
          type: 'setGasFiredPowerTableData',
          payload: gasFiredPowerData,
        });
      }
      // fetch carbon intensity for non-Grid
      if (techSrc.length > 0) {
        // after splicing grid and gas fired power, if techSrc has some value then thats for non-grid
        nonGridResp = await fetchCarbonIntensityNonGrid(techSrc);
        const carbonIntForNonGrid = nonGridResp?.data;
        updateCIdata(carbonIntForNonGrid, 'nongrid', state);
        dispatch({
          type: 'setCarbonIntensityForNonGrid',
          payload: carbonIntForNonGrid,
        });
      }

      dispatch({
        type: 'setIsShowKpiData',
        payload: true,
      });
    } catch {
      dispatch({
        type: 'setIsGetOutputKpiServiceFailure',
        payload: true,
      });
      dispatch({
        type: 'setIsShowKpiData',
        payload: false,
      });
      notify('error', FETCH_ERROR);
      showLoading(false, myDispatch);
    }
    showLoading(false, myDispatch);
  }
};

const updateTechSrcToRemoveGFP = (techSrc: string[]) => {
  if (techSrc.includes('LNG') || techSrc.includes(PIPELINE_GAS)) {
    // Remove 'LNG' and 'Natural Gas' from techSrc
    const indexLNG = techSrc.indexOf('LNG');
    const indexPipelineGas = techSrc.indexOf('Natural Gas');
    if (indexLNG !== -1) {
      techSrc.splice(indexLNG, 1);
    }
    if (indexPipelineGas !== -1) {
      techSrc.splice(indexPipelineGas, 1);
    }
  }
};

export const getOutputKpi = async (
  doesTechSrcHasGrid: boolean,
  doesTechSrcHasGasFiredPower: boolean,
  isCommodityPipelineGas: boolean,
  isPower: boolean,
  state: ICalculatorFormState,
  dispatch: Idispatch,
  myDispatch: Dispatch<Action>,
) => {
  if (isCommodityPipelineGas) {
    await getCIforCommodityPipelineGas(myDispatch, state, dispatch);
  } else if (isPower && state.technologySource.length > 0) {
    await getCIforCommodityPower(
      myDispatch,
      state,
      doesTechSrcHasGrid,
      doesTechSrcHasGasFiredPower,
      dispatch,
    );
  }
  dispatch({
    type: 'setIsGetOutputKpi',
    payload: !state.isGetOutputKpi,
  });
  dispatch({
    type: 'setActiveKey',
    payload: [0, 1, two],
  });
};

export const resetTable = (state: ICalculatorFormState, dispatch: Idispatch) => {
  const currTableData: IStepTwoTableData[] = [...state.stepTwoTableData] as IStepTwoTableData[];
  for (const rowData of currTableData) {
    for (let year = state.startYear; year < state.endYear + 1; year++) {
      rowData[year] = 0;
    }
  }
  dispatch({
    type: 'setStepTwoTableData',
    payload: currTableData,
  });
};

const getFirstColumnData = (commodity: string, value: string) => {
  const commodityMap: Record<string, Record<string, string>> = {
    power: {
      PIPELINE_GAS: 'Gas Fired Generation (NG)',
      LNG: 'Gas Fired Generation (LNG)',
    },
  };
  const commodityLower = commodity.toLowerCase();
  const mappedValue = commodityMap[commodityLower]?.[value];
  return <>{mappedValue || value}</>;
};

export const getColumnData = (
  data: {
    row: IStepTwoTableData;
    tableHeadingOne: string;
    value: string | number;
    val: string | number;
    index: number;
  },
  state: ICalculatorFormState,
  dispatch: Idispatch,
  myDispatch: Idispatch,
) => {
  const { row, tableHeadingOne, value, val, index } = data;
  return row[tableHeadingOne as keyof typeof row] === 'Total Volume' ? (
    <>{value}</>
  ) : (
    <TextInput
      value={value}
      type='text'
      onChange={(e) => {
        const params = { val: Number(val), index, row, tableHeadingOne };
        void handleStepTwoTableValueChanges(e, params, state, dispatch, myDispatch);
      }}
      onPaste={() => {
        handleDataPasteFromExternalFile();
      }}
    />
  );
};

export const tableCols = (
  state: ICalculatorFormState,
  dispatch: Dispatch<Action>,
  myState: InputTableFormState,
  tableHeadingOne: string,
  myDispatch: Dispatch<Action>,
) => {
  const colObj: IStepTwoTableColumnObject[] = [];
  if (myState.cols.length > 0) {
    myState.cols.forEach((val: string | number) => {
      if (colObj.length === 0) {
        colObj.push({
          dataIndex: val,
          key: val,
          width: 150,
          title: val,
          className: 'leftAlign',
          render: (value: string): ReactElement => getFirstColumnData(state.commodity, value),
        });
      } else {
        colObj.push({
          dataIndex: val,
          key: val,
          title: val,
          width: 150,
          className: 'leftAlign',
          render: (value: string | number, row: IStepTwoTableData, index: number): ReactElement =>
            getColumnData({ row, tableHeadingOne, value, val, index }, state, dispatch, myDispatch),
        });
      }
    });
  }
  return colObj;
};
