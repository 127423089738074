import {
  BIOMASS,
  COAL,
  GAS_FIRED_GENERATION,
  GEOTHERMAL,
  GRID,
  HYDROPOWER,
  OIL,
  SOLAR,
  WIND,
} from '../api/constants';
const UK = 'United Kingdom';
const USA = 'United States of America';
export default {
  regionOptions: [
    { value: 'Europe', label: 'Europe' },
    { value: 'Americas', label: 'Americas' },
    { value: 'Asia', label: 'Asia' },
    { value: 'Australia', label: 'Australia' },
  ],
  countryOptions: [
    { value: 'Belgium', label: 'Belgium', region: 'Europe' },
    { value: 'France', label: 'France', region: 'Europe' },
    { value: 'Germany', label: 'Germany', region: 'Europe' },
    { value: 'Italy', label: 'Italy', region: 'Europe' },
    { value: 'Netherlands', label: 'Netherlands', region: 'Europe' },
    { value: 'Spain', label: 'Spain', region: 'Europe' },
    { value: 'Switzerland', label: 'Switzerland', region: 'Europe' },
    { value: 'Turkey', label: 'Turkey', region: 'Europe' },
    {
      value: UK,
      label: UK,
      region: 'Europe',
    },
    { value: 'Japan', label: 'Japan', region: 'Asia' },
    { value: 'Other', label: 'Other', region: 'Asia' },
    { value: 'Brazil', label: 'Brazil', region: 'Americas' },
    {
      value: USA,
      label: USA,
      region: 'Americas',
    },
    { value: 'Mexico', label: 'Mexico', region: 'Americas' },
    { value: 'Canada', label: 'Canada', region: 'Americas' },
    { value: 'Other', label: 'Other', region: 'Australia' },
    { value: 'All', label: 'All', region: 'All' },
  ],
  commodityOptions: [
    { value: 1, label: 'Natural Gas' },
    { value: 2, label: 'Power' },
  ],
  techSourceOptions: [
    { value: WIND, label: WIND },
    { value: SOLAR, label: SOLAR },
    { value: HYDROPOWER, label: HYDROPOWER },
    { value: BIOMASS, label: BIOMASS },
    { value: GEOTHERMAL, label: GEOTHERMAL },
    { value: OIL, label: OIL },
    { value: COAL, label: COAL },
    { value: GRID, label: GRID },
    { value: GAS_FIRED_GENERATION, label: GAS_FIRED_GENERATION },
  ],
  possibleCountryOptions: {
    Africa: ['Other'],
    Americas: ['Brazil', 'Canada', 'Mexico', 'United States of America'],
    Asia: ['Japan', 'Other'],
    Australia: ['Australia'],
    Europe: [
      'Belgium',
      'France',
      'Germany',
      'Italy',
      'Netherlands',
      'Spain',
      'Switzerland',
      'Turkey',
      'United Kingdom',
    ],
  },
  gridLocationOptions: [
    {
      value: 'Belgium',
      label: 'Belgium',
      region: 'Europe',
      country: 'Belgium',
    },
    {
      value: 'France',
      label: 'France',
      region: 'Europe',
      country: 'France',
    },
    {
      value: 'Germany',
      label: 'Germany',
      region: 'Europe',
      country: 'Germany',
    },
    {
      value: 'Italy',
      label: 'Italy',
      region: 'Europe',
      country: 'Italy',
    },
    {
      value: 'Netherlands',
      label: 'Netherlands',
      region: 'Europe',
      country: 'Netherlands',
    },
    {
      value: 'Spain',
      label: 'Spain',
      region: 'Europe',
      country: 'Spain',
    },
    {
      value: 'Switzerland',
      label: 'Switzerland',
      region: 'Europe',
      country: 'Switzerland',
    },
    {
      value: 'Turkey',
      label: 'Turkey',
      region: 'Europe',
      country: 'Turkey',
    },
    {
      value: UK,
      label: UK,
      region: 'Europe',
      country: UK,
    },
    {
      value: 'Japan',
      label: 'Japan',
      region: 'Asia',
      country: 'Japan',
    },
    {
      value: 'Other',
      label: 'Other',
      region: 'Asia',
      country: 'Other',
    },
    {
      value: 'Brazil',
      label: 'Brazil',
      region: 'Americas',
      country: 'Brazil',
    },
    {
      value: 'ASCC',
      label: 'ASCC',
      region: 'Americas',
      country: USA,
    },
    {
      value: 'CA',
      label: 'CA',
      region: 'Americas',
      country: USA,
    },
    {
      value: 'FRCC',
      label: 'FRCC',
      region: 'Americas',
      country: USA,
    },
    {
      value: 'HICC',
      label: 'HICC',
      region: 'Americas',
      country: USA,
    },
    {
      value: 'MRO',
      label: 'MRO',
      region: 'Americas',
      country: USA,
    },
    {
      value: 'NPCC',
      label: 'NPCC',
      region: 'Americas',
      country: USA,
    },
    {
      value: 'PJM (former RFC)',
      label: 'PJM (former RFC)',
      region: 'Americas',
      country: USA,
    },
    {
      value: 'SERC',
      label: 'SERC',
      region: 'Americas',
      country: USA,
    },
    {
      value: 'SPP',
      label: 'SPP',
      region: 'Americas',
      country: USA,
    },
    {
      value: 'TRE',
      label: 'TRE',
      region: 'Americas',
      country: USA,
    },
    {
      value: 'WECC',
      label: 'WECC',
      region: 'Americas',
      country: USA,
    },
    {
      value: 'Mexico',
      label: 'Mexico',
      region: 'Americas',
      country: 'Mexico',
    },
    {
      value: 'Canada',
      label: 'Canada',
      region: 'Americas',
      country: 'Canada',
    },
    {
      value: 'Other',
      label: 'Other',
      region: 'Australia',
      country: 'Other',
    },
  ],
  operatingModelOptions: [
    { value: 1, label: 'Investment - SOV' },
    { value: 2, label: 'Investment - NOV' },
    { value: 3, label: 'Toll/PPA' },
    { value: 4, label: 'Lease' },
  ],
  unitofmeasuresOptns: [
    { value: 'MWH', label: 'MWH' },
    { value: 'MMBTU', label: 'MMBTU' },
    { value: 'MWH', label: 'MWH' },
  ],
  fuelTypeOptions: [
    { value: 1, label: 'Natural Gas' },
    { value: 2, label: 'LNG' },
  ],
};
