import { ActionHandlers, IFilters } from '../../types/types';
import { intitialFiltersState } from '../InitialStates/FiltersInitialState';
import { createReducer } from '../ReducerFunction';

const actionHandlers: ActionHandlers = {
  setOpportunity: (state: IFilters, payload: string[]) => ({
    ...state,
    opportunity: payload,
    isClearAll: false,
  }),
  setCommodity: (state: IFilters, payload: string) => ({
    ...state,
    commodity: payload,
    isClearAll: false,
  }),
  setCountry: (state: IFilters, payload: string) => ({
    ...state,
    country: payload,
    isClearAll: false,
  }),
  setStartDate: (state: IFilters, payload: string) => ({
    ...state,
    startDate: payload,
    isClearAll: false,
  }),
  setEndDate: (state: IFilters, payload: string) => ({
    ...state,
    endDate: payload,
    isClearAll: false,
  }),
  setIsEquityScope: (state: IFilters, payload: boolean) => ({
    ...state,
    isEquityScope: payload,
    isClearAll: false,
  }),
  setIsApplyFilterClicked: (state: IFilters, payload: boolean) => ({
    ...state,
    isApplyFilterClicked: payload,
    isClearAll: false,
  }),
  setClearAll: (_state: IFilters, payload: boolean) => ({
    ...intitialFiltersState,
    isClearAll: payload,
  }),
};
export const filtersReducer = createReducer(intitialFiltersState, actionHandlers);
