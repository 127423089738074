import {
  Grid,
  FormField,
  TextInput,
  Flexbox,
  SingleDatePicker,
  type FormLabelIconPosition,
} from '@sede-x/shell-ds-react-framework';
import filterOptions from '../../../constants/filterOptions';
import { useContext, useState } from 'react';
import { AppContext } from '../../../context/calculatorFormContext';
import {
  onOpportunityChange,
  handleBusinessChange,
  handleCountyChange,
  handleCommodityChange,
  handleTechSourceChange,
  handleGridLocationChange,
  handleAssetTypeChange,
  handleFuelTypeChange,
  handleOperatingModelChange,
  onEquityPercentageChange,
  maxLengthCheck,
  onEfficiencyPercentageChange,
  onGrossMarginChange,
  handleStartDateChange,
  disabledPrevDatesForEndDate,
  handleEndDateChange,
} from './KpiFormFunctions';
import { ReusableSelect } from './ReusableSelet';
import {
  type IDispatch,
  TChangeEvent,
  StringDispatch,
  IManualFormData,
  eventType,
  DefaultOptionType,
} from '../../../types/types';

export const KpiForm = (props: {
  setBusiness: StringDispatch;
  setCommodity: StringDispatch;
  setCountry: StringDispatch;
  myState: IManualFormData;
  myDispatch: IDispatch;
  business: string;
  country: string;
}) => {
  const { state, dispatch } = useContext(AppContext);
  const { setBusiness, setCommodity, setCountry, myState, myDispatch, business, country } = props;
  const [isFetchingGridLocation, setIsFetchingGridLocation] = useState(false);
  const [isFetchingCountry, setIsFetchingCountry] = useState(false);
  const [gridLocationOptions, setGridLocationOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const [countryOptions, setCountryOptions] = useState<Array<{ value: string; label: string }>>([
    { value: '', label: '' },
  ]);

  const { regionOptions } = filterOptions;
  const { operatingModelOptions } = filterOptions;
  const { unitofmeasuresOptns } = filterOptions;

  const selectConfig = {
    business: {
      id: 'business',
      label: 'Business',
      mandatory: true,
      value: state.business,
      onChange: (_e: eventType, option: DefaultOptionType) => {
        void handleBusinessChange(_e, { myState, option, state, country }, dispatch, myDispatch, {
          setBusiness,
          setGridLocationOptions,
          setIsFetchingCountry,
          setCountryOptions,
          setCountry,
          setIsFetchingGridLocation,
        });
      },
      optionLabelProp: 'label',
      invalid: !myState.isBusinessValid,
      ariaLabel: 'Select',
      allowClear: false,
      options: myState.businessunitsOptns,
    },
    region: {
      id: 'region',
      label: 'Region',
      mandatory: true,
      value: state.region,
      disabled: true,
      options: regionOptions,
    },
    country: {
      id: 'country',
      label: 'Country',
      mandatory: true,
      size: 'medium',
      allowClear: false,
      value: country,
      loading: isFetchingCountry,
      onChange: (_e: eventType, option: DefaultOptionType) => {
        handleCountyChange(_e, option, country, {
          dispatch,
          myDispatch,
          setCountry,
          myState,
          setIsFetchingGridLocation,
          state,
          setGridLocationOptions,
        });
      },
      invalid: !myState.isCountryValid,
      disabled: countryOptions.length === 1,
      options: countryOptions,
    },
    commodity: {
      id: 'commodity',
      label: 'Commodity',
      mandatory: true,
      value: state.commodity,
      onChange: (_e: eventType, option: DefaultOptionType) => {
        handleCommodityChange(_e, option, dispatch, myDispatch, business, myState, setCommodity);
      },
      optionLabelProp: 'label',
      invalid: !myState.isCommodityValid,
      allowClear: false,
      options: myState.commodityOptions,
    },
    techSource: {
      id: 'techSource',
      label: 'Technology Source',
      mandatory: true,
      value: myState.techSource,
      onChange: (e: eventType, _option: DefaultOptionType) => {
        handleTechSourceChange(e, _option, gridLocationOptions, {
          dispatch,
          myDispatch,
          state,
          country,
          myState,
          setIsFetchingGridLocation,
          setGridLocationOptions,
        });
      },
      optionLabelProp: 'label',
      disabled: !myState.enableTechSourceField,
      invalid: !myState.isTechSourceValid,
      allowClear: false,
      options: myState.techSourceOptions,
      mode: 'multiple',
    },
    gridLocation: {
      id: 'gridLocation',
      label: 'Grid Location',
      mandatory: true,
      value: state.gridLocation,
      onChange: (e: eventType, option: DefaultOptionType) => {
        handleGridLocationChange(e, option, dispatch, myDispatch);
      },
      loading: isFetchingGridLocation,
      disabled: myState.disableGrid,
      optionLabelProp: 'label',
      invalid: !myState.isGridLocationValid,
      allowClear: false,
      options: gridLocationOptions,
    },
    assetType: {
      id: 'assetType',
      label: 'Asset Type',
      mandatory: true,
      value: state.assetType,
      onChange: (e: eventType) => {
        handleAssetTypeChange(e, dispatch, myDispatch);
      },
      optionLabelProp: 'label',
      invalid: !myState.isAssetTypeValid,
      allowClear: false,
      options: myState.assetTypeOptions,
    },
    fuelType: {
      id: 'fuelType',
      label: 'Fuel Type',
      mandatory: true,
      value: state.fuelType,
      onChange: (e: eventType) => {
        handleFuelTypeChange(e, dispatch, myDispatch, state, myState);
      },
      optionLabelProp: 'label',
      invalid: !myState.isFuelTypeValid,
      allowClear: false,
      options: myState.fuelTypeOptions,
    },
    operatingModel: {
      id: 'operatingModel',
      label: 'Operating Model',
      mandatory: true,
      onChange: (_e: eventType, options: DefaultOptionType) => {
        handleOperatingModelChange(_e, options, dispatch, myDispatch);
      },
      optionLabelProp: 'label',
      invalid: !myState.isOperatingModelValid,
      allowClear: false,
      options: operatingModelOptions,
    },
    unitOfMeasure: {
      id: 'unitOfMeasure',
      label: 'Unit of Measure',
      mandatory: true,
      value: myState.uomVal,
      optionLabelProp: 'label',
      allowClear: false,
      options: unitofmeasuresOptns,
      iconPosition: 'right' as FormLabelIconPosition,
      disabled: true,
    },
  };

  return (
    <>
      <Grid columns={1}>
        <FormField id='opportunityName' label='Opportunity Name' mandatory={true}>
          <TextInput
            aria-label='Opportunity Name'
            onChange={(e: TChangeEvent) => {
              onOpportunityChange(e, dispatch, myDispatch);
            }}
            value={state.opportunity}
            maxLength={100}
            invalid={!myState.isOpportunityValid}
          />
        </FormField>
      </Grid>
      <Grid columns={3}>
        <ReusableSelect {...selectConfig.business} />
        <ReusableSelect {...selectConfig.region} />
        <ReusableSelect {...selectConfig.country} />
      </Grid>
      <Grid columns={3}>
        <ReusableSelect {...selectConfig.commodity} />
        <ReusableSelect {...selectConfig.techSource} />
        <ReusableSelect {...selectConfig.gridLocation} />
      </Grid>
      {myState.showGasFields ? (
        <Grid columns={4}>
          <ReusableSelect {...selectConfig.assetType} />
          <ReusableSelect {...selectConfig.fuelType} />
          <ReusableSelect {...selectConfig.operatingModel} />
          <Flexbox columnGap='12px'>
            <FormField id='equityPercentage' label='Equity Share (%)' mandatory={true}>
              <TextInput
                aria-label='Equity Share'
                onChange={(e: TChangeEvent) => {
                  onEquityPercentageChange(e, dispatch, myDispatch);
                }}
                value={myState.equityShareVal}
                disabled={!myState.showEquityShare}
                type='number'
                onInput={() => maxLengthCheck}
                maxLength={2}
                invalid={!myState.isEquityPercentageValid}
              />
            </FormField>
            <FormField id='efficiencyPercentage' label='Efficiency (LHV%)' mandatory={true}>
              <TextInput
                aria-label='Efficiency'
                type='number'
                onChange={(e: TChangeEvent) => {
                  onEfficiencyPercentageChange(e, dispatch, myDispatch);
                }}
                value={myState.efficiencyVal}
                invalid={!myState.isEfficiencyValid}
              />
            </FormField>
          </Flexbox>
        </Grid>
      ) : null}
      <Grid columns={4}>
        <ReusableSelect {...selectConfig.unitOfMeasure} />
        <FormField id='grossMargin' label='Gross Margin (Million USD)' mandatory={true}>
          <TextInput
            aria-label='Gross Margin'
            type='text'
            onChange={(e: TChangeEvent) => {
              onGrossMarginChange(e, dispatch, myDispatch);
            }}
            value={myState.grossMarginVal}
            invalid={!myState.isGrossMarginValid}
          />
        </FormField>
        <FormField id='startDate' label='Start Date' mandatory={true}>
          <SingleDatePicker
            // format='MM/DD/YYYY'
            aria-label='Pick date for the story StartDate'
            placeholder='DD MMM YYYY'
            onChange={(e) => {
              handleStartDateChange(e, dispatch, myDispatch);
            }}
            invalid={!myState.isStartDateValid}
            elevation={true}
          />
        </FormField>
        <FormField id='endDate' label='End Date' mandatory={true}>
          <SingleDatePicker
            // format='MM/DD/YYYY'
            aria-label='Pick date for the story EndDate'
            placeholder='DD MMM YYYY'
            disabledDate={(current) => disabledPrevDatesForEndDate(current, myState)}
            onChange={(e) => {
              handleEndDateChange(e, dispatch, myDispatch);
            }}
            invalid={!myState.isEndDateValid}
            elevation={true}
          />
        </FormField>
      </Grid>
    </>
  );
};
