import { Copy } from '@sede-x/shell-ds-react-framework/build/esm/components/Icon/components';
import { IKpiContentData } from '../../types/types';

export const kpiContentInitialState: IKpiContentData = {
  stepThreeTableData: [
    {
      '2024': '',
      'Tech Source': '',
      MWh: '',
    },
  ],
  cardTableData: [
    {
      KPIs: 'Equity Scope 1&2 Carbon (Peak annual emissions)',
      Value: '',
      Unit: <Copy />,
    },
    {
      KPIs: 'Net Absolute Emissions Scope 1,2 & 3 (Peak annual emissions)',
      Value: '',
      Unit: <Copy />,
    },
    {
      KPIs: 'Margin to Carbon Ratio (Average)',
      Value: '',
      Unit: <Copy />,
    },
  ],
  equityScope12PeakEmission: '',
  netAbsEmsPeak: '',
  mcrAvg: '',
  isCopiedToClipboard: false,
  copiedContent: '',
  netAbsEmsDataYearWise: {},
  equityScopeDataYearWise: {},
};
