import { Flexbox, FormField, Grid, TextInput } from '@sede-x/shell-ds-react-framework';
import { IDealDetails } from '../../../../types/types';
import { getInputFields } from '../DealDetailFunctions';

const InputForm = (props: { selectConfig: IDealDetails[] }) => {
  const { selectConfig } = props;
  const inputFields = getInputFields(selectConfig);
  return (
    <div className='non-edit-form'>
      <Grid columns={1}>
        <FormField id='opportunityName' label='Opportunity Name'>
          <TextInput disabled value={inputFields?.opportunityName} />
        </FormField>
      </Grid>
      <Grid columns={3}>
        <FormField id='business' label='Business'>
          <TextInput disabled value={inputFields.businessName} />
        </FormField>
        <FormField id='region' label='Region'>
          <TextInput disabled value={inputFields.region} />
        </FormField>
        <FormField id='country' label='Country'>
          <TextInput disabled value={inputFields.country} />
        </FormField>
      </Grid>
      <Grid columns={3}>
        <FormField id='commodity' label='Commodity'>
          <TextInput disabled value={inputFields.commodity} />
        </FormField>
        <FormField id='techSource' label='Technology Source'>
          <TextInput disabled value={inputFields.techSource.toString()} />
        </FormField>
        <FormField id='gridLocation' label='Grid Location'>
          <TextInput disabled value={inputFields.gridLocation} />
        </FormField>
      </Grid>
      {inputFields.showGasFields ? (
        <Grid columns={4}>
          <FormField id='assetType' label='Asset Type'>
            <TextInput disabled value={inputFields.assetType} />
          </FormField>
          <FormField id='fuelType' label='Fuel Type'>
            <TextInput disabled value={inputFields.fuelType} />
          </FormField>
          <FormField id='operatingModel' label='Operating Model'>
            <TextInput disabled value={inputFields.operatingModel} />
          </FormField>
          <Flexbox columnGap='12px'>
            <FormField id='equityPercentage' label='Equity Share (%)'>
              <TextInput disabled value={inputFields.equityShare} />
            </FormField>
            <FormField id='efficiencyPercentage' label='Efficiency (LHV%)'>
              <TextInput disabled value={inputFields.efficiency} />
            </FormField>
          </Flexbox>
        </Grid>
      ) : null}
      <Grid columns={4}>
        <FormField id='unitOfMeasure' label='Unit Of Measure'>
          <TextInput disabled value={inputFields.unitOfMeasure} />
        </FormField>
        <FormField id='grossMargin' label='Gross Margin (Million USD)'>
          <TextInput disabled value={inputFields.grossMargin} />
        </FormField>
        <FormField id='startDate' label='Start Date'>
          <TextInput disabled value={inputFields.startDate} />
        </FormField>
        <FormField id='endDate' label='End Date'>
          <TextInput disabled value={inputFields.endDate} />
        </FormField>
      </Grid>
    </div>
  );
};

export default InputForm;
