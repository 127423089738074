import { FUEL_CO2E, GVCET, LNG_NCF_CI, GAS_END_USE_CF_CI, NATURAL_GAS } from '../../api/constants';
import { threepointfourone, thousand } from '../../constants';
import { Idispatch, IRecord, ICalculatorFormState, IStepTwoTableData } from '../../types/types';

const getVolumeByUser = (year: number, stepTwoData: IStepTwoTableData[]) => {
  const relevantRow = stepTwoData.find((row) => {
    const rowValues = Object.values(row);
    return rowValues.includes(NATURAL_GAS) || rowValues.includes('LNG');
  });

  if (relevantRow) {
    const yearData = relevantRow[year as unknown as keyof typeof relevantRow];
    return Number(yearData?.toString().replaceAll(',', '')) || 0; // If the value is null or undefined, it defaults to 0.
  }

  return 0;
};

const calculateParametersForGasFiredPower = (currentYear: number, state: ICalculatorFormState) => {
  const gasFiredPowerData = [...state.gasFiredPowerTableData];
  const parameters = {
    fuelCO2eCIforCurrYear: 0,
    gvcetForCurrYear: 0,
    lngNcfCIforCurrYear: 0,
    gasEndUsencfCIforCurrYear: 0,
    timeStampForCurrYear: '',
  };
  // get all the entries where the year matches with current year
  const entry = gasFiredPowerData.filter((data) => data.YEAR === currentYear);
  // iterate year wise and find matching parameters
  entry.forEach((item) => {
    if (item.PARAMETER === FUEL_CO2E) {
      parameters.fuelCO2eCIforCurrYear = item.CARBON_INTENSITY;
      parameters.timeStampForCurrYear = item.LAST_UPDATE_TIMESTAMP;
    }
    if (item.PARAMETER === GVCET) {
      parameters.gvcetForCurrYear = item.CARBON_INTENSITY;
      parameters.timeStampForCurrYear = item.LAST_UPDATE_TIMESTAMP;
    }
    if (item.PARAMETER === LNG_NCF_CI) {
      parameters.lngNcfCIforCurrYear = item.CARBON_INTENSITY;
      parameters.timeStampForCurrYear = item.LAST_UPDATE_TIMESTAMP;
    }
    if (item.PARAMETER === GAS_END_USE_CF_CI) {
      parameters.gasEndUsencfCIforCurrYear = item.CARBON_INTENSITY;
      parameters.timeStampForCurrYear = item.LAST_UPDATE_TIMESTAMP;
    }
  });
  return parameters;
};

export const calculateCIforGFPfuelTypeNG = (
  state: ICalculatorFormState,
  dispatch: Idispatch,
  stepTwoData: IStepTwoTableData[],
) => {
  const efficencyByUser = Number(state.efficiency);
  const equitySharePercentageByUser = Number(state.equityShare);
  const isOperatingModelSOV = Number(state.operatingModel) === 1;
  type gasFiredObject = IRecord;
  const gasFiredPowerParameters: {
    assetScope1: gasFiredObject;
    pvce: gasFiredObject;
    operatedScope12: gasFiredObject;
    equityScope12: gasFiredObject;
    nae: gasFiredObject;
    ciDataForGasFiredPower: object[];
  } = {
    assetScope1: {},
    pvce: {},
    operatedScope12: {},
    equityScope12: {},
    nae: {},
    ciDataForGasFiredPower: [{}],
  };
  const ciDataForGasFiredPowerYearWise: IRecord = {};
  const timeStampForGasFiredPowerYearWise: Record<number, string> = {};

  for (let year = state.startYear; year < state.endYear + 1; year++) {
    const currentYear = year;
    const { fuelCO2eCIforCurrYear, gvcetForCurrYear, timeStampForCurrYear } =
      calculateParametersForGasFiredPower(currentYear, state);
    const volumeByUserForCurrYear = getVolumeByUser(currentYear, stepTwoData);
    // calculate Asset Scope 1 CI
    // (Fuel CO2e per year * UOM conversion)/E -> Fuled CO2e from table data in gCO2e/KWh
    // UOM conversion = 3.41 * 10^3
    const uomForAssecScopeCalc = threepointfourone * thousand;
    const assetScope1ForCurrYear =
      efficencyByUser === 0 ? 0 : (fuelCO2eCIforCurrYear * uomForAssecScopeCalc) / efficencyByUser;
    gasFiredPowerParameters.assetScope1[currentYear] = assetScope1ForCurrYear;

    // Calculate Project Value Chain CI Electrical (PVCE)
    // Asset Scope 1 CI + (GVCET for the year in gCO2e per Mj/E)*(UOM) in gCO2e/KWh -> GVCET from table data
    // UOM conversion = 3.6
    const uomForPVCEcalc = 3.6;
    const pvceForCurrYear =
      efficencyByUser === 0
        ? 0
        : assetScope1ForCurrYear + (gvcetForCurrYear / efficencyByUser) * uomForPVCEcalc;

    gasFiredPowerParameters.pvce[currentYear] = pvceForCurrYear;
    ciDataForGasFiredPowerYearWise[currentYear] = pvceForCurrYear;
    timeStampForGasFiredPowerYearWise[currentYear] = timeStampForCurrYear;
    // Calculate Operated Scope 1&2 in MTPA only if Operating Model is SOV, else 0
    // volume * Asset Scope 1 CI * UOM
    // UOM conversion = 1/10^9 = 1/1000000000 = 0.000000001
    if (isOperatingModelSOV) {
      const uomForOperatedScope12Calc = 0.000000001;
      const operatedScope12ForCurrYear =
        volumeByUserForCurrYear * assetScope1ForCurrYear * uomForOperatedScope12Calc;
      gasFiredPowerParameters.operatedScope12[currentYear] = operatedScope12ForCurrYear;
    } else {
      gasFiredPowerParameters.operatedScope12[currentYear] = 0.0;
    }

    // Calculate Equity Scope 1&2
    // volume * Asset Scope 1 CI * ES * UOM
    // 0 for Operating Models - Toll PPA as Equity share is always 0
    // UOM conversion = 1/10^9 = 1/1000000000 = 0.000000001
    let equityScope12ForCurrYear = 0;
    const uomForEquityScope12Calc = 0.000000001;
    equityScope12ForCurrYear =
      volumeByUserForCurrYear *
      assetScope1ForCurrYear *
      equitySharePercentageByUser *
      uomForEquityScope12Calc;
    gasFiredPowerParameters.equityScope12[currentYear] = equityScope12ForCurrYear;

    // Calculate Net Absolute Emission(NAE)
    // volume * PVCE * UOM
    // UOM conversion = 1/10^9 = 1/1000000000 = 0.000000001
    const uomForNetAbsEmisCalc = 0.000000001;
    const netAbsEmsForCurrYear =
      efficencyByUser === 0 ? 0 : volumeByUserForCurrYear * pvceForCurrYear * uomForNetAbsEmisCalc;
    gasFiredPowerParameters.nae[currentYear] = netAbsEmsForCurrYear;
  }
  dispatch({
    type: 'setCarbonIntensityForGasFiredPowerYearWise',
    payload: ciDataForGasFiredPowerYearWise,
  });
  dispatch({
    type: 'setTimeStampDataForGasFiredPowerYearWise',
    payload: timeStampForGasFiredPowerYearWise,
  });

  return gasFiredPowerParameters;
};

export const calculateCIforGFPfuelTypeLNG = (
  state: ICalculatorFormState,
  dispatch: Idispatch,
  stepTwoData: IStepTwoTableData[],
) => {
  const efficencyByUser = Number(state.efficiency);
  const equitySharePercentageByUser = Number(state.equityShare);
  const isOperatingModelSOV = Number(state.operatingModel) === 1;
  type gasFiredObject = IRecord;
  const gasFiredPowerParameters: {
    assetScope1: gasFiredObject;
    pvce: gasFiredObject;
    operatedScope12: gasFiredObject;
    equityScope12: gasFiredObject;
    nae: gasFiredObject;
    ciDataForGasFiredPower: object[];
  } = {
    assetScope1: {},
    pvce: {},
    operatedScope12: {},
    equityScope12: {},
    nae: {},
    ciDataForGasFiredPower: [{}],
  };
  const ciDataForGasFiredPowerYearWise: IRecord = {};
  const timeStampForGasFiredPowerYearWise: Record<number, string> = {};

  for (let year = state.startYear; year < state.endYear + 1; year++) {
    const currentYear = year;
    const {
      fuelCO2eCIforCurrYear,
      lngNcfCIforCurrYear,
      gasEndUsencfCIforCurrYear,
      timeStampForCurrYear,
    } = calculateParametersForGasFiredPower(currentYear, state);
    const volumeByUserForCurrYear = getVolumeByUser(currentYear, stepTwoData);
    // calculate Asset Scope 1 CI
    // (Fuel CO2e per year * UOM conversion)/E -> Fuled CO2e from table data
    // UOM conversion = 3.41 * 10^3
    const uomForAssecScopeCalc = threepointfourone * thousand;
    const assetScope1ForCurrYear =
      efficencyByUser === 0 ? 0 : (fuelCO2eCIforCurrYear * uomForAssecScopeCalc) / efficencyByUser;
    gasFiredPowerParameters.assetScope1[currentYear] = assetScope1ForCurrYear;

    // Calculate Project Value Chain CI Electrical (PVCE)
    // Asset Scope 1 + ((LNG NCF CI - Gas End Use NCF CI) / E) * UOM CF
    // UOM conversion = 3.6
    const uomForPVCEcalc = 3.6;
    const pvceForCurrYear =
      efficencyByUser === 0
        ? 0
        : assetScope1ForCurrYear +
          ((lngNcfCIforCurrYear - gasEndUsencfCIforCurrYear) / efficencyByUser) * uomForPVCEcalc;
    gasFiredPowerParameters.pvce[currentYear] = pvceForCurrYear;
    ciDataForGasFiredPowerYearWise[currentYear] = pvceForCurrYear;
    timeStampForGasFiredPowerYearWise[currentYear] = timeStampForCurrYear;
    // Calculate Operated Scope 1&2
    // volume * Asset Scope 1 CI * UOM
    // UOM conversion = 1/10^9 = 1/1000000000 = 0.000000001
    if (isOperatingModelSOV) {
      const uomForOperatedScope12Calc = 0.000000001;
      const operatedScope12ForCurrYear =
        volumeByUserForCurrYear * assetScope1ForCurrYear * uomForOperatedScope12Calc;
      gasFiredPowerParameters.operatedScope12[currentYear] = operatedScope12ForCurrYear;
    } else {
      gasFiredPowerParameters.operatedScope12[currentYear] = 0.0;
    }

    // Calculate Equity Scope 1&2
    // volume * Asset Scope 1 CI * ES * UOM
    // 0 for Operating Models - Toll PPA
    // UOM conversion = 1/10^9 = 1/1000000000 = 0.000000001
    let equityScope12ForCurrYear = 0;
    const uomForEquityScope12Calc = 0.000000001;
    equityScope12ForCurrYear =
      volumeByUserForCurrYear *
      assetScope1ForCurrYear *
      equitySharePercentageByUser *
      uomForEquityScope12Calc;
    gasFiredPowerParameters.equityScope12[currentYear] = equityScope12ForCurrYear;

    // Calculate Net Absolute Emission(NAE)
    // volume * PVCE * UOM
    // UOM conversion = 1/10^9 = 1/1000000000 = 0.000000001
    const uomForNetAbsEmisCalc = 0.000000001;
    const netAbsEmsForCurrYear =
      efficencyByUser === 0 ? 0 : volumeByUserForCurrYear * pvceForCurrYear * uomForNetAbsEmisCalc;

    gasFiredPowerParameters.nae[currentYear] = netAbsEmsForCurrYear;
  }
  dispatch({
    type: 'setCarbonIntensityForGasFiredPowerYearWise',
    payload: ciDataForGasFiredPowerYearWise,
  });
  dispatch({
    type: 'setTimeStampDataForGasFiredPowerYearWise',
    payload: timeStampForGasFiredPowerYearWise,
  });
  return gasFiredPowerParameters;
};
