/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Modal } from '@sede-x/shell-ds-react-framework';
import React from 'react';

const Loader = (props: { isLoading?: boolean; message?: string }) => {
  return (
    <Modal
      {...{
        closable: false,
        loading: true,
        bodyPadding: true,
        showFooter: false,
        loadingText:
          props.message !== undefined && props.message !== '' ? props.message : 'Loading...',
      }}
      open={props.isLoading!}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClose={() => {}}
    ></Modal>
  );
};
export default Loader;
