/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import instance from './instance';
import { API_VERSION_ONE } from './constants';
import { IDeals, IUpdateDeal, type SaveKpiDataI } from '../types/types';

const fetchCarbonIntensityGridApi = `${API_VERSION_ONE}techsources?`;
const fetchCarbonIntensityNonGridApi = `${API_VERSION_ONE}techsourcesren?`;
const fetchCarbonIntensityForPipelineGasAsCommodityApi = `${API_VERSION_ONE}ng-carbon-intensities/?`;
const fetchBusinessunitsApi = `${API_VERSION_ONE}businessunits`;
const fetchCommoditiesApi = `${API_VERSION_ONE}commodities`;
const fetchCountriesApi = `${API_VERSION_ONE}countries/?`;
const fetchRegionsApi = `${API_VERSION_ONE}regions`;
const fetchUnitofmeasuresApi = `${API_VERSION_ONE}unitofmeasures`;
const fetchGridLocationsApi = `${API_VERSION_ONE}powergrid/?`;
const fetchGasFiredPowerDataApi = `${API_VERSION_ONE}gasfiredpowercis/?`;
const saveKpiDataApi = `${API_VERSION_ONE}kpi-deal`;
const fetchTechSourcesApi = `${API_VERSION_ONE}get-technology-sources`;
const downloadKpiDealApi = `${API_VERSION_ONE}download-kpi-deal`;
const fetchAllDeals = `${API_VERSION_ONE}all-deals`;
const updateDeal = `${API_VERSION_ONE}update-deal/?`;
const deleteDeal = `${API_VERSION_ONE}delete-deal`;
const dealDetails = `${API_VERSION_ONE}deal-details/?`;

interface ICarbonIntensityOptions {
  techSource: string[] | string;
  startYear: number;
  endYear: number;
  gridLocation: string;
}

export const fetchCarbonIntensityGrid = (options: ICarbonIntensityOptions) => {
  const url = `${fetchCarbonIntensityGridApi}techSource=${options.techSource}&startYear=${options.startYear}&endYear=${options.endYear}&gridLocation=${options.gridLocation}`;
  return instance.get(url);
};

export const fetchCarbonIntensityNonGrid = (techSources: string[]) => {
  let allTechSourcesLink = '';
  for (const element of techSources) {
    allTechSourcesLink += `techSource=${element}&`;
  }
  allTechSourcesLink = allTechSourcesLink.substring(0, allTechSourcesLink.length - 1);
  const url = `${fetchCarbonIntensityNonGridApi}${allTechSourcesLink}`;
  return instance.get(url);
};

interface ICarbonIntensityOptionsForPipelineGasAsCommodity {
  region: string;
  startYear: number;
  endYear: number;
}
export const fetchCarbonIntensityForPipelineGasAsCommodity = (
  options: ICarbonIntensityOptionsForPipelineGasAsCommodity,
) => {
  const url = `${fetchCarbonIntensityForPipelineGasAsCommodityApi}region=${options.region}&startYear=${options.startYear}&endYear=${options.endYear}`;
  return instance.get(url);
};

export const fetchBusinessunits = () => {
  const url = fetchBusinessunitsApi;
  return instance.get(url);
};

export const fetchCommodities = () => {
  const url = fetchCommoditiesApi;
  return instance.get(url);
};

export const fetchCountries = (region: string) => {
  const url = `${fetchCountriesApi}region=${region}`;
  return instance.get(url);
};

export const fetchTechSources = () => {
  const url = `${fetchTechSourcesApi}`;
  return instance.get(url);
};

export const fetchRegions = () => {
  const url = fetchRegionsApi;
  return instance.get(url);
};
export const fetchUnitofmeasures = () => {
  const url = fetchUnitofmeasuresApi;
  return instance.get(url);
};

export const fetchGridLocations = (region: string, countryCode: string) => {
  const url = `${fetchGridLocationsApi}region=${region}&countryName=${countryCode}`;
  return instance.get(url);
};

interface IGasFiredPowerData {
  startYear: number;
  endYear: number;
}

export const fetchGasFiredPowerData = (options: IGasFiredPowerData) => {
  const url = `${fetchGasFiredPowerDataApi}startYear=${options.startYear}&endYear=${options.endYear}`;
  return instance.get(url);
};

export const saveKpiData = (options: SaveKpiDataI[]) => {
  const url = saveKpiDataApi;
  return instance.post(url, options);
};
export const downloadKpiData = () => {
  const url = downloadKpiDealApi;
  return instance.get(url);
};
export const getAllDeals = () => {
  const url = fetchAllDeals;
  return instance.get(url);
};

export const updateDeals = (options: IUpdateDeal) => {
  const { OPPORTUNITY_ID, MOVE_CARBON_DASHBOARD } = options;
  const url = `${updateDeal}opportunityId=${OPPORTUNITY_ID}&moveCarbonDashboard=${MOVE_CARBON_DASHBOARD}`;
  return instance.get(url);
};

export const deleteDeals = (deals: IDeals[]) => {
  const url = deleteDeal;
  return instance.post(url, deals);
};

export const getDealDetails = (opportunityId: number) => {
  const url = `${dealDetails}opportunityId=${opportunityId}`;
  return instance.get(url);
};
