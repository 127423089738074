import { Card, Alert } from '@sede-x/shell-ds-react-framework';
import {
  POWER_PERFORMANCE_STANDARD,
  POWER_PERFORMANCE_STANDARD_DESCRIPTION,
} from '../../../../../api/constants';
import { three } from '../../../../../constants';
import { containsHTMLTags, attachEmailLinkToText } from '../../../../../utils';

const SummaryMessage = (props: {
  kpiMessage: string[];
  isGasFiredPower: boolean;
  operatingModel: number;
}) => {
  return (
    <Card
      size='small'
      bodyPadding={true}
      padding='tight'
      bodyBgColor='base'
      className={
        props.isGasFiredPower &&
        Number(props.operatingModel) !== three &&
        props.kpiMessage.length !== 0
          ? 'kpiCardMaxWidth'
          : 'hideKpiMessage'
      }
      header={{
        title: POWER_PERFORMANCE_STANDARD,
        description: props.isGasFiredPower ? POWER_PERFORMANCE_STANDARD_DESCRIPTION : '',
      }}
    >
      <Alert iconVisibility={false}>
        {props.kpiMessage.map(
          (message) =>
            message !== '' && (
              <ul style={{ padding: 0, margin: 0 }} key={`${message}`}>
                {containsHTMLTags(message) ? (
                  <li dangerouslySetInnerHTML={{ __html: attachEmailLinkToText(message) }} />
                ) : (
                  <li>{attachEmailLinkToText(message)}</li>
                )}
              </ul>
            ),
        )}
      </Alert>
    </Card>
  );
};

export default SummaryMessage;
