import React, { useEffect, useState } from 'react';
import { Grid, Divider } from '@sede-x/shell-ds-react-framework';
import DealsFilter from './DealsFilter/DealsFilter';
import AllCarbonDealsTable from './AllCarbonDeals/AllCarbonDeals';
import AllCarbonDealsContent from './AllCarbonDealsContent/AllCarbonDealsContent';
import { IAuthToken, IDeals } from '../../types/types';
import './AllDeals.css';
import { AllDealsContainer } from './AllDeals.style';
import { getAllDeals } from '../../api/services';
import Loader from '../Loader';
import { showToast } from './utils';
import { FETCH_ERROR } from '../../api/constants';
import { AxiosError } from 'axios';
import Footer from '../Footer/Footer';
import { fiveHundreds } from '../../constants';

interface AllDealsProps {
  user: IAuthToken;
}
const AllDeals: React.FC<AllDealsProps> = ({ user }) => {
  const [allDealsData, setAllDealsData] = useState<IDeals[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getAllDealsData = async () => {
    try {
      const data = await getAllDeals();
      setAllDealsData(data?.data);
    } catch (err) {
      const error = err as AxiosError<Error>;
      showToast(error?.response?.status ?? fiveHundreds, FETCH_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteDeals = async () => {
    await getAllDealsData();
  };

  useEffect(() => {
    getAllDealsData();
  }, []);

  return (
    <AllDealsContainer>
      <Loader isLoading={isLoading} message={'Fetching All Carbon Deals'} />
      <Grid
        columns={'200px 5px auto'}
        style={{
          paddingLeft: '15px',
          paddingRight: '15px',
        }}
      >
        <Grid.Cell>
          <DealsFilter allDealsData={allDealsData} />
        </Grid.Cell>
        <Grid.Cell>
          <Divider direction='vertical' />
        </Grid.Cell>
        <Grid.Cell>
          <AllCarbonDealsContent user={user} />
          <AllCarbonDealsTable dealsData={allDealsData} handleDeleteDeals={handleDeleteDeals} />
        </Grid.Cell>
      </Grid>
      <Footer />
    </AllDealsContainer>
  );
};

export default AllDeals;
