import { type Dayjs } from 'dayjs';
import Decimal from 'decimal.js';
import {
  fetchBusinessunits,
  fetchCommodities,
  fetchCountries,
  fetchGridLocations,
  fetchTechSources,
} from '../../../api/services';
import {
  FETCH_ERROR,
  GAS_FIRED_GENERATION,
  GRID,
  MMBTU,
  MWH,
  PIPELINE_GAS,
  PJ,
  POWER,
} from '../../../api/constants';
import {
  convertInputToCommaSeparatedWithTwoDecimal,
  regexForOneToHundredUptoTwoDecimals,
} from '../../../utils';
import filterOptions from '../../../constants/filterOptions';
import {
  type ICalculatorFormState,
  TChangeEvent,
  Options,
  IDispatch,
  EventValue,
  ArrayDispatch,
  BooleanDispatch,
  StringDispatch,
  ICountry,
  IGridLocation,
  IManualFormData,
  ITechSrcApiResponseObj,
  eventType,
} from '../../../types/types';
import { four, hundred, three } from '../../../constants';
import { notify } from '../../Toast/notify';

const possibleCountryOptions = filterOptions.possibleCountryOptions;
const NatutalGas = 'Natural Gas';

export const disabledPrevDatesForEndDate = (current: Dayjs, myState: IManualFormData) =>
  current != null ? current.isBefore(myState.startDate, 'date') : false;

export const onGrossMarginChange = (
  e: TChangeEvent,
  dispatch: IDispatch,
  myDispatch: IDispatch,
) => {
  // set validation to false if value changes
  dispatch({
    type: 'setIsFormValidated',
    payload: false,
  });
  const grossMarginValInput = e.target.value;
  const valueToShowInUI = convertInputToCommaSeparatedWithTwoDecimal(grossMarginValInput);
  myDispatch({
    type: 'setGrossMarginVal',
    payload: valueToShowInUI,
  });
  dispatch({
    type: 'setGrossMargin',
    payload: new Decimal(valueToShowInUI.replaceAll(',', '')),
  });
  myDispatch({
    type: 'setisGrossMarginValid',
    payload: true,
  });
};

export const onOpportunityChange = (
  e: TChangeEvent,
  dispatch: IDispatch,
  myDispatch: IDispatch,
) => {
  // set validation to false if value changes
  dispatch({
    type: 'setIsFormValidated',
    payload: false,
  });
  const value = e.target.value;
  if (value) {
    myDispatch({
      type: 'setisOpportunityValid',
      payload: true,
    });
    dispatch({
      type: 'setOpportunity',
      payload: value,
    });
  } else {
    myDispatch({
      type: 'setisOpportunityValid',
      payload: false,
    });
  }
};

export const onEquityPercentageChange = (
  e: TChangeEvent,
  dispatch: IDispatch,
  myDispatch: IDispatch,
) => {
  // invalidate the form if value is changed from one option to another,
  // this will lead to hide the step two table
  dispatch({
    type: 'setIsFormValidated',
    payload: false,
  });

  const inputValue = e.target.value;
  if (regexForOneToHundredUptoTwoDecimals.test(inputValue)) {
    myDispatch({
      type: 'setEquityShareVal',
      payload: inputValue,
    });
    myDispatch({
      type: 'setIsEquityPercentageValid',
      payload: true,
    });
    dispatch({
      type: 'setEquityShare',
      payload: Number(inputValue) / hundred,
    });
    return;
  }
  if (inputValue === '0' || inputValue === '') {
    myDispatch({
      type: 'setEquityShareVal',
      payload: '',
    });
    dispatch({
      type: 'setEquityShare',
      payload: '',
    });
  }
};

export const onEfficiencyPercentageChange = (
  e: TChangeEvent,
  dispatch: IDispatch,
  myDispatch: IDispatch,
) => {
  // invalidate the form if value is changed from one option to another,
  // this will lead to hide the step two table
  dispatch({
    type: 'setIsFormValidated',
    payload: false,
  });

  const inputValue = e.target.value;
  if (regexForOneToHundredUptoTwoDecimals.test(inputValue)) {
    myDispatch({
      type: 'setEfficiencyVal',
      payload: inputValue,
    });
    myDispatch({
      type: 'setIsEfficiencyValid',
      payload: true,
    });

    dispatch({
      type: 'setEfficiency',
      payload: Number(inputValue) / hundred,
    });
    return;
  }
  if (inputValue === '0' || inputValue === '') {
    myDispatch({
      type: 'setEfficiencyVal',
      payload: '',
    });
    dispatch({
      type: 'setEfficiency',
      payload: '',
    });
  }
};

export const handleAssetTypeChange = (e: eventType, dispatch: IDispatch, myDispatch: IDispatch) => {
  // invalidate the form if value is changed from one option to another, this will lead to hide the step two table
  dispatch({ type: 'setIsFormValidated', payload: false });
  // update the selected asset type
  dispatch({ type: 'setAssetType', payload: e as string });
  // update the asset type is valid
  myDispatch({ type: 'setIsAssetTypeValid', payload: true });
};

export const handleFuelTypeChange = (
  e: eventType,
  dispatch: IDispatch,
  myDispatch: IDispatch,
  state: ICalculatorFormState,
  myState: IManualFormData,
) => {
  // invalidate the form if value is changed from one option to another, this will lead to hide the step two table
  dispatch({
    type: 'setIsFormValidated',
    payload: false,
  });

  // set technology sources for saving kpi data
  const selectedTechSources = [...state.selectedTechnologySources];
  const updatedSelectedTechSources = selectedTechSources.map((item) => {
    if ([GAS_FIRED_GENERATION, 'LNG', NatutalGas].includes(item.value)) {
      // Find the item in resp matching to LNG and get its id
      const fuelTypeTech = myState.techSourceApiResponse.find(
        (x: { TECHNOLOGY_SOURCE_NAME: string }) => x.TECHNOLOGY_SOURCE_NAME === e,
      );
      if (fuelTypeTech) {
        // Return a new object with the updated value and the matching id
        return {
          value: e,
          id: fuelTypeTech.TECHNOLOGY_SOURCE_ID,
        };
      }
    }
    // Return the original object for all other cases
    return item;
  });
  dispatch({
    type: 'setSelectedTechnologySources',
    payload: updatedSelectedTechSources as { value: string; id: number }[],
  });

  dispatch({
    type: 'setFuelType',
    payload: e as string,
  });
  myDispatch({
    type: 'setIsFuelTypeValid',
    payload: true,
  });
};

export const handleOperatingModelChange = (
  _e: eventType,
  options: Options,
  dispatch: IDispatch,
  myDispatch: IDispatch,
) => {
  // invalidate the form if value is changed from one option to another, this will lead to hide the step two table
  dispatch({ type: 'setIsFormValidated', payload: false });
  myDispatch({ type: 'setIsOperatingModelValid', payload: true });
  dispatch({ type: 'setOperatingModel', payload: Number(options.key) });

  if (Number(options.key) === three) {
    // for Operating Model as Toll PPA, equity share is default to 0
    myDispatch({ type: 'setEquityShareVal', payload: '0' });
    dispatch({ type: 'setEquityShare', payload: 0 / hundred });
    myDispatch({ type: 'setShowEquityShare', payload: false });
    myDispatch({ type: 'setIsEquityPercentageValid', payload: true });
    return;
  }

  if (Number(options.key) === four) {
    // for Operating Model as Lease, equity share is default to 100%
    myDispatch({ type: 'setEquityShareVal', payload: '100' });
    dispatch({ type: 'setEquityShare', payload: 1 });
    myDispatch({ type: 'setShowEquityShare', payload: false });
    myDispatch({ type: 'setIsEquityPercentageValid', payload: true });
    return;
  }
  // for Operating Model SOV/NOV, set equity share to empty and enable the field.
  myDispatch({ type: 'setEquityShareVal', payload: '' });
  dispatch({ type: 'setEquityShare', payload: '' });
  myDispatch({ type: 'setShowEquityShare', payload: true });
};
export const handleStartDateChange = (
  e: TChangeEvent | EventValue<Dayjs> | { $d: Date },
  dispatch: IDispatch,
  myDispatch: IDispatch,
) => {
  // invalidate the form if value is changed from one option to another, this will lead to hide the step two table
  dispatch({
    type: 'setIsFormValidated',
    payload: false,
  });

  if (e && '$d' in e && e.$d !== undefined) {
    myDispatch({
      type: 'setStartDate',
      payload: e.$d.toString(),
    });
    dispatch({
      type: 'setStartYear',
      payload: e.$d.getFullYear(),
    });
    dispatch({
      type: 'setStartDate',
      payload: e.$d.toString(),
    });
    myDispatch({
      type: 'setisStartDateValid',
      payload: true,
    });
  } else {
    myDispatch({
      type: 'setisStartDateValid',
      payload: false,
    });
  }
};

export const handleEndDateChange = (
  e: TChangeEvent | EventValue<Dayjs> | { $d: Date },
  dispatch: IDispatch,
  myDispatch: IDispatch,
) => {
  // invalidate the form if value is changed from one option to another, this will lead to hide the step two table
  dispatch({
    type: 'setIsFormValidated',
    payload: false,
  });
  if (e && '$d' in e && e.$d !== undefined) {
    myDispatch({
      type: 'setEndDate',
      payload: e.$d.toString(),
    });
    dispatch({
      type: 'setEndYear',
      payload: e.$d.getFullYear(),
    });
    dispatch({
      type: 'setEndDate',
      payload: e.$d.toString(),
    });
    myDispatch({
      type: 'setisEndDateValid',
      payload: true,
    });
  } else {
    myDispatch({
      type: 'setisEndDateValid',
      payload: false,
    });
  }
};

export const handleBusinessChange = async (
  _e: eventType,
  data: { myState: IManualFormData; option: Options; state: ICalculatorFormState; country: string },
  dispatch: IDispatch,
  myDispatch: IDispatch,
  func: {
    setBusiness: StringDispatch;
    setGridLocationOptions: ArrayDispatch;
    setIsFetchingCountry: BooleanDispatch;
    setCountryOptions: ArrayDispatch;
    setCountry: StringDispatch;
    setIsFetchingGridLocation: BooleanDispatch;
  },
) => {
  const { myState, option, state, country } = data;
  const {
    setBusiness,
    setGridLocationOptions,
    setIsFetchingCountry,
    setCountryOptions,
    setCountry,
    setIsFetchingGridLocation,
  } = func;
  let region = '';
  // invalidate the form if value is changed from one option to another, this will lead to hide the step two table
  dispatch({
    type: 'setIsFormValidated',
    payload: false,
  });
  const buz = option.value;
  setBusiness(buz as string);
  setGridLocationOptions([]);
  dispatch({ type: 'setBusiness', payload: buz as string });
  dispatch({ type: 'setBusinessId', payload: Number(option.key) });

  dispatch({
    type: 'setGridLocation',
    payload: '',
  });

  if (buz !== '' || buz !== null) {
    myDispatch({
      type: 'setisBusinessValid',
      payload: true,
    });
  } else {
    myDispatch({
      type: 'setisBusinessValid',
      payload: false,
    });
  }

  region = getRegion(buz as string);

  dispatch({
    type: 'setRegion',
    payload: region,
  });

  // setting up country options
  await setCountryOptionsAndCountyValue(country, myDispatch, state, region, dispatch, myState, {
    setGridLocationOptions,
    setIsFetchingGridLocation,
    setIsFetchingCountry,
    setCountryOptions,
    setCountry,
  });
};

export const handleCommodityChange = (
  _e: eventType,
  selectedOption: Options,
  dispatch: IDispatch,
  myDispatch: IDispatch,
  _business: string,
  _myState: IManualFormData,
  setCommodity: StringDispatch,
) => {
  const commoditySelected = selectedOption.value;
  // invalidate the form if value is changed from one option to another, this will lead to hide the step two table
  dispatch({ type: 'setIsFormValidated', payload: false });
  if (commoditySelected !== '' || commoditySelected !== null) {
    myDispatch({
      type: 'setisCommodityValid',
      payload: true,
    });
    setCommodity(commoditySelected as string);
    dispatch({ type: 'setCommodity', payload: commoditySelected as string });
    dispatch({ type: 'setCommodityId', payload: Number(selectedOption.key) });
  } else {
    myDispatch({ type: 'setisCommodityValid', payload: false });
  }

  if ((commoditySelected as string).includes('Gas')) {
    myDispatch({
      type: 'setEnableTechSourceField',
      payload: false,
    });
    myDispatch({
      type: 'setTechSource',
      payload: [],
    });
    myDispatch({
      type: 'setShowGasFields',
      payload: false,
    });

    dispatch({
      type: 'setGridLocation',
      payload: '',
    });
    myDispatch({
      type: 'setDisableGrid',
      payload: true,
    });
  } else {
    myDispatch({
      type: 'setEnableTechSourceField',
      payload: true,
    });
  }
};

export const handleCountyChange = (
  _e: eventType,
  selectedOption: Options,
  country: string,
  param: {
    dispatch: IDispatch;
    myDispatch: IDispatch;
    setCountry: StringDispatch;
    myState: IManualFormData;
    setIsFetchingGridLocation: BooleanDispatch;
    state: ICalculatorFormState;
    setGridLocationOptions: ArrayDispatch;
  },
) => {
  const {
    dispatch,
    myDispatch,
    setCountry,
    myState,
    setIsFetchingGridLocation,
    state,
    setGridLocationOptions,
  } = param;
  const countrySelected = selectedOption.value;
  // invalidate the form if value is changed from one option to another, this will lead to hide the step two table
  if (country !== '') {
    dispatch({ type: 'setIsFormValidated', payload: false });
  }

  if (countrySelected !== '' || countrySelected !== null) {
    myDispatch({ type: 'setisCountryValid', payload: true });

    setCountry(countrySelected as string);
    dispatch({
      type: 'setCountryId',
      payload: Number(selectedOption.key),
    });
    dispatch({ type: 'setGridLocation', payload: '' });
    // at this point, region and country will have data. just check if tech source has GRID selected.
    if (myState.techSource.includes(GRID)) {
      void fetchGridLocationData(
        { setIsFetchingGridLocation, setGridLocationOptions, state, dispatch, myDispatch },
        country,
        countrySelected as string,
      );
    }

    dispatch({ type: 'setGridLocation', payload: '' });
  } else {
    myDispatch({ type: 'setisCountryValid', payload: false });
  }
};

export const handleTechSourceChange = (
  techSources: eventType,
  _selectedOption: Options,
  gridLocationOptions: Array<{ value: string; label: string }>,
  params: {
    dispatch: IDispatch;
    myDispatch: IDispatch;
    state: ICalculatorFormState;
    country: string;
    myState: IManualFormData;
    setIsFetchingGridLocation: BooleanDispatch;
    setGridLocationOptions: ArrayDispatch;
  },
) => {
  const {
    dispatch,
    myDispatch,
    state,
    country,
    myState,
    setIsFetchingGridLocation,
    setGridLocationOptions,
  } = params;
  if (
    !['GAS_FIRED_GENERATION', 'LNG', NatutalGas].some((element) =>
      (techSources as string[]).includes(element),
    )
  ) {
    dispatch({
      type: 'setFuelType',
      payload: '',
    });
  }
  const [hasSelectedTechSrcLNGOrNaturalGas, currentFuelTypeVal, currentFuelTypeId] =
    setUpVarsForGFP(state);
  const selectedTechSourceValues = getTechSrcValues(
    techSources as string[],
    hasSelectedTechSrcLNGOrNaturalGas,
    currentFuelTypeVal,
    currentFuelTypeId,
    myState,
  ) as Array<{ value: string; id: number }>;
  // invalidate the form if value is changed from one option to another, this will lead to hide the step two table
  dispatch({ type: 'setIsFormValidated', payload: false });
  dispatch({
    type: 'setSelectedTechnologySources',
    payload: selectedTechSourceValues,
  });
  myDispatch({ type: 'setTechSource', payload: techSources as string[] });
  // show fields related to Gas Fired Generation
  if ((techSources as string[]).includes(GAS_FIRED_GENERATION)) {
    myDispatch({ type: 'setShowGasFields', payload: true });
  } else {
    myDispatch({ type: 'setShowGasFields', payload: false });
  }

  // if the last value selected to GRID. means currently selected value is GRID else everytime a tech source is selected,
  // this api will hit if techsource has GRID in it
  const isLastTechSrcGrid =
    (techSources as string[])[(techSources as string[]).length - 1] === GRID;
  const isRegionAndCountryNotEmpty = state.region !== '' && country !== '';
  if (isLastTechSrcGrid && isRegionAndCountryNotEmpty) {
    void fetchGridLocationData(
      { setIsFetchingGridLocation, setGridLocationOptions, state, dispatch, myDispatch },
      country,
    );
  }
  updateGridLocationFieldView(techSources as string[], dispatch, myDispatch, gridLocationOptions);

  // reset gridLocation state value if GRID is not present in the tech sources. this is typically when GRID was present and removed from the list
  if (!(techSources as string[]).includes('Grid') && state.gridLocationId !== 0) {
    dispatch({
      type: 'setGridLocationId',
      payload: 0,
    });
  }
};

export const handleGridLocationChange = (
  e: eventType,
  selectedOption: Options,
  dispatch: IDispatch,
  myDispatch: IDispatch,
) => {
  myDispatch({
    type: 'setIsGridLocationValid',
    payload: true,
  });
  dispatch({
    type: 'setGridLocation',
    payload: e as string,
  });
  dispatch({
    type: 'setGridLocationId',
    payload: Number(selectedOption.key),
  });
};

export const maxLengthCheck = (event: TChangeEvent) => {
  if (event.target.value.length > event.target.maxLength) {
    event.target.value = event.target.value.slice(0, event.target.maxLength);
    return true;
  }
  return false;
};

const getRegion = (business: string) => {
  let region = '';

  switch (true) {
    case business.includes('Americas'):
      region = 'Americas';
      break;
    case business.includes('Europe'):
      region = 'Europe';
      break;
    case business.includes('Asia'):
      region = 'Asia';
      break;
    case business.includes('Australia'):
      region = 'Australia';
      break;
    case business === 'Shell Energy Trading Americas':
      region = 'Americas';
      break;
    default:
    // handle any other cases here
  }
  return region;
};
const setCountryOptionsAndCountyValue = async (
  country: string,
  myDispatch: IDispatch,
  state: ICalculatorFormState,
  region: string,
  dispatch: IDispatch,
  myState: IManualFormData,
  func: {
    setGridLocationOptions: ArrayDispatch;
    setIsFetchingGridLocation: BooleanDispatch;
    setIsFetchingCountry: BooleanDispatch;
    setCountryOptions: ArrayDispatch;
    setCountry: StringDispatch;
  },
) => {
  const {
    setGridLocationOptions,
    setIsFetchingGridLocation,
    setIsFetchingCountry,
    setCountryOptions,
    setCountry,
  } = func;
  let countryOpts = [{ label: '', value: '' }];
  setIsFetchingCountry(true);
  try {
    const countryResp = await fetchCountries(region);
    const countryRespData = countryResp.data;
    countryOpts = countryRespData
      .filter((ctry: ICountry) => {
        return possibleCountryOptions[region as keyof typeof possibleCountryOptions]?.includes(
          ctry.COUNTRY_NAME,
        );
      })
      .map((ctry: ICountry) => {
        return {
          value: ctry.COUNTRY_ID,
          label: ctry.COUNTRY_NAME,
        };
      });
    // default the Country field to the value if countryOpts has only one country
    if (countryOpts.length === 1) {
      setCountry(countryOpts[0].label);
      // As country is set to a value here, make API call for Grid Location if GRID is already selected in Technology sources
      if (myState.techSource.includes(GRID)) {
        await fetchGridLocationData(
          { setIsFetchingGridLocation, setGridLocationOptions, state, dispatch, myDispatch },
          country,
          countryOpts[0].label,
          region,
        );
      }
      dispatch({
        type: 'setCountryId',
        payload: countryOpts[0].value,
      });
    } else {
      setCountry('');
      dispatch({
        type: 'setCountryId',
        payload: 0,
      });
    }
    setCountryOptions(countryOpts);
  } catch {
    notify('error', FETCH_ERROR);
  }
  setIsFetchingCountry(false);
  return countryOpts;
};

const setUpVarsForGFP = (state: ICalculatorFormState) => {
  let hasSelectedTechSrcLNGOrNaturalGas = false;
  let currentFuelTypeVal = '';
  let currentFuelTypeId = 0;
  for (const item of state.selectedTechnologySources) {
    if (item.value === 'LNG' || item.value === NatutalGas) {
      hasSelectedTechSrcLNGOrNaturalGas = true;
      currentFuelTypeVal = item.value;
      currentFuelTypeId = item.id;
      break;
    }
  }
  return [hasSelectedTechSrcLNGOrNaturalGas, currentFuelTypeVal, currentFuelTypeId];
};
type StringNumberBoolean = string | number | boolean;
const getTechSrcValues = (
  techSources: string[],
  hasSelectedTechSrcLNGOrNaturalGas: StringNumberBoolean,
  currentFuelTypeVal: StringNumberBoolean,
  currentFuelTypeId: StringNumberBoolean,
  myState: IManualFormData,
) => {
  return techSources.map((name: string) => {
    if (name !== GAS_FIRED_GENERATION) {
      const techSource: ITechSrcApiResponseObj = myState.techSourceApiResponse.find(
        (obj) => obj.TECHNOLOGY_SOURCE_NAME === name,
      ) as ITechSrcApiResponseObj;
      if (techSource) {
        return { value: name, id: techSource.TECHNOLOGY_SOURCE_ID };
      } else {
        return { value: name, id: -1 };
      }
    }
    // this condition checks if tech source already has a fuel type selected and then tech source change happens.
    // here if gas fired generation is there then no need to update the selected tech source with 'Gas Fired Generation' again
    else if (hasSelectedTechSrcLNGOrNaturalGas) {
      return { value: currentFuelTypeVal, id: currentFuelTypeId };
    } else {
      return { value: name, id: -1 };
    }
  });
};

const updateGridLocationFieldView = (
  techSources: string | string[],
  dispatch: IDispatch,
  myDispatch: IDispatch,
  gridLocationOptions: { value: string; label: string }[],
) => {
  // enable/disable GRID LOCAITON field
  if (techSources.includes(GRID)) {
    // if techsource has GRID in it
    if (gridLocationOptions.length === 1) {
      // but gridLocation has only one data
      myDispatch({
        type: 'setDisableGrid',
        payload: true,
      });
    } else {
      myDispatch({
        type: 'setDisableGrid',
        payload: false,
      });
    }
  } else {
    myDispatch({
      type: 'setDisableGrid',
      payload: true,
    });

    dispatch({
      type: 'setGridLocation',
      payload: '',
    });
  }
};

interface GridLocationDataParams {
  setIsFetchingGridLocation: BooleanDispatch;
  setGridLocationOptions: ArrayDispatch;
  state: ICalculatorFormState;
  dispatch: IDispatch;
  myDispatch: IDispatch;
}
const fetchGridLocationData = async (
  params: GridLocationDataParams,
  country: string,
  countryVal?: string,
  region?: string,
) => {
  const { setIsFetchingGridLocation, setGridLocationOptions, state, dispatch, myDispatch } = params;
  try {
    setIsFetchingGridLocation(true);
    const gridLocationResponse = await fetchGridLocations(
      region ?? state.region, // if region is undefined then it will take state.region
      countryVal ?? country, // if countryVal is undefined then it will take country
    );
    const gridLocation: IGridLocation[] = gridLocationResponse.data;
    const gridLocationOptions: Array<{ value: string; label: string }> = [];
    gridLocation.forEach((gridData: IGridLocation) => {
      gridLocationOptions.push({
        value: String(gridData.GRID_ID),
        label: gridData.Grid,
      });
    });
    setGridLocationOptions(gridLocationOptions);
    // if grid location has only one option then set the value as default to the field and disable the field
    if (gridLocationOptions.length === 1) {
      dispatch({
        type: 'setGridLocation',
        payload: gridLocationOptions[0].label,
      });
      dispatch({
        type: 'setGridLocationId',
        payload: gridLocationOptions[0].value,
      });

      myDispatch({
        type: 'setDisableGrid',
        payload: true,
      });
    } else {
      myDispatch({
        type: 'setDisableGrid',
        payload: false,
      });
    }
  } catch {
    notify('error', FETCH_ERROR);
  }
  setIsFetchingGridLocation(false);
};

export const fetchDropDownData = async (myDispatch: IDispatch) => {
  myDispatch({
    type: 'setIsLoading',
    payload: true,
  });
  try {
    const businessunitsOptn = [];
    const commodityOptn = [];
    const techSourceOptn = [];
    const fuelTypeOptn = [];
    const assetTypeOptn = [];
    const businessunits = await fetchBusinessunits();
    const commodityResp = await fetchCommodities();
    const techSourceResp = await fetchTechSources();
    const businessunitsOptns = businessunits.data;
    const commodityOptns = commodityResp.data;
    const techSourceOptns = techSourceResp.data;

    // create options for business dropdown field
    for (const option of businessunitsOptns) {
      businessunitsOptn.push({
        value: option.BUSINESS_ID,
        label: option.BUSINESS_NAME,
      });
    }

    // create options for commodity dropdown field
    for (const option of commodityOptns) {
      const commodityName = option.TRADE_COMMODITY_NAME;
      const isCommodityPg = commodityName.replace(' ', '').toLowerCase() === 'pipelinegas';
      const isCommodityPower = commodityName.replace(' ', '').toLowerCase() === 'power';
      const { TRADE_COMMODITY_ID, TRADE_COMMODITY_NAME } = option;
      if (isCommodityPg || isCommodityPower) {
        commodityOptn.push({
          value: TRADE_COMMODITY_ID,
          label: TRADE_COMMODITY_NAME,
        });
      }
    }

    // create options for technology source dropdown field and add GAS FIRED GENERATION as an option to it
    const techSourcePermitted = [
      'wind',
      'solar',
      'hydropower',
      'biomass',
      'geothermal',
      'oil',
      'coal',
      'grid',
    ];
    const fuelTypePermitted = ['naturalgas', 'lng'];
    for (const option of techSourceOptns) {
      // Compare the incoming tech source with techSourcePermitted and fuelTypePermitted options after removing spaces and convert to lowercase
      const isTechSourcePermitted = techSourcePermitted.includes(
        option.TECHNOLOGY_SOURCE_NAME.replaceAll(' ', '').toLowerCase(),
      );
      const isFuelTypePermitted = fuelTypePermitted.includes(
        option.TECHNOLOGY_SOURCE_NAME.replaceAll(' ', '').toLowerCase(),
      );
      if (isTechSourcePermitted) {
        techSourceOptn.push({
          value: option.TECHNOLOGY_SOURCE_NAME,
          label: option.TECHNOLOGY_SOURCE_NAME,
        });
      }
      if (isFuelTypePermitted) {
        fuelTypeOptn.push({
          value: option.TECHNOLOGY_SOURCE_NAME,
          label: option.TECHNOLOGY_SOURCE_NAME,
        });
      }
    }
    techSourceOptn.push({
      value: 'Gas Fired Generation',
      label: 'Gas Fired Generation',
    });
    assetTypeOptn.push(
      { value: 'Greenfield', label: 'Greenfield' },
      { value: 'Brownfield', label: 'Brownfield' },
    );

    myDispatch({ type: 'setBusinessunitsOptns', payload: businessunitsOptn });
    myDispatch({ type: 'setCommodityOptions', payload: commodityOptn });
    myDispatch({ type: 'setTechSourceOptions', payload: techSourceOptn });
    myDispatch({ type: 'setFuelTypeOptions', payload: fuelTypeOptn });
    myDispatch({ type: 'setAssetTypeOptions', payload: assetTypeOptn });
    myDispatch({ type: 'setTechSourceApiResponse', payload: techSourceOptns });
  } catch {
    notify('error', FETCH_ERROR);
    myDispatch({
      type: 'setIsLoading',
      payload: false,
    });
  }
  myDispatch({
    type: 'setIsLoading',
    payload: false,
  });
};

export const setUom = (
  business: string | string[],
  commodity: string,
  myDispatch: IDispatch,
  dispatch: IDispatch,
) => {
  // default to MWH
  let uom = MWH;

  // Business with Commodity combinations
  const isAmericaWithCommodityPg = business.includes('Americas') && commodity === PIPELINE_GAS;
  const isAsiaWithCommodityPg = business.includes('Asia') && commodity === PIPELINE_GAS;
  const isAustraliaWithCommodityPg = business.includes('Australia') && commodity === PIPELINE_GAS;
  const isEuropeWithCommodityPgOrPw =
    business.includes('Europe') && (commodity === PIPELINE_GAS || commodity === POWER);

  // if business = AMERICA and commodity = NG, UOM -> MMBTU || if business = ASIA and commodity = NG, UOM -> MMBTU
  if (isAmericaWithCommodityPg || isAsiaWithCommodityPg) {
    uom = MMBTU;
  }

  // if business = AUSTRALIA and commodity = NG, UOM -> PJ
  if (isAustraliaWithCommodityPg) {
    uom = PJ;
  }

  // if business = EUROPE and commodity = NG/PW, UOM -> MWH
  if (isEuropeWithCommodityPgOrPw) {
    uom = MWH;
  }

  myDispatch({
    type: 'setUomVal',
    payload: uom,
  });
  dispatch({
    type: 'setUomVal',
    payload: uom,
  });
};
