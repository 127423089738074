import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PageContainer from '../PageContainer/PageContainer';
import { IAuthToken } from '../../types/types';
import MainHeader from '../MainHeader/MainHeader';
import { Toaster } from '../Toast';
import { KPI_DEAL_BUTTON_ACCESS } from '../../api/constants';
import AllDealsContainerPage from '../AllDealsContainerPage/AllDealsContainerPage';

interface AppRouterProps {
  user: IAuthToken;
  logout: () => void;
}
const AppRouter: React.FC<AppRouterProps> = ({ user, logout }) => {
  const isUserHaveAccess = KPI_DEAL_BUTTON_ACCESS?.some(
    (accessUser) => accessUser.shortname === user.preferred_username?.toUpperCase(),
  );
  return (
    <div data-testid='app-router'>
      <Toaster />
      <MainHeader
        emailAddress={user?.email}
        shortname={user?.preferred_username.toUpperCase()}
        isUserHaveAccess={isUserHaveAccess}
        logout={logout}
      />
      <Routes>
        <Route path='/' element={<PageContainer user={user} />} />
        <Route path='/kpi-calculator' element={<PageContainer user={user} />} />
        {isUserHaveAccess && (
          <Route path='/all-deals' element={<AllDealsContainerPage user={user} />} />
        )}
      </Routes>
    </div>
  );
};

export default AppRouter;
